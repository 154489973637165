import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import Breadcrumbs from 'theme/breadcrumbs';
import PageHeader from 'theme/pageHeader';
import Tabs from 'theme/tabs';
import { useTitle } from 'utils/hooks';
import AvailableFlights from './availableFlights';
import BookingModal from './bookingModal';
import Bookings from './bookings';

const TABS = [
  { value: 'planned', label: 'Planned flights', element: <Bookings /> },
  { value: 'available', label: 'Add flight', element: <AvailableFlights /> },
];

const CorporateFlights = (): JSX.Element => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const { corporateFlightsStore } = useStores();
  const { fetchFlights, fetchBookings } = corporateFlightsStore;

  useTitle(tab === 'planned' ? 'Planned flights' : 'Add flight');

  useEffect(() => {
    fetchFlights();
    fetchBookings();
  }, []);

  return (
    <>
      <Breadcrumbs current="Flights" />
      <PageHeader title="Flights" />
      <Tabs tabs={TABS} onTabClick={(value) => navigate(paths.corporateFlights(value))} />
      <BookingModal />
    </>
  );
};

export default CorporateFlights;
