import { runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import { Option } from 'domain/types';
import * as requests from 'flights/requests';
import { AirportType, Flight, LoadingAirportsFlags } from 'flights/types';
import { DateType, Filter, FilterType } from 'theme/searchPanel';

const DEFAULT_DIFF_IN_DAYS = 14;

class FlightsStore {
  rootStore: RootStore;

  airportFrom: Option = DEFAULT_SELECT_OPTION;
  airportTo: Option = DEFAULT_SELECT_OPTION;
  datepickerEndDate: Date | undefined = undefined;
  datepickerStartDate: Date | undefined = undefined;
  flights: Flight[] = [];
  isLoadingAirportAutocompleteHints: LoadingAirportsFlags = {
    from: false,
    to: false,
  };
  isLoadingFlightDetails = false;
  isLoadingFlights = false;
  isSubmitting = false;
  numberOfDays = DEFAULT_DIFF_IN_DAYS;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  handleDate = (date: Date, type: DateType): void => {
    if (type === DateType.Start) this.datepickerStartDate = date;
    else this.datepickerEndDate = date;
  };

  clearDatepickerDates = (): void => {
    this.datepickerStartDate = undefined;
    this.datepickerEndDate = undefined;
    this.numberOfDays = DEFAULT_DIFF_IN_DAYS;
  };

  swapAirports = (): void => {
    const airportFrom = this.airportFrom;
    this.airportFrom = this.airportTo;
    this.airportTo = airportFrom;
  };

  private setIsLoadingAirportAutocompleteHints = (value: string, bool: boolean): void => {
    this.isLoadingAirportAutocompleteHints = {
      ...this.isLoadingAirportAutocompleteHints,
      [value]: bool,
    };
  };

  getAirportAutocompleteHints = (from: boolean) => (): Promise<Option[] | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    runInAction(() => this.setIsLoadingAirportAutocompleteHints(from ? 'from' : 'to', true));

    return requests
      .fetchAirports()
      .then(({ data }) => {
        return data
          .filter(({ iata }) => iata !== this.airportFrom.iata && iata !== this.airportTo.iata)
          .map(({ iata, name }) => ({
            value: iata,
            label: name,
            iata,
          }));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_AIRPORTS_ERROR);
      })
      .finally(() => {
        runInAction(() => this.setIsLoadingAirportAutocompleteHints(from ? 'from' : 'to', false));
      });
  };

  get filterBtnDisabled(): boolean {
    return (
      !!(!this.datepickerStartDate && this.datepickerEndDate) || !!(this.datepickerStartDate && !this.datepickerEndDate)
    );
  }

  get filters(): Filter[] {
    return [
      {
        clear: this.clearDatepickerDates,
        endDate: this.datepickerEndDate,
        label: 'When',
        name: 'when',
        onDateChange: this.handleDate,
        startDate: this.datepickerStartDate,
        type: FilterType.Dates,
      },
      {
        flightAutocompleteSettings: {
          isStatic: true,
          loadingAirports: this.isLoadingAirportAutocompleteHints,
          onAutocompleteChange: (from: boolean) => this.getAirportAutocompleteHints(from),
          onAutocompleteSelect: (from: boolean) => (option: Option) =>
            this.selectAirport(option, from ? AirportType.From : AirportType.To),
          placeholder: 'Type name...',
          swapValues: this.swapAirports,
          valueFrom: this.airportFrom,
          valueTo: this.airportTo,
        },
        label: '',
        name: 'from / to',
        type: FilterType.FlightSearch,
      },
    ];
  }

  private selectAirport = (option: Option, type: AirportType): void => {
    if (type === AirportType.From) this.airportFrom = option;
    else this.airportTo = option;
  };
}

export default FlightsStore;
