import { createContext, useContext } from 'react';
import { AdminFlightsStore } from 'adminFlights';
import { AuthStore } from 'auth';
import { CompaniesStore } from 'companies';
import { CorporateAdminStore } from 'corporateAdmin';
import { CorporateFlightsStore } from 'corporateFlights';
import { Option } from 'domain/types';
import { FlightsStore } from 'flights';
import { GuestsStore } from 'guests';
import { MembersStore } from 'members';
import { TeamStore } from 'team';
import { ToastsStore } from 'toasts';
import { UsersStore } from 'users';

export class RootStore {
  adminFlightsStore: AdminFlightsStore;
  authStore: AuthStore;
  companiesStore: CompaniesStore;
  corporateAdminStore: CorporateAdminStore;
  corporateFlightsStore: CorporateFlightsStore;
  flightsStore: FlightsStore;
  guestsStore: GuestsStore;
  membersStore: MembersStore;
  teamStore: TeamStore;
  toastsStore: ToastsStore;
  usersStore: UsersStore;

  constructor() {
    this.adminFlightsStore = new AdminFlightsStore(this);
    this.authStore = new AuthStore(this);
    this.companiesStore = new CompaniesStore(this);
    this.corporateAdminStore = new CorporateAdminStore(this);
    this.corporateFlightsStore = new CorporateFlightsStore(this);
    this.flightsStore = new FlightsStore(this);
    this.guestsStore = new GuestsStore(this);
    this.membersStore = new MembersStore(this);
    this.teamStore = new TeamStore(this);
    this.toastsStore = new ToastsStore(this);
    this.usersStore = new UsersStore(this);
  }

  updateOptions = (options: Option[], selectedOption: Option, multiple?: boolean): Option[] => {
    if (options.some(({ value }) => value === selectedOption.value))
      return options.filter(({ value }) => value !== selectedOption.value);
    else if (multiple) return [...options, selectedOption];
    else return [selectedOption];
  };

  getSelectedOption = (options: Option[]): string => (options.length ? options[0].value : '');

  addFlagToFetch = (options: Option[], value: string): boolean | undefined => {
    const optionValue = this.getSelectedOption(options);
    if (!optionValue) return undefined;

    return optionValue === value;
  };
}

export const rootStore = new RootStore();

const RootStoreContext = createContext(rootStore);

export function useStores(): RootStore {
  const context = useContext(RootStoreContext);
  if (context === undefined) throw new Error('useRootStore must be used within RootStoreProvider');

  return context;
}

export default RootStoreContext;
