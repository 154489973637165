import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { TableAction, TableActionBtn } from 'domain/types';
import { FlightGuest, FlightGuestModalName } from 'guests/types';
import Breadcrumbs from 'theme/breadcrumbs';
import Button from 'theme/button';
import Confirm from 'theme/confirm';
import Input from 'theme/input';
import LoadingArea from 'theme/loadingArea';
import MembershipTag from 'theme/membershipTag';
import Modal from 'theme/modal';
import PageHeader from 'theme/pageHeader';
import Pagination from 'theme/pagination';
import SortableHeadCell from 'theme/sortableHeadCell';
import TableActions from 'theme/tableActions';
import TableItemName from 'theme/tableItemName';
import { useTitle } from 'utils/hooks';
import styles from './Guests.module.scss';
import GuestFormModal from './guestFormModal';

const Guests = (): JSX.Element => {
  const { guestsStore, corporateAdminStore } = useStores();
  const {
    changePageNumber,
    changePageSize,
    closeModal,
    deleteGuest,
    fetchGuests,
    guestCount,
    guests,
    isLoadingGuests,
    isResettingGuests,
    isSubmitting,
    modalName,
    openModal,
    pageNumber,
    pageSize,
    searchValues,
    selectedGuest,
    setSearchValues,
    sortingProps,
  } = guestsStore;
  const { corporateAdminOrganization, currentContextOrganizationId } = corporateAdminStore;

  const rowButtonActions = (guest: FlightGuest): TableActionBtn[] => {
    const btns: TableActionBtn[] = [
      {
        hidden: false,
        link: paths.guestDetails(guest.id, currentContextOrganizationId),
        text: 'Details',
        type: TableAction.Details,
      },
      {
        action: () => openModal(FlightGuestModalName.Delete, guest),
        hidden: false,
        text: 'Remove user',
        type: TableAction.Remove,
      },
    ];

    return btns.filter((btn) => !btn.hidden);
  };

  useTitle('Guests');

  useEffect(() => {
    if (corporateAdminOrganization) fetchGuests();
  }, [corporateAdminOrganization]);

  const removeGuest = (): void | undefined => {
    if (!selectedGuest) return;
    deleteGuest(selectedGuest.id);
  };

  return (
    <div className={styles.root}>
      <Breadcrumbs current="Guests" />
      <PageHeader title="Guests">
        <Button onClick={() => openModal(FlightGuestModalName.Add)} text="Add guest" />
      </PageHeader>
      <LoadingArea loading={isLoadingGuests}>
        <div className="main-container">
          <table className="horizontal-table-vertical-top">
            <thead>
              <tr>
                <SortableHeadCell columnName="CreatedAt" name="No." {...sortingProps} />
                <th data-th="Role">Role</th>
                <SortableHeadCell columnName="Name" name="Name" {...sortingProps}>
                  <Input
                    name="search"
                    loading={isResettingGuests}
                    placeholder="Search by name..."
                    onChange={(e) => setSearchValues('name', e.target.value)}
                    value={searchValues.name}
                    type="search"
                  />
                </SortableHeadCell>
                <th data-th="Action" />
              </tr>
            </thead>
            <tbody>
              {guests.map((g) => {
                return (
                  <tr key={g.id}>
                    <td data-th="No.">{g.sequenceId}</td>
                    <td data-th="Role" className={styles.roleCell}>
                      <MembershipTag label="Guest" userRole={g.role} />
                    </td>
                    <td data-th="Name" className={styles.nameCell}>
                      <TableItemName title={g.fullName} link={paths.guestDetails(g.id, currentContextOrganizationId)} />
                    </td>
                    <td data-th="Action">
                      <TableActions actions={rowButtonActions(g)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            activePage={pageNumber}
            activePageItemCount={guests.length}
            allItemCount={guestCount}
            disabled={isLoadingGuests}
            goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        </div>
      </LoadingArea>

      <GuestFormModal />
      <Modal onClose={closeModal} isOpen={modalName === FlightGuestModalName.Delete}>
        <Confirm
          yesText="Confirm"
          action={removeGuest}
          close={closeModal}
          submitting={isSubmitting}
          question={
            <>
              Are you sure that you want to delete <strong>{selectedGuest?.fullName}</strong>?
            </>
          }
        />
      </Modal>
    </div>
  );
};

export default observer(Guests);
