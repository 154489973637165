import React from 'react';
import FlightRoute from 'flights/flightRoute';
import FlightStatusTag from 'flights/flightStatusTag';
import { Booking, Flight, FlightDetails } from 'flights/types';
import styles from './FlightDetailsHeader.module.scss';

interface FlightDetailsHeaderProps<T> {
  flight: T;
}

const FlightDetailsHeader = <T extends Booking | Flight | FlightDetails>({
  flight,
}: FlightDetailsHeaderProps<T>): JSX.Element | null => {
  if (!flight) return null;
  const { departureFrom, departureTo, status } = flight;

  return (
    <div className={styles.root}>
      <h1 className={styles.from}>{departureFrom.city}</h1>
      <FlightRoute flight={flight} simplified />
      <h1 className={styles.to}>{departureTo.city}</h1>
      <FlightStatusTag status={status} />
    </div>
  );
};

export default FlightDetailsHeader;
