import api from 'api';
import { paths } from 'app/routes/paths.const';
import { searchObjectToArray } from 'utils/helpers';
import { TeamMemberDto, TeamMemberReturn, TeamMembersReturn, TeamMemberSearchValues } from './types';

export interface FetchTeamMemberParams {
  id?: string;
  pageNumber: number;
  pageSize: number;
  roles?: string[];
  searchValues?: TeamMemberSearchValues;
  sortBy?: string;
}

export const fetchTeamMembers = ({
  id,
  pageNumber,
  pageSize,
  roles,
  searchValues,
  sortBy,
}: FetchTeamMemberParams): TeamMembersReturn => {
  return api.get('/admin/team', {
    params: {
      ...(id && { id }),
      pageSize,
      pageNumber,
      ...(roles && { role: roles }),
      ...(sortBy && { sort: sortBy }),
      ...(searchValues && { ...searchObjectToArray<TeamMemberSearchValues>(searchValues) }),
    },
  });
};

export const updateTeamMember = (teamMemberId: string, data: TeamMemberDto): TeamMemberReturn => {
  return api.patch(`/admin/team/${teamMemberId}`, { ...data, redirectUrl: location.origin + paths.createPassword });
};
