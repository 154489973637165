import { AxiosResponse } from 'axios';
import { Gender, MembershipRole } from 'domain/types';

enum FlightGuestStatus {
  Accepted = 'Accepted',
  Companion = 'Companion',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export interface RawFlightGuest {
  birthDate: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  organizationName: string;
  phone: string;
  sequenceId: string;
  sex: Gender;
  status: FlightGuestStatus;
}

export interface FlightGuest extends RawFlightGuest {
  fullName: string;
  role: MembershipRole.Guest;
}

export enum FlightGuestModalName {
  Add = 'Add',
  Delete = 'Delete',
  Edit = 'Edit',
}

export interface FlightGuestsRawData {
  count: number;
  results: RawFlightGuest[];
}

export interface FlightGuestCreateDto {
  birthDate: string;
  contactRequestUrl: null;
  email: string | null;
  firstName: string;
  lastName: string;
  organizationId: string;
  phone: string | null;
  sex: Gender;
}

export type FlightGuestUpdateDto = Omit<FlightGuestCreateDto, 'organizationId'> & {
  shouldResendMail: boolean;
};

export interface FetchFlightGuestsParams {
  contactId?: string;
  name?: string;
  organizationId: string;
  pageNumber: number;
  pageSize: number;
  searchValues: FlightGuestSearchValues;
  sortBy: string;
}

export interface FlightGuestSearchValues extends Record<string, string> {
  name: string;
}

export type FlightGuestCreateReturn = Promise<AxiosResponse<FlightGuestCreateDto>>;
export type FlightGuestUpdateReturn = Promise<AxiosResponse<FlightGuestUpdateDto>>;
export type FlightGuestsReturn = Promise<AxiosResponse<FlightGuestsRawData>>;
export type FlightDeleteGuestReturn = Promise<AxiosResponse<void>>;
