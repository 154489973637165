import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { AdminPermission } from 'domain/types';

interface UseRoleAndPermissionsReturn {
  resetRoleAndPermissions: () => void;
  roleAndPermissionsProps: {
    isPermissionsEditionVisible: boolean;
    isSuperAdmin: boolean;
    setIsPermissionsEditionVisible: Dispatch<SetStateAction<boolean>>;
    setIsSuperAdmin: Dispatch<SetStateAction<boolean>>;
    setViewPermissions: Dispatch<SetStateAction<AdminPermission[]>>;
    setWritePermissions: Dispatch<SetStateAction<AdminPermission[]>>;
    viewPermissions: AdminPermission[];
    writePermissions: AdminPermission[];
  };
}

export function useRoleAndPermissions(): UseRoleAndPermissionsReturn {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [viewPermissions, setViewPermissions] = useState<AdminPermission[]>([AdminPermission.IndividualMemberships]);
  const [writePermissions, setWritePermissions] = useState<AdminPermission[]>([]);
  const [isPermissionsEditionVisible, setIsPermissionsEditionVisible] = useState(true);

  const resetRoleAndPermissions = (): void => {
    setIsSuperAdmin(false);
    setViewPermissions([AdminPermission.IndividualMemberships]);
    setWritePermissions([]);
  };

  useEffect(() => {
    if (isSuperAdmin) {
      setViewPermissions([]);
      setWritePermissions([]);
    } else {
      setViewPermissions([AdminPermission.IndividualMemberships]);
      setWritePermissions([]);
    }
    setIsPermissionsEditionVisible(!isSuperAdmin);
  }, [isSuperAdmin]);

  return {
    resetRoleAndPermissions,
    roleAndPermissionsProps: {
      isPermissionsEditionVisible,
      isSuperAdmin,
      setIsPermissionsEditionVisible,
      setIsSuperAdmin,
      setViewPermissions,
      setWritePermissions,
      viewPermissions,
      writePermissions,
    },
  };
}
