import { useState, useEffect, useMemo, FormEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { toastMessages } from 'domain/toastMessages';
import Button from 'theme/button';
import Input from 'theme/input';
import NonAuthedPageLayoutTitle from 'theme/nonAuthedPageLayoutTitle';
import { useTitle } from 'utils/hooks';

function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const token = search.get('token');

  const { authStore, toastsStore } = useStores();
  const { loading, resetPassword } = authStore;
  const { addToast } = toastsStore;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useTitle('Reset Password');

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    if (!token) return;

    resetPassword(password, token)
      .then(() => {
        navigate(paths.login);
        addToast(toastMessages.RESET_PASSWORD.SUCCESS, 'success');
      })
      .catch((err) => {
        if (err.response.status === 400) addToast(toastMessages.RESET_PASSWORD.PASSWORD_TOO_SHORT);
        else addToast(toastMessages.ERROR.DEFAULT);
      });
  };

  const isSubmitBtnDisabled = useMemo(() => {
    return !password || !confirmPassword || password !== confirmPassword || loading;
  }, [password, confirmPassword, loading]);

  useEffect(() => {
    return () => {
      if (!token) {
        navigate(paths.login);
        addToast(toastMessages.RESET_PASSWORD.TOKEN_MISSING);
      }
    };
  }, [token]);

  return (
    <>
      <NonAuthedPageLayoutTitle title="Choose your new password" />
      <form onSubmit={onSubmit}>
        <Input
          label="Password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          type="password"
          value={password}
        />
        <Input
          label="Confirm Password"
          name="confirm-password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm your new password"
          type="password"
          value={confirmPassword}
        />

        <div>
          <Button text="Send" loading={loading} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </>
  );
}

export default observer(ResetPassword);
