import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { TeamModalName } from 'team/types';
import Breadcrumbs from 'theme/breadcrumbs';
import Button from 'theme/button';
import PageHeader from 'theme/pageHeader';
import Tabs from 'theme/tabs';
import { useAdminPermissionsForUIManagement, useTitle } from 'utils/hooks';
import AddPermissionsModal from './addPermissionsModal';
// import CabinCrew from './cabinCrew';
import EntourageTeam from './entourageTeam';
import TeamMemberModal from './teamMemberModal';

const TABS = [
  { value: 'entourage', label: 'Entourage Team', element: <EntourageTeam /> },
  // { value: 'cabin-crew', label: 'Cabin Crew', element: <CabinCrew /> },
];

const Team = (): JSX.Element => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const { teamStore } = useStores();
  const { fetchTeamMembers, openModal, pageNumber, pageSize } = teamStore;

  const isEditionForbidden = useAdminPermissionsForUIManagement();
  useTitle(tab === 'entourage' ? 'Entourage Team' : 'Cabin Crew');

  useEffect(() => {
    fetchTeamMembers(pageSize, pageNumber);
  }, []);

  const onTabClick = (value: string): void | undefined => {
    navigate(paths.team(value));
  };

  const renderHeaderBtns = (): JSX.Element | null => {
    if (tab !== 'entourage' && isEditionForbidden) return null;

    return (
      <div className="page-header-btn-container">
        <Button text="Add permissions" onClick={() => openModal(TeamModalName.AddPermissions)} buttonType="secondary" />
        <Button text="Add team member" onClick={() => openModal(TeamModalName.AddTeamMember)} />
      </div>
    );
  };

  return (
    <div>
      <Breadcrumbs current="Team" />
      <PageHeader title="Team">{renderHeaderBtns()}</PageHeader>
      <Tabs tabs={TABS} onTabClick={(value: string) => onTabClick(value)} />

      <TeamMemberModal />
      <AddPermissionsModal />
    </div>
  );
};

export default observer(Team);
