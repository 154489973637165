import React from 'react';
import clsx from 'clsx';
import styles from './AirportCodeLabel.module.scss';

interface AirportCodeLabelProps {
  className?: string;
  code: string;
}

const AirportCodeLabel = ({ className, code }: AirportCodeLabelProps): JSX.Element => {
  return <div className={clsx(styles.root, className)}>{code}</div>;
};

export default AirportCodeLabel;
