import { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Tabs.module.scss';

type Tab = {
  element: ReactNode;
  label: string;
  value: string;
};

type TabsProps = {
  onTabClick?: (value: string) => void;
  tabs: Tab[];
};

const Tabs = ({ onTabClick, tabs }: TabsProps): JSX.Element => {
  const { tab } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

  const handleTabClick = (index: number, value: string): void => {
    onTabClick?.(value);
    setActiveTabIndex(index);
  };

  useEffect(() => {
    if (tab && tabs.length > 0) setActiveTabIndex(tabs.findIndex(({ value }) => value === tab) ?? 0);
  }, [tab, tabs.length]);

  useEffect(() => {
    if (tabs.length === 1) setActiveTabIndex(0);
  }, [tabs.length]);

  return (
    <div className={styles.root}>
      <ul>
        {tabs.map(({ value, label }, i) => (
          <li
            className={clsx(styles.tabItem, { [styles.tabItemActive]: i === activeTabIndex })}
            key={i}
            onClick={() => handleTabClick(i, value)}
          >
            {label}
          </li>
        ))}
      </ul>
      {activeTabIndex !== null && <div className={styles.content}>{tabs[activeTabIndex].element}</div>}
    </div>
  );
};

export default Tabs;
