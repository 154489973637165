import React from 'react';
import clsx from 'clsx';
import styleVariables from 'theme/variables.module.scss';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  className?: string;
  color?: string;
  progress: number;
  small?: boolean;
}

const ProgressBar = ({
  className = '',
  color = styleVariables.colorSuccess,
  progress,
  small = false,
}: ProgressBarProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, { [styles.small]: small })}>
      <div className={styles.progress} style={{ width: `${progress}%`, backgroundColor: color }} />
    </div>
  );
};

export default ProgressBar;
