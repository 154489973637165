import { FormEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { AdminFlightModalName } from 'adminFlights/types';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Input from 'theme/input';
import Modal from 'theme/modal';
import { useFormValidation } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  nameRequired: '',
};

const EditAdminFlightModal = (): JSX.Element => {
  const { adminFlightsStore } = useStores();
  const { closeModal, isSubmitting, modalName } = adminFlightsStore;

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const [name, setName] = useState('');

  const handleNameChange = (value: string): void => {
    cleanValidationErrors(['nameRequired']);
    setName(value);
  };

  const onModalClose = (): void => {
    closeModal();
    setName('');
    resetValidation();
  };

  const validateOnSubmit = (): void => {
    handleValidation('nameRequired', formValidationMessages.NAME_REQUIRED, !name);
  };

  const submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    validateOnSubmit();
    alert('Submit');
  };

  return (
    <Modal onClose={onModalClose} isOpen={modalName === AdminFlightModalName.FlightEdit} title="Edit flight">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <table className="vertical-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td data-th="Name">
                <Input
                  name="name"
                  onChange={(e) => handleNameChange(e.target.value)}
                  value={name}
                  error={validation.nameRequired}
                  inTable
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <Button onClick={closeModal} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default observer(EditAdminFlightModal);
