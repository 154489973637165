import React from 'react';
import clsx from 'clsx';
import logo from 'theme/images/svg/logo.svg';
import styles from './NonAuthedPageLayoutTitle.module.scss';

interface NonAuthedPageLayoutTitleProps {
  centered?: boolean;
  title: string;
}

const NonAuthedPageLayoutTitle = ({ centered = false, title }: NonAuthedPageLayoutTitleProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <img className={styles.logo} src={logo} alt="logo" />
      <h1 className={clsx(styles.title, { center: centered })}>{title}</h1>
    </div>
  );
};

export default NonAuthedPageLayoutTitle;
