import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { TableAction } from 'domain/types';
import { FlightGuestModalName } from 'guests/types';
import Breadcrumbs from 'theme/breadcrumbs';
import IconButton from 'theme/iconButton';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import TableCard from 'theme/tableCard';
import { useTitle } from 'utils/hooks';
import GuestFormModal from '../guestFormModal';
import styles from './GuestDetails.module.scss';

const ANCESTORS = [{ title: 'Guests', to: paths.guests }];

const GuestDetails = (): JSX.Element => {
  const { guestId } = useParams();
  const { corporateAdminStore, guestsStore } = useStores();
  const { currentContextOrganizationId } = corporateAdminStore;
  const { fetchGuest, guest, isLoadingGuest, openModal } = guestsStore;

  useEffect(() => {
    if (!guestId || !currentContextOrganizationId) return;
    fetchGuest(guestId);
  }, [guestId, currentContextOrganizationId]);

  useTitle('Guest details');

  const renderContent = (): JSX.Element | null => {
    if (!guest) return null;

    return (
      <>
        <PageHeader title={guest.fullName}>
          <IconButton action={() => openModal(FlightGuestModalName.Edit, guest)} text="Edit" type={TableAction.Edit} />
        </PageHeader>
        <TableCard title="Personal information" vertical className="main-container">
          <table>
            <tbody>
              <tr>
                <th>First name</th>
                <td data-th="First name">{guest.firstName}</td>
              </tr>
              <tr>
                <th>Last name</th>
                <td data-th="Last name">{guest.lastName}</td>
              </tr>
              <tr>
                <th>DOB</th>
                <td data-th="DOB">{guest.birthDate}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td data-th="Gender">{guest.sex}</td>
              </tr>
            </tbody>
          </table>
        </TableCard>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <Breadcrumbs ancestors={ANCESTORS} current="Details" />
      <LoadingArea loading={isLoadingGuest}>{renderContent()}</LoadingArea>
      <GuestFormModal />
    </div>
  );
};

export default observer(GuestDetails);
