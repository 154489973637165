type DataObject = Record<string, string | number | undefined>;

export function addOptionalParamsToAPIRequest<T extends DataObject>(data: T): T {
  const paramsObject: T = {} as T;

  Object.keys(data).forEach((paramName: keyof T) => {
    if (data[paramName]) {
      paramsObject[paramName] = data[paramName];
    }
  });

  return paramsObject;
}
