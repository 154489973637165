import { useEffect } from 'react';

export function useTitle(title: string): void {
  if (!title) return;

  useEffect(() => {
    const prevTitle = document.title;
    document.title = `Entourage Web Admin — ${title}`;

    return () => {
      document.title = prevTitle;
    };
  });
}
