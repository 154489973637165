import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { SUBSCRIPTION_STATUSES } from 'domain/constants';
import VerificationTag from 'theme/verificationTag';
import styles from './CompanyDetailsTable.module.scss';

function CompanyDetailsTable(): JSX.Element | null {
  const { companiesStore } = useStores();
  const { company } = companiesStore;

  if (!company) return null;

  return (
    <div className={clsx(styles.root, 'card')}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>Id</th>
            <td data-th="Id">{company.id}</td>
          </tr>
          <tr>
            <th>Tax number</th>
            <td data-th="Tax number">{company.taxId}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td data-th="Address">{company.address}</td>
          </tr>
          <tr>
            <th>Payment</th>
            <td data-th="Payment">
              <VerificationTag labels={SUBSCRIPTION_STATUSES} verified={company.isActive} />
            </td>
          </tr>
          <tr>
            <th>Active until</th>
            <td data-th="Date">{company.activeUntil}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default observer(CompanyDetailsTable);
