import axios, { AxiosRequestConfig } from 'axios';
import { fromUnixTime, isBefore, isEqual, toDate } from 'date-fns';
import qs from 'qs';
import { rootStore } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { Auth } from 'auth';
import { createRefreshToken, loginWithRefreshToken } from 'auth/requests';
import env from 'domain/env';
import { getAPICredentials, getRefreshToken, saveAPICredentials, saveRefreshToken } from './utils';

const api = axios.create({
  baseURL: env.backendUrl,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    getAPICredentials();
    const apiCredentials = getAPICredentials();
    const parsedApiCredentials: Auth = apiCredentials ? JSON.parse(apiCredentials) : null;
    const refreshToken = getRefreshToken();

    if (!parsedApiCredentials) return Promise.resolve(config);

    const currentTime = toDate(new Date());
    const isTokenValid =
      parsedApiCredentials &&
      (isBefore(currentTime, fromUnixTime(parsedApiCredentials.user.exp)) ||
        isEqual(currentTime, fromUnixTime(parsedApiCredentials.user.exp)));

    if (isTokenValid) {
      if (!refreshToken) {
        return createRefreshToken(apiCredentials)
          .then(({ data }) => {
            saveRefreshToken(data);

            return Promise.resolve({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${parsedApiCredentials.token}` || '',
              },
            } as AxiosRequestConfig);
          })
          .catch((error) => {
            return Promise.reject(`Couldn't create refresh token. ${error}`);
          });
      } else {
        return Promise.resolve({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${parsedApiCredentials.token}` || '',
          },
        } as AxiosRequestConfig);
      }
    } else {
      if (refreshToken) {
        return loginWithRefreshToken()
          .then(({ data }) => {
            const { token } = data;

            rootStore.authStore.setToken(token);
            saveAPICredentials(token);

            const newAPICredentials = getAPICredentials();
            const parsedNewApiCredentials: Auth = newAPICredentials && JSON.parse(newAPICredentials);

            return Promise.resolve({
              ...config,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${parsedNewApiCredentials.token}` || '',
              },
            } as AxiosRequestConfig);
          })
          .catch((error) => {
            return Promise.reject(`Couldn't login with refresh token. ${error}`);
          });
      } else return Promise.reject('No refresh token');

      return Promise.resolve(config);
    }
    return Promise.resolve(config);
  },
  (err: unknown) => Promise.reject(err)
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (error.config.url !== '/auth/login' && error.response.status === 401) {
      rootStore.authStore.logout();
      return window.location.replace(location.origin + paths.login);
    } else return Promise.reject(error);
  }
);

export default api;
