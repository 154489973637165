import { FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { CompanyModalName } from 'companies/types';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import { useCompanyDetailsForm, useFormValidation } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  companyNameRequired: '',
};

function EditCompanyModal(): JSX.Element {
  const { companyId } = useParams();
  const { companiesStore, toastsStore } = useStores();
  const { closeModal, isSubmitting, modalName, selectedCompany, updateCompany } = companiesStore;
  const { addToast, toastMessages } = toastsStore;

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const { companyDto, companyName, formTable } = useCompanyDetailsForm({
    cleanValidationErrors,
    selected: selectedCompany,
    validation,
  });

  const onModalClose = (): void => {
    closeModal();
    resetValidation();
  };

  const validateOnSubmit = (): void => {
    handleValidation('companyNameRequired', formValidationMessages.COMPANY_NAME_REQUIRED, !companyName);
  };

  const editCompany = (): void => {
    validateOnSubmit();
    if (!selectedCompany || !companyName) return;

    updateCompany(companyDto, selectedCompany.id, !!companyId)
      .then(() => {
        addToast(toastMessages.COMPANY.UPDATE_SUCCESS, 'success');
        onModalClose();
      })
      .catch(() => {
        addToast(toastMessages.COMPANY.UPDATE_ERROR);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    editCompany();
  };

  return (
    <Modal onClose={onModalClose} isOpen={modalName === CompanyModalName.EditCompany} title="Edit company">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        {formTable}
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(EditCompanyModal);
