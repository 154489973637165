export enum AdminPermission {
  Account = 'Account',
  Charters = 'Charters',
  CorporateInvoices = 'CorporateInvoices',
  CorporateMemberships = 'CorporateMemberships',
  EntourageTeam = 'EntourageTeam',
  IndividualInvoices = 'IndividualInvoices',
  IndividualMemberships = 'IndividualMemberships',
  Members = 'Members',
}

export type AdminPermissionOption = { name: string; value: string };
