import React from 'react';
import clsx from 'clsx';
import { FlightDate } from 'flights/types';
import { formatDate } from 'utils/helpers';
import { DATE_FORMAT } from 'utils/helpers';
import styles from './AdminFlightDateRow.module.scss';

interface AdminFlightDateRowProps {
  children: React.ReactNode[];
  flightDate: FlightDate;
}

const AdminFlightDateRow = ({ children, flightDate }: AdminFlightDateRowProps): JSX.Element => {
  const { date, isToday } = flightDate;

  return (
    <div className={clsx(styles.root, { [styles.today]: isToday })}>
      <div className={clsx(styles.date, { [styles.dateToday]: isToday })}>
        <span className={styles.dayOfWeek}>{formatDate(date, DATE_FORMAT.DAY_OF_WEEK)}</span>
        <span className={styles.day}>{formatDate(date, DATE_FORMAT.DATE_NUMBER)}</span>
      </div>
      <div className={styles.flights}>{children}</div>
    </div>
  );
};

export default AdminFlightDateRow;
