import { useState, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { AdminRole, AuthUser } from 'auth/types';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Input from 'theme/input';
import NonAuthedPageLayoutTitle from 'theme/nonAuthedPageLayoutTitle';
import { useFormValidation, useTitle } from 'utils/hooks';
import styles from './Login.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  passwordRequired: '',
};

function Login(): JSX.Element {
  const navigate = useNavigate();
  const { authStore, toastsStore } = useStores();
  const { isEAD, login, loading } = authStore;
  const { addToast } = toastsStore;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useTitle('Login');

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired']);
    setEmail(value);
  };

  const handlePasswordChange = (value: string): void => {
    cleanValidationErrors(['passwordRequired']);
    setPassword(value);
  };

  const navigateAfterLogin = (user: AuthUser): void => {
    const shouldGoToOnboarding = !isEAD && user.ownedOrganizationsWithCompletedOnboarding.length === 0;

    const superAdminLink = user.userRoles.includes(AdminRole.SuperAdmin) ? paths.individualMemberships : paths.members;
    const link = shouldGoToOnboarding ? paths.onboarding('payment') : superAdminLink;

    navigate(link, {
      state: { redirectedFromLogin: true },
    });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();

    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('passwordRequired', formValidationMessages.PASSWORD_REQUIRED, !password);

    if (!email || !password) return;

    login(email.trim(), password)
      .then(({ user }) => navigateAfterLogin(user))
      .catch((err) => addToast(err.message));
  };

  return (
    <>
      <NonAuthedPageLayoutTitle title="Login" />
      <form onSubmit={onSubmit} className={styles.form}>
        <Input
          label="Email"
          name="email"
          onChange={(e) => handleEmailChange(e.target.value)}
          placeholder="Enter your email"
          value={email}
          error={validation.emailRequired}
        />
        <Input
          label="Password"
          name="password"
          onChange={(e) => handlePasswordChange(e.target.value)}
          placeholder="Enter your password"
          type="password"
          value={password}
          error={validation.passwordRequired}
        />

        <div>
          <Link className={styles.forgotLink} to={paths.requestNewPassword}>
            Forgot password?
          </Link>

          <Button text="Login" loading={loading} disabled={loading} type="submit" />
        </div>
      </form>
    </>
  );
}

export default observer(Login);
