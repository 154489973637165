import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import api from 'api';
import { getRefreshToken } from 'api/utils';
import env from 'domain/env';
import { Auth, LoginReturn, RefreshTokenReturn, ResetPasswordReturn } from './types';

export function login(email: string, password: string): LoginReturn {
  return api.post('/auth/login', { email, password });
}

export function requestNewPassword(email: string, redirectUrl: string): ResetPasswordReturn {
  return api.post('/password-reset/send-token', { email, redirectUrl });
}

export function resetPassword(password: string, token: string): ResetPasswordReturn {
  return api.post('/password-reset/update-password', { passwordPlain: password, token });
}

export function createRefreshToken(token: string | null): RefreshTokenReturn {
  const parsedApiCredentials: Auth = token ? JSON.parse(token) : null;

  return axios.put(
    `${env.backendUrl}/auth/refresh-tokens/${uuidv4()}`,
    {},
    {
      headers: { Authorization: `Bearer ${parsedApiCredentials.token}` || '' },
    }
  );
}

export function loginWithRefreshToken(): LoginReturn {
  const credentials = getRefreshToken();
  const { deviceId, value } = credentials && JSON.parse(credentials);

  return axios.post(
    `${env.backendUrl}/auth/refresh-token`,
    { deviceId, value },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
}
