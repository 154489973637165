import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import clsx from 'clsx';
import styles from './LazyImage.module.scss';

interface LazyImageProps {
  alt?: string;
  contained?: boolean;
  height?: string;
  placeholderSrc?: string;
  src: string;
  width?: string;
}

const LazyImage = ({ alt, contained = false, height, placeholderSrc, src, width }: LazyImageProps): JSX.Element => {
  return (
    <LazyLoadImage
      className={clsx(styles.root, { [styles.contained]: contained })}
      src={src}
      alt={alt || ''}
      effect="blur"
      placeholderSrc={placeholderSrc}
      width={width}
      height={height}
    />
  );
};

export default LazyImage;
