import { TableColumn } from 'domain/types';
import { UserColumn } from './types';

export const initiallySelectedColumns = [UserColumn.Phone, UserColumn.IndMembership, UserColumn.CorporateMembership];

export const tableColumns: TableColumn<UserColumn>[] = [
  { seq: 1, name: UserColumn.No, static: true },
  { seq: 2, name: UserColumn.Name, static: true },
  { seq: 3, name: UserColumn.Phone, static: false },
  { seq: 4, name: UserColumn.Sex, static: false },
  { seq: 5, name: UserColumn.BirthDate, static: false },
  { seq: 6, name: UserColumn.Country, static: false },
  { seq: 7, name: UserColumn.ActiveUntil, static: false },
  { seq: 8, name: UserColumn.IndMembership, static: false },
  { seq: 9, name: UserColumn.CorporateMembership, static: false },
];
