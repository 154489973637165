import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import FlightRoute from 'flights/flightRoute';
import FlightSeats from 'flights/flightSeats';
import FlightStatusTag from 'flights/flightStatusTag';
import { Flight } from 'flights/types';
import Button from 'theme/button';
import styles from './AvailableFlightRow.module.scss';

interface AvailableFlightRowProps {
  flight: Flight;
}

const AvailableFlightRow = ({ flight }: AvailableFlightRowProps): JSX.Element => {
  const navigate = useNavigate();
  const { corporateFlightsStore } = useStores();
  const { openModal } = corporateFlightsStore;

  const { departureDate, departureFrom, departureTo, flightNumber, fullDepartureTime, seatCompletion, status } = flight;
  const flightDetailsParams = {
    departureDate,
    departureFrom: departureFrom.iata,
    departureTo: departureTo.iata,
    flightNumber,
  };

  const onModalOpen = (): void => {
    openModal();
    navigate({
      pathname: paths.corporateFlights('available'),
      search: `?${new URLSearchParams(flightDetailsParams).toString()}`,
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <span>{fullDepartureTime}</span>
      </div>
      <FlightRoute flight={flight} type="airports" />
      <div className={styles.right}>
        <FlightSeats flight={flight} smallProgressBar withLabel />
        <FlightStatusTag status={status} />
        <Button onClick={onModalOpen} text="Book" disabled={seatCompletion === 100} />
      </div>
    </div>
  );
};

export default AvailableFlightRow;
