import { ReactNode } from 'react';

interface DataListProps {
  children: ReactNode | ReactNode[];
  dataLength: number;
  textEmpty: string;
}

const DataList = ({ children, dataLength, textEmpty }: DataListProps): JSX.Element => {
  return <>{dataLength > 0 ? children : <p>{textEmpty}</p>}</>;
};

export default DataList;
