import { FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import UserDetailsFormTable from 'theme/userDetailsFormTable';
import { UserModalName } from 'users/types';
import { useFormValidation, useUserDetailsForm } from 'utils/hooks';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';

interface UserFormModalProps {
  isUserDetailsView?: boolean;
}

const FORM_VALIDATIONS: FormValidation = {
  emailInvalid: '',
  emailRequired: '',
  firstNameRequired: '',
  lastNameRequired: '',
  phoneInvalid: '',
  phoneRequired: '',
  roleRequired: '',
};

function UserFormModal({ isUserDetailsView = false }: UserFormModalProps): JSX.Element {
  const { toastsStore, usersStore } = useStores();
  const { addToast, toastMessages } = toastsStore;
  const { closeModal, modalName, isSubmitting, selectedUser, updateUser } = usersStore;

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const { isFormSubmitDisabled, resetFormValues, userFormData } = useUserDetailsForm({
    cleanValidationErrors,
    selected: selectedUser,
    validation,
  });
  const { email, firstName, lastName, phone } = userFormData;

  const onModalClose = (): void => {
    closeModal();
    resetFormValues();
    resetValidation();
  };

  const validateOnSubmit = (): void => {
    handleValidation('firstNameRequired', formValidationMessages.FIRST_NAME_REQUIRED, !firstName);
    handleValidation('lastNameRequired', formValidationMessages.LAST_NAME_REQUIRED, !lastName);
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation('phoneRequired', formValidationMessages.PHONE_REQUIRED, !phone);
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, !!phone && !PHONE_REGEX.test(phone));
  };

  const editUser = (): void | undefined => {
    validateOnSubmit();
    if (!selectedUser || isFormSubmitDisabled) return;

    updateUser(
      selectedUser.id,
      {
        email,
        firstName,
        lastName,
        phone,
      },
      isUserDetailsView
    )
      .then(() => {
        addToast(toastMessages.USER.UPDATE_SUCCESS, 'success');
        onModalClose();
      })
      .catch(({ response }) => {
        let msg;

        if (response?.status === 409) {
          switch (true) {
            case response.data.includes('email'):
              msg = toastMessages.USER.EMAIL_IN_USE_ERROR;
              break;
            case response.data.includes('phone'):
              msg = toastMessages.USER.PHONE_IN_USE_ERROR;
              break;
            default:
              msg = toastMessages.ERROR.DEFAULT;
          }
        } else msg = toastMessages.ERROR.DEFAULT;

        addToast(msg);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    editUser();
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={modalName === UserModalName.UserForm}
      title={selectedUser?.id ? 'Edit user' : 'Add user'}
    >
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <UserDetailsFormTable userFormData={userFormData} validation={validation} />
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(UserFormModal);
