import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import DataList from 'theme/dataList';
import LoadingArea from 'theme/loadingArea';
import styles from './Bookings.module.scss';
import BookingRow from './bookingRow';

const Bookings = (): JSX.Element => {
  const { corporateFlightsStore } = useStores();
  const { bookings, isLoadingBookings } = corporateFlightsStore;

  return (
    <div className={styles.root}>
      <LoadingArea loading={isLoadingBookings}>
        <DataList dataLength={bookings.length} textEmpty="No planned flights found!">
          <div className={styles.flightList}>
            {bookings.map((b) => (
              <BookingRow key={b.pnrCode} booking={b} />
            ))}
          </div>
        </DataList>
      </LoadingArea>
    </div>
  );
};

export default observer(Bookings);
