import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import AddUserToCompanyModal from 'companies/addUserToCompanyModal';
import { CompanyModalName } from 'companies/types';
import { MemberModalName } from 'members/types';
import Breadcrumbs from 'theme/breadcrumbs';
import Button from 'theme/button';
import LoadingArea from 'theme/loadingArea';
import MembershipTag from 'theme/membershipTag';
import PageHeader from 'theme/pageHeader';
import Pagination from 'theme/pagination';
import SearchPanel from 'theme/searchPanel';
import SortableHeadCell from 'theme/sortableHeadCell';
import TableItemName from 'theme/tableItemName';
import VerificationTag from 'theme/verificationTag';
import { useAdminPermissionsForUIManagement, useTitle } from 'utils/hooks';
import DeleteMemberModal from './deleteMemberModal';
import EditMemberModal from './editMemberModal';
import InviteMemberModal from './inviteMemberModal';
import MemberRowButtonActions from './memberRowButtonActions';

interface MembersProps {
  isEAD?: boolean;
}

function Members({ isEAD }: MembersProps): JSX.Element {
  const { companiesStore, membersStore } = useStores();
  const {
    changePageNumber,
    changePageSize,
    clearFilters,
    fetchMembers,
    filters,
    isLoadingMembers,
    members,
    membersCount,
    openModal,
    pageNumber,
    pageSize,
    renderManagerTagLabel,
    resetFiltersSortAndPagination,
    sortingProps,
  } = membersStore;
  const { company, openModal: openAddUserToCompanyModal } = companiesStore;

  const { companyId } = useParams();
  const isEditionForbidden = useAdminPermissionsForUIManagement();

  useEffect(() => {
    if (!isEAD) fetchMembers({ pageSize, pageNumber, onInit: true });
  }, [isEAD]);

  useEffect(() => {
    return () => {
      resetFiltersSortAndPagination();
    };
  }, []);

  useTitle(isEAD ? '' : 'Members');

  const handleSearch = useCallback(() => {
    fetchMembers({ companyIdParam: companyId, pageSize, pageNumber, resetPreviousData: true });
  }, [fetchMembers, pageSize, pageNumber]);

  const searchPanel = (
    <SearchPanel
      loading={isLoadingMembers}
      filters={filters}
      reset={clearFilters}
      search={handleSearch}
      title="Search members"
    >
      {isEAD && company && !isEditionForbidden && (
        <Button
          text="Add member"
          onClick={() => openAddUserToCompanyModal(CompanyModalName.AddUserToCompany, company)}
        />
      )}
    </SearchPanel>
  );

  const memberDetailsRedirectLink = (memberId: string): string =>
    isEAD ? paths.companyMember(companyId ?? '', memberId, 'details') : paths.member(memberId);

  const content = (
    <LoadingArea loading={isLoadingMembers}>
      <div className="main-container">
        <table className="horizontal-table">
          <thead>
            <tr>
              <SortableHeadCell columnName="CreatedAt" name="No." {...sortingProps} />
              <th data-th="Role">Role</th>
              <SortableHeadCell columnName="UserName" name="User" {...sortingProps} />
              <th data-th="Phone">Phone</th>
              <th data-th="Status">Status</th>
              <th data-th="Action" />
            </tr>
          </thead>
          <tbody>
            {members.map((m) => {
              return (
                <tr key={m.id}>
                  <td data-th="No.">{m.sequenceId}</td>
                  <td data-th="Role">
                    <MembershipTag
                      label={renderManagerTagLabel(m)}
                      userRole={m.role}
                      isManagingCharters={m.isManagingCharters}
                      isManagingFinancial={m.isManagingFinancial}
                    />
                  </td>
                  <td data-th="User">
                    <TableItemName title={m.user.name} subtitle={m.user.email} link={memberDetailsRedirectLink(m.id)} />
                  </td>
                  <td data-th="Phone">{m.user.phone}</td>
                  <td data-th="Status">
                    <VerificationTag verified={m.user.identityConfirmed} />
                  </td>
                  <td data-th="Action">
                    <MemberRowButtonActions member={m} isEAD={!!isEAD} link={memberDetailsRedirectLink(m.id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={pageNumber}
          activePageItemCount={members.length}
          allItemCount={membersCount}
          disabled={isLoadingMembers}
          goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
          pageSize={pageSize}
          onPageSizeChange={changePageSize}
        />
      </div>
    </LoadingArea>
  );

  const superAdminContent = (
    <>
      {searchPanel}
      {content}
      <AddUserToCompanyModal />
    </>
  );

  const corporateAdminContent = (
    <>
      <Breadcrumbs current="Members" />
      <PageHeader title="Members">
        <Button onClick={() => openModal(MemberModalName.InviteMember, company)} text="Add member" />
      </PageHeader>
      {searchPanel}
      {content}
      <InviteMemberModal />
      <EditMemberModal />
    </>
  );

  return (
    <>
      {isEAD ? superAdminContent : corporateAdminContent}
      <DeleteMemberModal />
    </>
  );
}

export default observer(Members);
