import { FunctionComponent, MouseEvent, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { TableAction, TableActionBtn } from 'domain/types';
import Icon, { icons } from 'theme/icon';
import styles from './IconButton.module.scss';

const ICON_MAP: Record<TableAction, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  [TableAction.Add]: icons.add,
  [TableAction.Block]: icons.block,
  [TableAction.Delete]: icons.delete,
  [TableAction.Details]: icons.details,
  [TableAction.Edit]: icons.edit,
  [TableAction.ExtLink]: icons.intercom,
  [TableAction.Remove]: icons.remove,
  [TableAction.Settings]: icons.settings,
};

function IconButton({ action, className = '', disabled = false, link, text, type }: TableActionBtn): JSX.Element {
  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    action?.();
  };

  const content = (
    <>
      <Icon icon={ICON_MAP[type]} />
      {text && <span>{text}</span>}
    </>
  );

  const extLink = (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={clsx(styles.root, { [styles.linkDisabled]: !link }, className)}
    >
      {content}
    </a>
  );

  const intLink = link ? (
    <Link to={link} className={clsx(styles.root, className)}>
      {content}
    </Link>
  ) : null;

  const btn = (
    <button className={clsx(styles.root, className)} onClick={handleClick} disabled={disabled}>
      {content}
    </button>
  );

  const renderElement = (): JSX.Element | null => {
    if (typeof link === 'string') return type === TableAction.ExtLink ? extLink : intLink;
    return btn;
  };

  return <>{renderElement()}</>;
}

export default IconButton;
