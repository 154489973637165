import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import Button from 'theme/button';
import Confirm from 'theme/confirm';
import FileUpload from 'theme/fileUpload';
import MembershipTag from 'theme/membershipTag';
import Modal from 'theme/modal';
import TableCard from 'theme/tableCard';
import Tag, { TagColor } from 'theme/tag';
import VerificationTag from 'theme/verificationTag';
import { UserModalName } from 'users/types';
import { useAdminPermissionsForUIManagement } from 'utils/hooks';
import styles from './UserDetailsTable.module.scss';

function UserDetailsTable(): JSX.Element | null {
  const { membersStore, usersStore, toastsStore } = useStores();
  const { renderManagerTagLabel } = membersStore;
  const {
    closeModal,
    confirmUserIdentity,
    deleteUserIdentityDocument,
    isUploadingAsset,
    isSubmitting,
    modalName,
    openModal,
    uploadFile,
    uploadProgress,
    user,
    userIdentityDocument,
  } = usersStore;
  const { addToast, toastMessages } = toastsStore;

  if (!user) return null;

  const isEditionForbidden = useAdminPermissionsForUIManagement();

  const verifyUser = (): void => {
    confirmUserIdentity(user.id)
      .then(() => addToast(toastMessages.USER.ID_CONFIRMATION_SUCCESS, 'success'))
      .catch(() => addToast(toastMessages.USER.ID_CONFIRMATION_ERROR));
  };

  const removeUserIdentityDocument = (): void => {
    deleteUserIdentityDocument(user.id)
      .then(() => addToast(toastMessages.USER.ID_PHOTO_REMOVAL_SUCCESS, 'success'))
      .catch(() => addToast(toastMessages.USER.ID_PHOTO_REMOVAL_ERROR));
  };

  const handleFileUpload = (file: File | null): Promise<void> => {
    if (!file) return Promise.resolve();

    return uploadFile(user.id, file)
      .then(() => addToast(toastMessages.USER.ID_UPLOAD_SUCCESS, 'success'))
      .catch(() => addToast(toastMessages.USER.ID_UPLOAD_ERROR));
  };

  return (
    <div className={styles.root}>
      <TableCard className={styles.card} title="User information" vertical>
        <table>
          <tbody>
            <tr>
              <th>Id</th>
              <td data-th="Id">{user.id}</td>
            </tr>
            <tr>
              <th>First name</th>
              <td data-th="First name">{user.firstName}</td>
            </tr>
            <tr>
              <th>Last name</th>
              <td data-th="Last name">{user.lastName}</td>
            </tr>
            <tr>
              <th>Preferred name</th>
              <td data-th="Preferred name">{user.preferredName}</td>
            </tr>
            <tr>
              <th>Birth date</th>
              <td data-th="Birth date">{user.birthDate}</td>
            </tr>
            <tr>
              <th>Biological sex</th>
              <td data-th="Biological sex">{user.sex}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td data-th="Email">{user.email}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <td data-th="Phone">{user.phone}</td>
            </tr>
            <tr>
              <th>Country of Residence</th>
              <td data-th="Country of Residence">{user.country}</td>
            </tr>
            <tr>
              <th>Date created</th>
              <td data-th="Date created">{user.createdAt}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>Membership type</th>
              <td data-th="Membership type">
                {user.isPremium ? <Tag text="Premium" color={TagColor.Brown} /> : <Tag text="Free" />}
              </td>
            </tr>
            <tr>
              <th>Active until</th>
              <td data-th="Active until">{user.activeUntil}</td>
            </tr>
            <tr>
              <th>Flight deposit</th>
              <td data-th="Flight deposit">{user.flightDeposit && `$${user.flightDeposit}`}</td>
            </tr>
            <tr>
              <th>Corporate role</th>
              <td data-th="Corporate role">
                <ul>
                  {user.corporateOrganizations.map((o) => (
                    <li key={o.id} className={styles.corporateRole}>
                      <MembershipTag
                        label={renderManagerTagLabel(o)}
                        userRole={o.role}
                        isManagingCharters={user.isManagingCharters}
                        isManagingFinancial={user.isManagingFinancial}
                      />{' '}
                      at <span className={styles.organizationName}>{o.organization.name}</span>
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </TableCard>

      <TableCard className={styles.card} title="ID" vertical>
        <table>
          <tbody>
            <tr>
              <th>Status</th>
              <td data-th="Status">
                <VerificationTag verified={user.identityConfirmed} />
                {!user.identityConfirmed && !isEditionForbidden && (
                  <Button
                    buttonType="primary"
                    text="Confirm verification"
                    onClick={() => openModal(UserModalName.IDConfirmation)}
                    disabled={!userIdentityDocument}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <FileUpload
          className={styles.fileUpload}
          onFileUpload={handleFileUpload}
          text="Upload ID photo"
          loading={isUploadingAsset}
          uploadProgress={uploadProgress}
          disabled={isEditionForbidden}
          imageUrl={userIdentityDocument}
          removeImage={() => openModal(UserModalName.RemoveDocument)}
        />
      </TableCard>

      <Modal onClose={closeModal} isOpen={modalName === UserModalName.IDConfirmation}>
        <Confirm
          action={verifyUser}
          close={closeModal}
          submitting={isSubmitting}
          question="Are you sure that you want to confirm the identity of this user?"
        />
      </Modal>
      <Modal onClose={closeModal} isOpen={modalName === UserModalName.RemoveDocument}>
        <Confirm
          action={removeUserIdentityDocument}
          close={closeModal}
          submitting={isSubmitting}
          question="Are you sure that you want to remove the ID photo?"
        />
      </Modal>
    </div>
  );
}

export default observer(UserDetailsTable);
