import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import Wallet from 'corporateAdmin/wallet';
import { SIDEBAR_VISIBILITY_STORAGE_NAME } from 'domain/constants';
import Icon, { icons } from 'theme/icon';
import { images } from 'theme/images';
import Loader from 'theme/loader';
import Select from 'theme/select';
import styles from './Layout.module.scss';
import * as menuLinks from './menuLinks';
import SideMenu from './sideMenu';
import UserMenu from './userMenu';

const LS_SIDEBAR_VISIBILITY = localStorage.getItem(SIDEBAR_VISIBILITY_STORAGE_NAME);

const Layout = (): JSX.Element => {
  const { authStore, corporateAdminStore } = useStores();
  const { isAuthenticated, isEAD } = authStore;
  const {
    corporateAdminOrganizationOptions,
    isLoadingCorporateAdminUser,
    selectedCorporateAdminOrganizationOption,
    setCorporateAdminOrganization,
  } = corporateAdminStore;

  const [isSidebarVisible, setIsSidebarVisible] = useState(LS_SIDEBAR_VISIBILITY !== 'false');

  const membershipLinks = isEAD ? menuLinks.membershipSuperAdmin : [];
  const manageLinks = isEAD ? menuLinks.manageSuperAdmin : menuLinks.manageCorporateAdmin;

  const updateLsSidebarVisibility = (value: boolean): void => {
    localStorage.setItem(SIDEBAR_VISIBILITY_STORAGE_NAME, value.toString());
  };

  const toggleSidebar = (): void => {
    const updatedSidebarVisibility = !isSidebarVisible;
    setIsSidebarVisible(updatedSidebarVisibility);
    updateLsSidebarVisibility(updatedSidebarVisibility);
  };

  const renderOrganizationSelect = (): JSX.Element | null => {
    if (isEAD) return null;

    return (
      <div className={styles.organizationSelectContainer}>
        <Loader small loading={isLoadingCorporateAdminUser} />
        {!isLoadingCorporateAdminUser && (
          <Select
            className={styles.organizationSelect}
            disabled={corporateAdminOrganizationOptions.length === 1}
            onChange={setCorporateAdminOrganization}
            options={corporateAdminOrganizationOptions}
            value={selectedCorporateAdminOrganizationOption}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (LS_SIDEBAR_VISIBILITY === 'false') setIsSidebarVisible(false);
  }, []);

  return (
    <div className={styles.root}>
      <aside className={clsx(styles.sidebar, { [styles.sidebarHidden]: !isSidebarVisible })}>
        {isSidebarVisible && (
          <>
            <div className={styles.logoContainer}>
              <img src={images.png.miniLogo} alt="logo" height="30" width="30" />
              <h1 className={styles.companyName}>EFC</h1>
            </div>

            {renderOrganizationSelect()}

            <SideMenu title="memberships" menuLinks={membershipLinks} />
            <SideMenu title="manage" menuLinks={manageLinks} />
            <Wallet nav />
          </>
        )}
      </aside>

      <button
        className={clsx(styles.toggleSidebarBtn, { [styles.toggleSidebarBtnActive]: !isSidebarVisible })}
        onClick={toggleSidebar}
      >
        <Icon icon={icons.chevronLeftDouble} />
      </button>

      <main className={styles.main}>
        <Outlet />
        {isAuthenticated && <UserMenu />}
      </main>
    </div>
  );
};

export default observer(Layout);
