import React from 'react';
import clsx from 'clsx';
import { MembershipRoleOption, Option } from 'domain/types';
import Icon, { icons } from 'theme/icon';
import Loader from 'theme/loader';
import styles from './SelectDropdown.module.scss';

type SelectDropdownProps<T> = {
  loading?: boolean;
  onSelect: (option: T) => void;
  options: T[];
  selectedOption: T;
  up?: boolean;
};

const SelectDropdown = <T extends Option | MembershipRoleOption>({
  loading,
  onSelect,
  options,
  selectedOption,
  up = false,
}: SelectDropdownProps<T>): JSX.Element => {
  const optionList = options.map((o) => (
    <div
      key={o.value}
      className={clsx(styles.option, { [styles.selected]: selectedOption?.value === o.value })}
      onClick={() => onSelect(o)}
    >
      <span className={styles.optionLabel}>{o.label}</span>
      {selectedOption?.value === o.value ? <Icon icon={icons.tick} /> : null}
    </div>
  ));

  return (
    <div className={clsx(styles.root, { [styles.hoisted]: up })}>
      <Loader small loading={loading} className={styles.loader} />
      {!loading && <>{options.length > 0 ? optionList : <p className={styles.empty}>No results!</p>}</>}
    </div>
  );
};

export default SelectDropdown;
