import { Auth, RefreshAuth } from 'auth';
import { LOGIN_STORAGE_PREFIX, REFRESH_TOKEN_STORAGE_PREFIX } from 'domain/constants';

const LOCAL_STORAGE_AUTH_NAME = `${LOGIN_STORAGE_PREFIX}${location.hostname}`;
const LOCAL_STORAGE_REFRESH_AUTH_NAME = `${REFRESH_TOKEN_STORAGE_PREFIX}${location.hostname}`;

// eslint-disable-next-line
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const getAPICredentials = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_AUTH_NAME) || null;
};

export const saveAPICredentials = (token: string): Auth => {
  const user = parseJwt(token);
  const newSerializedState: Auth = { token, user };

  localStorage.setItem(LOCAL_STORAGE_AUTH_NAME, JSON.stringify(newSerializedState));

  return newSerializedState;
};

export function removeAPICredentials(): void {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_NAME);
}

export const saveRefreshToken = (refAuth: RefreshAuth): void => {
  localStorage.setItem(LOCAL_STORAGE_REFRESH_AUTH_NAME, JSON.stringify(refAuth));
};

export const removeRefreshToken = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_AUTH_NAME);
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_REFRESH_AUTH_NAME) || null;
};
