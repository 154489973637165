import axios from 'axios';
import { Base64 } from 'js-base64';
import api from 'api';
import { OrganizationKind } from 'domain/types';
import { searchObjectToArray } from 'utils/helpers';
import {
  AssetType,
  ConfirmUserIdentityReturn,
  FetchUsersParams,
  IntercomConfigReturn,
  OrganizationFeeReturn,
  QuestionsReturn,
  UserDto,
  UserAddressesReturn,
  UserIdentityDocumentReturn,
  UserReturn,
  UsersReturn,
} from './types';

export const fetchUser = (userId: string): UsersReturn => {
  return api.get('/users', { params: { pageSize: 1, pageNumber: 1, id: userId } });
};

export const fetchUsers = ({
  corporateRoles,
  id,
  isIdentityConfirmed,
  isPremium,
  organizationId,
  pageNumber,
  pageSize,
  searchValues,
  sortBy,
}: FetchUsersParams): UsersReturn => {
  const hasChosenCorporateRoles = corporateRoles?.length;

  return api.get('/users', {
    params: {
      ...(id && { id }),
      pageSize,
      pageNumber,
      sort: sortBy,
      ...(hasChosenCorporateRoles && { membershipRole: corporateRoles }),
      ...(hasChosenCorporateRoles && { organizationKind: OrganizationKind.Corporate }),
      ...(typeof isIdentityConfirmed !== 'undefined' && { isIdentityConfirmed }),
      ...(typeof isPremium !== 'undefined' && { isPremium }),
      ...(organizationId && { organizationId }),
      ...(searchValues && { ...searchObjectToArray(searchValues) }),
    },
  });
};

export const fetchOrganizationFee = (organizationId: string): OrganizationFeeReturn => {
  return api.get(`/payments/fees/${organizationId}`);
};

export const createUser = (data: UserDto): UserReturn => {
  return api.post('/users', data);
};

export const updateUser = (id: string, data: UserDto): UserReturn => {
  return api.patch(`/users/${id}`, data);
};

export const confirmUserIdentity = (userId: string): ConfirmUserIdentityReturn => {
  return api.post(`/admin/users/${userId}/confirm-identity`);
};

interface UploadAssetToS3Params {
  fileType: string;
  uint8Array: Uint8Array;
  updateProgress: (value: number) => void;
  url: string;
}

function uploadAssetToS3({ fileType, uint8Array, url, updateProgress }: UploadAssetToS3Params): Promise<void> {
  return axios({
    method: 'put',
    url,
    data: uint8Array,
    headers: {
      'Content-Type': fileType,
    },
    onUploadProgress: ({ lengthComputable, loaded, target, total }) => {
      const totalLength = lengthComputable
        ? total
        : target.getResponseHeader('content-length') || target.getResponseHeader('x-decompressed-content-length');
      if (totalLength !== null) {
        updateProgress?.(Math.round((loaded * 100) / totalLength));
      }
    },
  }).then(({ data }) => data);
}

interface UploadAssetParams {
  assetKey: AssetType;
  file: File;
  updateProgress: (value: number) => void;
  userId: string;
}

export const uploadAsset = ({ userId, assetKey, file, updateProgress }: UploadAssetParams): Promise<string> => {
  return new Promise((resolve, reject) => {
    api.post(`/users/${userId}/assets/${assetKey}`).then(({ data }) => {
      const reader = new FileReader();
      reader.onload = () => {
        const resultString = reader.result as string;
        const base64Data = resultString.split(',')[1];
        const uint8Array = Base64.toUint8Array(base64Data);

        uploadAssetToS3({ fileType: file.type, uint8Array, url: data.presignedUrl, updateProgress })
          .then(() => resolve(data.presignedUrl))
          .catch((error) => reject(error));
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  });
};

export const fetchUserIdentityDocument = (userId: string): UserIdentityDocumentReturn => {
  return api.get(`/users/${userId}/assets/identity_document`);
};

export const deleteUserIdentityDocument = (userId: string): Promise<void> => {
  return api.delete(`/users/${userId}/assets/identity_document`);
};

export const fetchUserAddresses = (userId: string): UserAddressesReturn => {
  return api.get(`/users/${userId}/addresses`);
};

export const fetchQuestions = (userId: string): QuestionsReturn => {
  return api.get('/onboardings', { params: { userId } });
};

export const fetchIntercomConfig = (): IntercomConfigReturn => {
  return api.get('/admin/intercom/config');
};
