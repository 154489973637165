import { paths } from 'app/routes/paths.const';
import { AdminPermission } from 'domain/types';
import { icons } from 'theme/icon';
import { MenuLink } from './types';

export const membershipSuperAdmin: MenuLink[] = [
  {
    label: 'Individual',
    name: 'individual-memberships',
    permissionId: AdminPermission.IndividualMemberships,
    to: paths.individualMemberships,
    icon: icons.user,
  },
  {
    label: 'Corporate',
    name: 'companies',
    permissionId: AdminPermission.CorporateMemberships,
    to: paths.companies,
    icon: icons.corporate,
  },
];

export const manageSuperAdmin: MenuLink[] = [
  {
    label: 'Flights',
    name: 'flights',
    permissionId: AdminPermission.Charters,
    to: paths.adminFlights,
    icon: icons.flight,
  },
  // {
  //   label: 'Payments',
  //   name: 'payments',
  //   to: paths.payments,
  //   icon: icons.payment,
  // },
  {
    label: 'Team',
    name: 'team',
    permissionId: AdminPermission.EntourageTeam,
    to: paths.team('entourage'),
    icon: icons.staff,
  },
];

export const manageCorporateAdmin: MenuLink[] = [
  {
    label: 'Members',
    name: 'members',
    to: paths.members,
    icon: icons.user,
  },
  {
    label: 'Flights',
    name: 'flights-main',
    to: null,
    icon: icons.flight,
    disabled: true,
    sublinks: [
      {
        label: 'Book flight',
        name: 'corporate-flights',
        to: paths.corporateFlights('planned'),
      },
      { label: 'Guests', name: 'guests', to: paths.guests },
    ],
  },
  // {
  //   label: 'Payments',
  //   name: 'payments',
  //   to: paths.payments,
  //   icon: icons.payment,
  // },
  {
    label: 'Account',
    name: 'account',
    to: paths.account,
    icon: icons.corporate,
  },
];
