import React from 'react';
import clsx from 'clsx';
import styles from './HorizontalScrollTable.module.scss';

interface HorizontalScrollTableProps {
  columnCount: number;
  left: JSX.Element;
  middle: JSX.Element;
  right: JSX.Element;
  withSearch?: boolean;
}

const HorizontalScrollTable = ({
  columnCount,
  left,
  middle,
  right,
  withSearch = false,
}: HorizontalScrollTableProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, { [styles.withSearch]: withSearch })}>
      <div
        className={clsx(styles.tableContainer, styles.noBorderRight, styles.left, {
          [styles.leftNoColumnSelected]: !columnCount,
        })}
      >
        {left}
      </div>
      <div
        className={clsx(styles.tableContainer, styles.noBorderRight, styles.middle, {
          [styles.middleNoColumnSelected]: !columnCount,
        })}
      >
        {middle}
      </div>
      <div className={clsx(styles.tableContainer, styles.right)}>{right}</div>
    </div>
  );
};

export default HorizontalScrollTable;
