import React from 'react';
import { CustomOption, Gender, Option } from 'domain/types';
import RadioButton from 'theme/radioButton';
import styles from './RadioOptions.module.scss';

interface RadioOptionsProps<T> {
  onChange: (value: T) => void;
  options: T[];
  selected: T | undefined;
}

const RadioOptions = <T extends Option | CustomOption<Gender>>({
  onChange,
  options,
  selected,
}: RadioOptionsProps<T>): JSX.Element => {
  return (
    <div className={styles.root}>
      {options.map((o) => (
        <div key={o.value} className={styles.optionRow} onClick={() => onChange(o)}>
          <RadioButton checked={selected?.value === o.value} />
          <span className={styles.label}>{o.label}</span>
        </div>
      ))}
    </div>
  );
};

export default RadioOptions;
