import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import { DATE_FORMAT } from 'utils/helpers';
import styles from './Datepicker.module.scss';

interface DatepickerProps {
  className?: string;
  clear: () => void;
  error?: string;
  handleDate: (date: Date) => void;
  inTable?: boolean;
  label?: string;
  maxDate?: Date;
  selectedDate: Date | undefined;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
}

const Datepicker = ({
  className = '',
  clear,
  error,
  handleDate,
  inTable = false,
  label,
  maxDate,
  selectedDate,
  showMonthDropdown = false,
  showYearDropdown = false,
}: DatepickerProps): JSX.Element | null => {
  const icon = selectedDate ? (
    <button className={styles.clearBtn} onClick={clear} type="button">
      <Icon icon={icons.cross} />
    </button>
  ) : (
    <Icon icon={icons.calendar} />
  );

  return (
    <div className={clsx(styles.root, className, { [styles.hasError]: error })}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.picker}>
        <div className={styles.pickerContainer}>
          <DatePicker
            className={styles.datePicker}
            selected={selectedDate}
            onChange={(date) => handleDate(date)}
            dateFormat={DATE_FORMAT.DAY_FULL_ALT}
            placeholderText="Select date"
            maxDate={maxDate}
            showYearDropdown={showYearDropdown}
            showMonthDropdown={showMonthDropdown}
          />
        </div>
        {icon}
      </div>
      {error && <span className={clsx(styles.error, { [styles.errorInTable]: inTable })}>{error}</span>}
    </div>
  );
};

export default Datepicker;
