import { useState, useEffect } from 'react';
import { CompanyColumn } from 'companies/types';
import { TableColumn } from 'domain/types';
import { UserColumn } from 'users/types';

interface UseCustomizedTableColumnsProps<T extends UserColumn | CompanyColumn> {
  initiallySelectedColumns: T[];
  localStorageName: string;
  tableColumns: TableColumn<T>[];
}

interface UseCustomizedTableColumnsReturn<T extends UserColumn | CompanyColumn> {
  activeColumnCount: number;
  columnsToSave: TableColumn<T>[];
  handleColumnsToSave: (column: TableColumn<T>) => void;
  saveActiveColumns: () => void;
  savedActiveColumns: TableColumn<T>[];
}

export function useCustomizedTableColumns<T extends UserColumn | CompanyColumn>({
  initiallySelectedColumns,
  localStorageName,
  tableColumns,
}: UseCustomizedTableColumnsProps<T>): UseCustomizedTableColumnsReturn<T> {
  const [columnsToSave, setColumnsToSave] = useState<TableColumn<T>[]>([]);

  const handleColumnsToSave = (column: TableColumn<T>): void => {
    if (columnsToSave.find((c) => c.name === column.name))
      setColumnsToSave(columnsToSave.filter((c) => c.name !== column.name));
    else setColumnsToSave(columnsToSave.concat(column).sort((a, b) => a.seq - b.seq));
  };

  const saveActiveColumns = (): void => {
    localStorage.setItem(localStorageName, JSON.stringify(columnsToSave));
  };

  const cols = localStorage.getItem(localStorageName);
  const savedActiveColumns: TableColumn<T>[] = cols
    ? JSON.parse(cols)
    : tableColumns.filter(({ name }) => initiallySelectedColumns.includes(name));

  useEffect(() => {
    setColumnsToSave(savedActiveColumns);
  }, []);

  return {
    activeColumnCount: savedActiveColumns.length,
    columnsToSave,
    handleColumnsToSave,
    saveActiveColumns,
    savedActiveColumns,
  };
}
