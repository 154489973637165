import api from 'api';
import {
  CreateMembershipDto,
  DeleteMemberReturn,
  FetchMembersReturn,
  InvitationAcceptDto,
  InvitationAcceptReturn,
  MemberInvitationReturn,
  MemberInvitationsReturn,
  MemberReturn,
  MemberSearchValues,
  UpdateMembershipDto,
} from 'members/types';
import { searchObjectToArray } from 'utils/helpers';

interface FetchMembersParams {
  id?: string;
  isIdentityConfirmed?: boolean;
  organizationId?: string;
  pageNumber: number;
  pageSize: number;
  roles?: string[];
  searchValues?: MemberSearchValues;
  sortBy?: string;
}

export const fetchMembers = ({
  id,
  isIdentityConfirmed,
  organizationId,
  pageNumber,
  pageSize,
  roles,
  searchValues,
  sortBy,
}: FetchMembersParams): FetchMembersReturn => {
  return api.get('/memberships', {
    params: {
      ...(id && { id }),
      ...(typeof isIdentityConfirmed !== 'undefined' && { userIsIdentityConfirmed: isIdentityConfirmed }),
      pageSize,
      pageNumber,
      organizationId,
      ...(sortBy && { sort: sortBy }),
      ...(roles && { role: roles }),
      ...(searchValues && { ...searchObjectToArray<MemberSearchValues>(searchValues) }),
    },
  });
};

export const inviteMember = (data: CreateMembershipDto): MemberReturn => {
  return api.post('/memberships/invitations', data);
};

export const editMember = (data: UpdateMembershipDto, membershipId: string): MemberReturn => {
  return api.patch(`/memberships/${membershipId}`, data);
};

export const deleteMember = (memberId: string): DeleteMemberReturn => {
  return api.delete(`/memberships/${memberId}`);
};

export const finishCorporateOwnerOnboarding = (organizationId: string): Promise<void> => {
  return api.post(`/onboardings/corporate-owner/${organizationId}`);
};

export const fetchInvitations = (): MemberInvitationsReturn => {
  return api.get('/memberships/invitations');
};

export const fetchMemberInvitation = (accessToken: string, invitationId: string): MemberInvitationReturn => {
  return api.get('/memberships/invitations/fetch', {
    params: {
      invitationId,
      accessToken,
    },
  });
};

export const acceptInvitation = (data: InvitationAcceptDto): InvitationAcceptReturn => {
  return api.post('/memberships/invitations/token-accept', data);
};
