import { useState, useEffect, useMemo } from 'react';
import { FormValidation } from 'domain/types';
import { FlightGuest } from 'guests/types';
import { TeamMember } from 'team/types';
import { User, UserFormData } from 'users/types';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';

interface UseUserDetailsFormProps<T> {
  cleanValidationErrors: (errors: string[]) => void;
  selected?: T;
  validation: FormValidation;
}

interface UseUserDetailsFormReturn {
  isFormSubmitDisabled: boolean;
  resetFormValues: () => void;
  userFormData: UserFormData;
}

export const useUserDetailsForm = <T extends User | TeamMember | FlightGuest>({
  cleanValidationErrors,
  selected,
  validation,
}: UseUserDetailsFormProps<T>): UseUserDetailsFormReturn => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleFirstNameChange = (value: string): void => {
    cleanValidationErrors(['firstNameRequired']);
    setFirstName(value);
  };

  const handleLastNameChange = (value: string): void => {
    cleanValidationErrors(['lastNameRequired']);
    setLastName(value);
  };

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid']);
    setEmail(value);
  };

  const handlePhoneChange = (value: string): void => {
    cleanValidationErrors(['phoneRequired', 'phoneInvalid']);
    setPhone(value);
  };

  const resetFormValues = (): void => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
  };

  const isFormSubmitDisabled = useMemo(() => {
    const nameCheck = !firstName || !lastName;

    if (validation.emailRequired && validation.phoneRequired)
      return nameCheck || !email || !phone || !EMAIL_REGEX.test(email) || !PHONE_REGEX.test(phone);
    if (validation.emailRequired && !validation.phoneRequired) return nameCheck || !email || !EMAIL_REGEX.test(email);
    if (!validation.emailRequired && validation.phoneRequired) return nameCheck || !phone || !PHONE_REGEX.test(phone);

    return nameCheck;
  }, [firstName, lastName, email, phone]);

  useEffect(() => {
    if (selected) {
      setFirstName(selected.firstName);
      setLastName(selected.lastName);
      setEmail(selected.email || '');
      setPhone(selected.phone || '');
    }
  }, [selected]);

  return {
    isFormSubmitDisabled,
    resetFormValues,
    userFormData: {
      email: email.trim(),
      firstName: firstName.trim(),
      handleEmailChange,
      handleFirstNameChange,
      handleLastNameChange,
      handlePhoneChange,
      lastName: lastName.trim(),
      phone: phone.trim(),
    },
  };
};
