import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { REDIRECT_LINKS } from 'domain/constants';
import Button from 'theme/button';
import Icon, { icons } from 'theme/icon';
import { images } from 'theme/images';
import LazyImage from 'theme/lazyImage';
import { useTitle } from 'utils/hooks';
import styles from './Onboarding.module.scss';
import OnboardingCompanyForm from './OnboardingCompanyForm';
import Steps from './Steps';

const STEP_MAP: Record<string, string> = {
  payment: 'payment',
  details: 'details',
  members: 'members',
};

const Onboarding = (): JSX.Element | null => {
  const { authStore, corporateAdminStore, membersStore, toastsStore } = useStores();
  const { fetchCorporateAdminUser } = corporateAdminStore;
  const { finishCorporateOwnerOnboarding, isSubmitting } = membersStore;
  const { addToast, toastMessages } = toastsStore;

  const { step } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    if (step === STEP_MAP.payment) setActiveStep(1);
    if (step === STEP_MAP.details) setActiveStep(2);
    if (step === STEP_MAP.members) setActiveStep(3);
  }, [step]);

  useTitle('Onboarding');

  useEffect(() => {
    fetchCorporateAdminUser(true);
  }, []);

  const onOnboardingEnd = (shouldShowToast = false): void => {
    finishCorporateOwnerOnboarding()
      .then(() => {
        if (shouldShowToast) addToast(toastMessages.ONBOARDING.FINISH_SUCCESS, 'success');
        navigate(paths.members);
      })
      .catch(() => addToast(toastMessages.ERROR.DEFAULT));
  };

  const skipBtn = <Button text="Skip onboarding" buttonType="transparent" onClick={onOnboardingEnd} />;

  const switchSteps = (): JSX.Element | null => {
    switch (step) {
      case STEP_MAP.payment:
        return (
          <div className={styles.content}>
            <div className={styles.contentContainer}>
              <h1>Complete the payment</h1>
              <p className={clsx(styles.text, 'text')}>
                Your corporate account is pending payment.
                <br />
                To complete the payment and have full access to all functionalities download and install our app.
                <br />
                Register with your email address: <strong>{authStore.loggedInUser?.email}</strong>
              </p>
              <span className={styles.disclaimer}>Don't have Entourage app yet?</span>
              <div className={styles.storeLinks}>
                <a href={REDIRECT_LINKS.appStoreUrl} target="_blank" rel="noopener noreferrer">
                  <img src={images.png.appleStoreImage} alt="App Store" />
                </a>
                <a href={REDIRECT_LINKS.googlePlayUrl} target="_blank" rel="noopener noreferrer">
                  <img src={images.png.googlePlayImage} alt="Google Play" />
                </a>
              </div>
            </div>
            <footer className={styles.footer}>
              {skipBtn}
              <Button text="Next" to={paths.onboarding('details')} />
            </footer>
          </div>
        );
      case STEP_MAP.details:
        return (
          <div className={styles.content}>
            <OnboardingCompanyForm className={styles.contentContainer}>{skipBtn}</OnboardingCompanyForm>
          </div>
        );
      case STEP_MAP.members:
        return (
          <div className={styles.content}>
            <div className={styles.contentContainer}>
              <h1>Add members</h1>
              <p className={clsx(styles.text, 'text')}>Add and manage members of your company.</p>
            </div>
            <footer className={styles.footer}>
              <Button
                text="Open members screen"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => onOnboardingEnd(true)}
              />
            </footer>
          </div>
        );
      default:
        return null;
    }
  };

  const switchBackgroundImage = (): string | undefined => {
    switch (step) {
      case STEP_MAP.payment:
        return `url(${images.png.holdingHandImage})`;
      case STEP_MAP.members:
        return `url(${images.png.screensImage})`;
      default:
        return undefined;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div
          className={clsx(styles.area, styles.image, { [styles.fileUploadContainer]: step === STEP_MAP.details })}
          style={{ backgroundImage: switchBackgroundImage() }}
        >
          {/* {step === STEP_MAP.details && <FileUpload onFileUpload={handleFileUpload} text="Upload your logo file" />} */}
          {step === STEP_MAP.details && (
            <LazyImage src={images.png.plane} placeholderSrc={images.jpg.planeMin} width="100%" height="100%" />
          )}
        </div>
        <main className={clsx(styles.area, styles.main)}>
          <Button
            onClick={onOnboardingEnd}
            className={styles.closeBtn}
            loading={isSubmitting}
            icon={<Icon icon={icons.cross} />}
            buttonType="transparent"
          />
          <Steps activeStep={activeStep} stepCount={3} />
          {switchSteps()}
        </main>
      </div>
    </div>
  );
};

export default observer(Onboarding);
