import { useState, useEffect, useMemo, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { CompanyModalName } from 'companies/types';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import options from 'domain/options';
import { Option } from 'domain/types';
import { MembershipRole } from 'domain/types';
import MemberRoleField from 'members/memberRoleField';
import Autocomplete from 'theme/autocomplete';
import Button from 'theme/button';
import Modal from 'theme/modal';
import { useMemberRole } from 'utils/hooks';

function AddUserToCompanyModal(): JSX.Element {
  const { companyId } = useParams();

  const { companiesStore, toastsStore, usersStore } = useStores();
  const { addUserToCompany, closeModal, isSubmitting, modalName, selectedCompany } = companiesStore;
  const { addToast, toastMessages } = toastsStore;
  const { getUserAutocompleteHints, isLoadingUserAutocompleteHints } = usersStore;

  const [user, setUser] = useState<Option>(DEFAULT_SELECT_OPTION);
  const { memberRoleFieldProps } = useMemberRole(options.role);
  const { handleRoleChange, isManagingCharters, isManagingFinancial, monthlyCharterLimit, role } = memberRoleFieldProps;

  const onModalClose = (): void => {
    closeModal();
    setUser(DEFAULT_SELECT_OPTION);
    handleRoleChange(options.role[0]);
  };

  const submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    addUserToCompany(
      {
        organizationId: selectedCompany?.id || (companyId ?? ''),
        role: role.value as MembershipRole,
        userId: user.value,
        isManagingFinancial,
        isManagingCharters,
        monthlyCharterLimit: monthlyCharterLimit || null,
      },
      true
    )
      .then(() => {
        addToast(toastMessages.COMPANY.ADDING_MEMBER_SUCCESS, 'success');
        onModalClose();
      })
      .catch((err) => {
        if (err.response.status === 409) addToast(toastMessages.COMPANY.ADDING_MEMBER_ERROR_DUPLICATE);
        else addToast(toastMessages.COMPANY.ADDING_MEMBER_ERROR);
      });
  };

  useEffect(() => {
    return () => {
      setUser(DEFAULT_SELECT_OPTION);
    };
  }, []);

  const isSaveBtnDisabled = useMemo(
    () => !user.value || (role.value === MembershipRole.Manager && !isManagingCharters && !isManagingFinancial),
    [user, role, isManagingCharters, isManagingFinancial]
  );

  return (
    <Modal
      onClose={onModalClose}
      isOpen={modalName === CompanyModalName.AddUserToCompany}
      title={selectedCompany ? `Add user to ${selectedCompany.name}` : 'Add user'}
    >
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <table className="vertical-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td data-th="Name">
                <Autocomplete
                  className="table-style-form-field"
                  loading={isLoadingUserAutocompleteHints}
                  placeholder="Search users..."
                  onChange={getUserAutocompleteHints()}
                  onSelect={setUser}
                  value={user}
                />
              </td>
            </tr>
            <MemberRoleField {...memberRoleFieldProps} options={options.role} />
          </tbody>
        </table>
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSaveBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(AddUserToCompanyModal);
