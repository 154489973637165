import api from 'api';
import { OrganizationKind } from 'domain/types';
import { searchObjectToArray } from 'utils/helpers';
import {
  CompanyReturn,
  CompanySearchValues,
  CreateMembershipDto,
  DeleteCompanyReturn,
  FetchCompaniesReturn,
  MemberReturn,
  UpdateCompanyDto,
} from './types';

interface FetchCompaniesParams {
  id?: string;
  kind?: OrganizationKind;
  pageNumber: number;
  pageSize: number;
  searchValues?: CompanySearchValues;
  sortBy?: string;
}

export const fetchCompanies = ({
  id,
  kind,
  pageNumber,
  pageSize,
  searchValues,
  sortBy,
}: FetchCompaniesParams): FetchCompaniesReturn => {
  return api.get('/organizations', {
    params: {
      ...(id && { id }),
      pageSize,
      pageNumber,
      ...(kind && { kind }),
      ...(sortBy && { sort: sortBy }),
      ...(searchValues && { ...searchObjectToArray<CompanySearchValues>(searchValues) }),
    },
  });
};

export const createCompany = (name: string): CompanyReturn => {
  return api.post('/organizations/corporate', { name });
};

export const updateCompany = (data: UpdateCompanyDto, companyId: string): CompanyReturn => {
  return api.post(`/organizations/corporate/${companyId}`, data);
};

export const createMembership = (data: CreateMembershipDto): MemberReturn => {
  return api.post('/memberships', data);
};

export const deleteCompany = (organizationId: string): DeleteCompanyReturn => {
  return api.delete(`/organizations/corporate/${organizationId}`);
};
