import { AdminRole } from 'auth/types';
import Tag, { TagColor } from 'theme/tag';

type TeamMemberRoleTagProps = {
  role: AdminRole;
};

const getTeamMemberRoleTagColor = (role: AdminRole): TagColor => {
  switch (role) {
    case AdminRole.SuperAdmin:
      return TagColor.Green;
    case AdminRole.WriteAdmin:
      return TagColor.Orange;
    case AdminRole.ViewOnlyAdmin:
      return TagColor.Blue;
    default:
      return TagColor.Default;
  }
};

const getTeamMemberRoleTagLabel = (role: AdminRole): string => {
  switch (role) {
    case AdminRole.SuperAdmin:
      return 'Super Admin';
    case AdminRole.WriteAdmin:
      return 'Admin';
    case AdminRole.ViewOnlyAdmin:
      return 'View-only';
    default:
      return 'default';
  }
};

const TeamMemberRoleTag = ({ role }: TeamMemberRoleTagProps): JSX.Element => {
  return <Tag text={getTeamMemberRoleTagLabel(role)} color={getTeamMemberRoleTagColor(role)} />;
};

export default TeamMemberRoleTag;
