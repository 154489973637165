import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as BlockedWhiteIcon } from 'theme/images/svg/blocked-white.svg';
import { ReactComponent as BlockedIcon } from 'theme/images/svg/blocked.svg';
import { ReactComponent as CalendarIcon } from 'theme/images/svg/calendar.svg';
import { ReactComponent as ChairIcon } from 'theme/images/svg/chair.svg';
import { ReactComponent as ChevronLeftDoubleIcon } from 'theme/images/svg/chevron-left-double.svg';
import { ReactComponent as ChevronLeftIcon } from 'theme/images/svg/chevron-left.svg';
import { ReactComponent as ChevronRightDoubleIcon } from 'theme/images/svg/chevron-right-double.svg';
import { ReactComponent as ChevronRightIcon } from 'theme/images/svg/chevron-right.svg';
import { ReactComponent as CloudIcon } from 'theme/images/svg/cloud.svg';
import { ReactComponent as CorporateIcon } from 'theme/images/svg/corp.svg';
import { ReactComponent as CrossIcon } from 'theme/images/svg/cross.svg';
import { ReactComponent as DeleteIcon } from 'theme/images/svg/delete.svg';
import { ReactComponent as DetailsIcon } from 'theme/images/svg/details.svg';
import { ReactComponent as DropdownIcon } from 'theme/images/svg/dropdown.svg';
import { ReactComponent as EditIcon } from 'theme/images/svg/edit.svg';
import { ReactComponent as WarningIcon } from 'theme/images/svg/exclamation-mark.svg';
import { ReactComponent as FlightVerticalImage } from 'theme/images/svg/flight-vertical.svg';
import { ReactComponent as FlightIcon } from 'theme/images/svg/flight.svg';
import { ReactComponent as IntercomIcon } from 'theme/images/svg/intercom.svg';
import { ReactComponent as MinusCircleIcon } from 'theme/images/svg/minus-circle.svg';
import { ReactComponent as RemoveUserIcon } from 'theme/images/svg/minus.svg';
import { ReactComponent as MoreIcon } from 'theme/images/svg/more.svg';
import { ReactComponent as PaymentIcon } from 'theme/images/svg/payment.svg';
import { ReactComponent as AddUserIcon } from 'theme/images/svg/plus.svg';
import { ReactComponent as RouteImageWhite } from 'theme/images/svg/route-white.svg';
import { ReactComponent as RouteImage } from 'theme/images/svg/route.svg';
import { ReactComponent as SadIcon } from 'theme/images/svg/sad-white.svg';
import { ReactComponent as SearchIcon } from 'theme/images/svg/search.svg';
import { ReactComponent as SettingsIcon } from 'theme/images/svg/settings.svg';
import { ReactComponent as StaffIcon } from 'theme/images/svg/staff.svg';
import { ReactComponent as SwapIcon } from 'theme/images/svg/swap.svg';
import { ReactComponent as TickIcon } from 'theme/images/svg/tick.svg';
import { ReactComponent as UserIcon } from 'theme/images/svg/user.svg';

export const icons = {
  add: AddUserIcon,
  block: BlockedIcon,
  blockedWhite: BlockedWhiteIcon,
  calendar: CalendarIcon,
  chair: ChairIcon,
  chevronLeft: ChevronLeftIcon,
  chevronLeftDouble: ChevronLeftDoubleIcon,
  chevronRight: ChevronRightIcon,
  chevronRightDouble: ChevronRightDoubleIcon,
  cloud: CloudIcon,
  corporate: CorporateIcon,
  cross: CrossIcon,
  delete: DeleteIcon,
  details: DetailsIcon,
  dropdown: DropdownIcon,
  edit: EditIcon,
  exclamationMark: WarningIcon,
  flight: FlightIcon,
  flightVertical: FlightVerticalImage,
  intercom: IntercomIcon,
  minusCircle: MinusCircleIcon,
  more: MoreIcon,
  payment: PaymentIcon,
  remove: RemoveUserIcon,
  route: RouteImage,
  routeWhite: RouteImageWhite,
  sad: SadIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  staff: StaffIcon,
  swap: SwapIcon,
  tick: TickIcon,
  user: UserIcon,
};

interface IconProps {
  className?: string;
  height?: number;
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | null;
  onClick?: () => void;
  width?: number;
}

const Icon = ({ icon, height = 21, className, onClick, width = 21 }: IconProps): JSX.Element => {
  if (!icon) return <></>;

  const IconComponent = icon;
  return <IconComponent height={height} className={className} width={width} onClick={() => onClick?.()} />;
};

export default Icon;
