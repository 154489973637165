import clsx from 'clsx';
import styles from './Steps.module.scss';

interface StepsProps {
  activeStep: number;
  stepCount: number;
}

const Steps = ({ activeStep, stepCount }: StepsProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <span className={styles.stepCounter}>
        step {activeStep} of {stepCount}
      </span>
      <div className={styles.dots}>
        {Array.from({ length: stepCount }).map((_, index) => (
          <div key={index} className={clsx(styles.dot, { [styles.dotActive]: index + 1 === activeStep })} />
        ))}
      </div>
    </div>
  );
};

export default Steps;
