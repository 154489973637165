import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import options from 'domain/options';
import { AdminPermission, AdminPermissionOption } from 'domain/types';
import Checkbox from 'theme/checkbox';
import Icon, { icons } from 'theme/icon';
import RadioOptionTable from 'theme/radioOptionTable';
import styles from './RoleAndPermissions.module.scss';

const HIDDEN_OPTIONS: AdminPermissionOption[] = [
  {
    name: 'IndividualMemberships',
    value: 'No access',
  },
  {
    name: 'EntourageTeam',
    value: 'Edit',
  },
];

const DEFAULT_ROW_VALUE: AdminPermissionOption = {
  name: 'IndividualMemberships',
  value: 'viewPermissions',
};

type RoleAndPermissionsProps = {
  isPermissionsEditionVisible: boolean;
  isSuperAdmin: boolean;
  setIsPermissionsEditionVisible: Dispatch<SetStateAction<boolean>>;
  setIsSuperAdmin: Dispatch<SetStateAction<boolean>>;
  setViewPermissions: Dispatch<SetStateAction<AdminPermission[]>>;
  setWritePermissions: Dispatch<SetStateAction<AdminPermission[]>>;
  viewPermissions: AdminPermission[];
  writePermissions: AdminPermission[];
};

const RoleAndPermissions = ({
  isPermissionsEditionVisible,
  isSuperAdmin,
  setIsPermissionsEditionVisible,
  setIsSuperAdmin,
  setViewPermissions,
  setWritePermissions,
  viewPermissions,
  writePermissions,
}: RoleAndPermissionsProps): JSX.Element => {
  const handlePermissionsChange = (key: AdminPermission, value: string): void => {
    const updateWritePermissions = (prev: AdminPermission[]): AdminPermission[] =>
      value === 'writePermissions' ? [...prev, key] : prev.filter((item) => item !== key);
    const updateViewPermissions = (prev: AdminPermission[]): AdminPermission[] =>
      value === 'viewPermissions' ? [...prev, key] : prev.filter((item) => item !== key);

    setWritePermissions((prevWritePermissions) => updateWritePermissions(prevWritePermissions));
    setViewPermissions((prevViewPermissions) => updateViewPermissions(prevViewPermissions));
  };

  return (
    <tr>
      <th className="aligned-top">Super Admin?</th>
      <td data-th="Super Admin?">
        <Checkbox checked={isSuperAdmin} onClick={() => setIsSuperAdmin(!isSuperAdmin)} />
        <button
          className={clsx(styles.togglePermissionsBtn, {
            [styles.togglePermissionsBtnDisabled]: isSuperAdmin,
          })}
          onClick={() => setIsPermissionsEditionVisible(!isPermissionsEditionVisible)}
          type="button"
          disabled={isSuperAdmin}
        >
          Edit permissions
          <Icon
            icon={icons.chevronLeft}
            className={clsx(styles.togglePermissionsBtnIcon, {
              [styles.togglePermissionsBtnIconOpen]: isPermissionsEditionVisible,
            })}
          />
        </button>
        {isPermissionsEditionVisible && (
          <RadioOptionTable
            keyName="Module"
            keys={options.entourageTeamMemberPermissionKey}
            values={{ writePermissions, viewPermissions }}
            selectionOptions={options.entourageTeamMemberPermission}
            onOptionChange={handlePermissionsChange}
            hiddenOptions={HIDDEN_OPTIONS}
            defaultRowValue={DEFAULT_ROW_VALUE}
          />
        )}
      </td>
    </tr>
  );
};

export default RoleAndPermissions;
