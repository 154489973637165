import { FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import { useCompanyDetailsForm, useFormValidation } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  companyNameRequired: '',
};

function EditCorporateOrganizationModal(): JSX.Element {
  const { corporateAdminStore, toastsStore } = useStores();
  const { closeModal, isSubmitting, modalName, selectedCorporateAdminOrganization, updateCorporateAdminOrganization } =
    corporateAdminStore;
  const { addToast, toastMessages } = toastsStore;

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const { companyDto, companyName, formTable } = useCompanyDetailsForm({
    cleanValidationErrors,
    selected: selectedCorporateAdminOrganization,
    validation,
  });

  const onModalClose = (): void => {
    closeModal();
    resetValidation();
  };

  const validateOnSubmit = (): void => {
    handleValidation('companyNameRequired', formValidationMessages.COMPANY_NAME_REQUIRED, !companyName);
  };

  const editCompany = (): void => {
    validateOnSubmit();
    if (!selectedCorporateAdminOrganization || !companyName) return;

    updateCorporateAdminOrganization(companyDto, selectedCorporateAdminOrganization.id)
      .then(() => {
        addToast(toastMessages.COMPANY.UPDATE_SUCCESS, 'success');
        onModalClose();
      })
      .catch(() => {
        addToast(toastMessages.COMPANY.UPDATE_ERROR);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    editCompany();
  };

  return (
    <Modal onClose={onModalClose} isOpen={!!modalName} title="Edit company">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        {formTable}
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(EditCorporateOrganizationModal);
