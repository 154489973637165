import { ChangeEvent } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { RootStore } from 'Root.store';
import { fetchCompanies } from 'companies/requests';
import { DEFAULT_PAGE_SIZE, DEFAULT_SELECT_OPTION } from 'domain/constants';
import options from 'domain/options';
import { Option } from 'domain/types';
import {
  AdminPermission,
  MembershipRole,
  OrganizationKind,
  SortColumn,
  SortDirection,
  SortingProps,
  TableColumn,
} from 'domain/types';
import { Member } from 'members/types';
import { Filter, FilterType } from 'theme/searchPanel';
import { formatDate, formatToMoney } from 'utils/helpers';
import * as requests from './requests';
import {
  AssetTypeEnum,
  ModelUserParams,
  OnboardingItem,
  OrganizationFeeReturn,
  Question,
  RawUser,
  UserDto,
  User,
  UserColumn,
  UserModalName,
  UserSearchValues,
  UsersRawData,
} from './types';

class UsersStore {
  rootStore: RootStore;

  activeTableColumns: TableColumn<UserColumn>[] = [];
  isLoadingCompanyAutocompleteHints = false;
  isLoadingQuestions = false;
  isLoadingUser = false;
  isLoadingUserAddresses = false;
  isLoadingUserAutocompleteHints = false;
  isLoadingUsers = false;
  isLoadingUserIdentityDocument = false;
  isSubmitting = false;
  isUploadingAsset = false;
  membershipTypeOptions: Option[] = [];
  modalName: UserModalName | null = null;
  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  questions: Question[] = [];
  roleOptions: Option[] = [];
  searchValues: UserSearchValues = {
    user: '',
    organizationId: '',
  };
  selectedUser: User | undefined;
  selectedCompanyAutocompleteOption: Option = DEFAULT_SELECT_OPTION;
  sortColumn: SortColumn = SortColumn.CreatedAt;
  sortDirection: SortDirection = SortDirection.Desc;
  statusOptions: Option[] = [];
  uploadProgress = 0;
  user: User | undefined;
  userIdentityDocument = '';
  users: User[] = [];
  usersCount = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get filters(): Filter[] {
    return [
      {
        isSearchXL: true,
        label: 'Name / email / phone',
        name: 'user_name',
        onChange: (e: ChangeEvent<HTMLInputElement>) => this.onSearchChange(e, 'user'),
        placeholder: 'Type phrase...',
        type: FilterType.Search,
        value: this.searchValues.user,
      },
      {
        label: 'Status',
        name: 'status',
        onSelect: this.setStatusOptions,
        options: options.status,
        selectedOptions: this.statusOptions,
        type: FilterType.Multiselect,
      },
      {
        label: 'Membership type',
        name: 'membership_type',
        onSelect: this.setUserOptions,
        options: options.membership,
        selectedOptions: this.membershipTypeOptions,
        type: FilterType.Multiselect,
      },
      {
        autocompleteSettings: {
          label: 'Corporate name',
          loading: this.isLoadingCompanyAutocompleteHints,
          onChange: this.getCompanyAutocompleteHints,
          onSelect: (option: Option) => this.setSelectedCompanyAutocompleteOption(option),
          placeholder: 'Type name...',
          value: this.selectedCompanyAutocompleteOption,
        },
        label: 'Corporate name',
        name: 'corporate_name',
        onChange: (e: ChangeEvent<HTMLInputElement>) => this.onSearchChange(e, 'organizationId'),
        placeholder: 'Type name...',
        type: FilterType.Autocomplete,
        value: this.searchValues.organizationId,
      },
      {
        label: 'Corporate role',
        name: 'corporate_role',
        onSelect: this.setRoleOptions,
        options: options.role,
        selectedOptions: this.roleOptions,
        type: FilterType.Multiselect,
      },
    ];
  }

  private setRoleOptions = (option: Option): void => {
    this.roleOptions = this.rootStore.updateOptions(this.roleOptions, option, true);
  };

  private setUserOptions = (option: Option): void => {
    this.membershipTypeOptions = this.rootStore.updateOptions(this.membershipTypeOptions, option);
  };

  private setStatusOptions = (option: Option): void => {
    this.statusOptions = this.rootStore.updateOptions(this.statusOptions, option);
  };

  private setSortColumn = (sortColumn: SortColumn): void => {
    this.sortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection): void => {
    this.sortDirection = sortDirection;
  };

  private onSearchChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
    this.setSearchValues(key, event.target.value);
  };

  private modelUser = ({ user, depositData, addresses, intercomConfigUrl }: ModelUserParams): User => {
    const { membershipViews } = user;

    const membershipView = membershipViews.find(
      ({ organization, role }) => role === MembershipRole.Owner && organization.kind == OrganizationKind.Individual
    );
    const membershipViewOrganization = membershipView?.organization;
    const membershipViewUser = membershipView?.user;

    const activeUntil = membershipViewOrganization?.activeUntil ?? '';
    const intercomId = membershipViewUser?.intercomId ?? '';
    const managedCorpOrganization = membershipViews.find(
      ({ organization, role }) => role === MembershipRole.Manager && organization.kind == OrganizationKind.Corporate
    );

    const isManagingFinancial = managedCorpOrganization?.isManagingFinancial;
    const isManagingCharters = managedCorpOrganization?.isManagingCharters;

    const intercomUrl = intercomConfigUrl && intercomId ? intercomConfigUrl + intercomId : '';

    return {
      ...user,
      activeUntil: activeUntil ? formatDate(activeUntil) : '',
      addresses: addresses ?? [],
      birthDate: user.birthDate ? formatDate(user.birthDate) : '',
      corporateOrganizations: user.membershipViews.filter(
        ({ organization }: Member) => organization.kind === OrganizationKind.Corporate
      ),
      createdAt: formatDate(user.createdAt),
      flightDeposit: depositData ? formatToMoney(depositData.currentDepositInCents) : '',
      ...(isManagingFinancial !== undefined && { isManagingFinancial }),
      ...(isManagingCharters !== undefined && { isManagingCharters }),
      identityConfirmed: this.isUserIdentityConfirmed(user),
      intercomUrl,
      name: user.firstName + ' ' + user.lastName,
    };
  };

  private get canAccessIndividualInvoices(): boolean {
    return this.rootStore.authStore.hasPermissionToView(AdminPermission.IndividualInvoices);
  }

  get sortingProps(): SortingProps {
    return { sortColumn: this.sortColumn, sortDirection: this.sortDirection, updateSort: this.updateSort };
  }

  fetchUser = async (userId: string): Promise<UsersRawData> => {
    runInAction(() => (this.isLoadingUser = true));

    try {
      const { data } = await requests.fetchUser(userId);
      const responseUser = data.results[0];
      runInAction(() => (this.user = this.modelUser({ user: responseUser })));

      const firstOwnerMembership = responseUser.membershipViews.find((m) => m.role === MembershipRole.Owner);
      if (firstOwnerMembership) {
        if (this.canAccessIndividualInvoices) {
          const [depositData, addressData] = await Promise.all([
            requests.fetchOrganizationFee(firstOwnerMembership.organization.id),
            requests.fetchUserAddresses(userId),
          ]);
          runInAction(
            () =>
              (this.user = this.modelUser({
                user: responseUser,
                depositData: depositData.data,
                addresses: addressData.data,
              }))
          );
        } else {
          const addressData = await requests.fetchUserAddresses(userId);
          runInAction(() => (this.user = this.modelUser({ user: responseUser, addresses: addressData.data })));
        }
      }

      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw error;
    } finally {
      runInAction(() => (this.isLoadingUser = false));
    }
  };

  fetchUsers = (pageSize: number, pageNumber: number, resetPreviousData?: boolean): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingUsers = true));
    if (resetPreviousData) this.pageNumber = 1;

    const isPremium = this.rootStore.addFlagToFetch(this.membershipTypeOptions, 'premium');
    const isIdentityConfirmed = this.rootStore.addFlagToFetch(this.statusOptions, 'verified');

    return Promise.all([
      requests.fetchUsers({
        corporateRoles: this.roleOptions.map((o) => o.value),
        isIdentityConfirmed,
        isPremium,
        organizationId: this.searchValues.organizationId,
        pageNumber,
        pageSize,
        searchValues: this.searchValues,
        sortBy: this.sortColumn + this.sortDirection,
      }),
      requests.fetchIntercomConfig().catch(() => addToast(toastMessages.USER.INTERCOM_CONFIG_ERROR)),
    ])
      .then(([usersResponse, intercomResponse]) => ({
        userData: usersResponse.data,
        intercomConfigUrl: intercomResponse?.data.usersUrl ?? '',
      }))
      .then(({ userData, intercomConfigUrl }) => {
        runInAction(() => {
          this.users = userData.results.map((user) => this.modelUser({ user, intercomConfigUrl }));
          this.usersCount = userData.count;
        });
      })
      .catch(() => addToast(toastMessages.USER.FETCH_ERROR))
      .finally(() => runInAction(() => (this.isLoadingUsers = false)));
  };

  updateUser = (id: string, user: UserDto, isUserDetailsView = false): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateUser(id, user)
      .then(() => {
        isUserDetailsView ? this.fetchUser(id) : this.fetchUsers(this.pageSize, this.pageNumber);
      })
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  private fetchUserIdentityDocument = (userId: string): void => {
    runInAction(() => (this.isLoadingUserIdentityDocument = true));

    requests
      .fetchUserIdentityDocument(userId)
      .then(({ data }) => {
        runInAction(() => (this.userIdentityDocument = data.presignedUrl));
      })
      .catch((error) => {
        if (error.response?.status === 404 && error.response?.data === 'asset')
          runInAction(() => (this.userIdentityDocument = ''));
      })
      .finally(() => runInAction(() => (this.isLoadingUserIdentityDocument = false)));
  };

  deleteUserIdentityDocument = (userId: string): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .deleteUserIdentityDocument(userId)
      .then(() => {
        runInAction(() => {
          this.userIdentityDocument = '';
          this.closeModal();
        });
      })
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  updateSort = (column: SortColumn): void => {
    const dir = this.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.setSortColumn(column);
    this.setSortDirection(dir);
    this.fetchUsers(this.pageSize, 1, true);
  };

  openModal = (modalName: UserModalName, user?: User): void => {
    if (modalName === UserModalName.UserForm && user) this.selectedUser = user;
    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.selectedUser = undefined;
    this.modalName = null;
  };

  fetchOrganizationFee = (userOrganizationId: string): OrganizationFeeReturn => {
    return requests.fetchOrganizationFee(userOrganizationId);
  };

  private isUserIdentityConfirmed = ({ membershipViews }: RawUser): boolean => {
    const member =
      membershipViews.find(
        ({ organization, role }) => role === MembershipRole.Owner && organization.kind == OrganizationKind.Individual
      ) || membershipViews[0];
    if (!member) return false;

    return member.user.identityConfirmed;
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) {
      this.pageNumber = newPageNumber;
      this.fetchUsers(this.pageSize, newPageNumber);
    }
  };

  changePageSize = (newPageSize: number): void => {
    if (newPageSize !== this.pageSize) {
      this.pageSize = newPageSize;
      this.fetchUsers(newPageSize, 1, true);
    }
  };

  private setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
  };

  private resetPagination = (): void => {
    this.pageNumber = 1;
    this.pageSize = DEFAULT_PAGE_SIZE;
  };

  private resetSortBy = (): void => {
    this.sortColumn = SortColumn.CreatedAt;
    this.sortDirection = SortDirection.Desc;
  };

  updateFilters = (): void => {
    this.fetchUsers(this.pageSize, 1, true);
  };

  private resetFilters = (): void => {
    this.roleOptions = [];
    this.membershipTypeOptions = [];
    this.statusOptions = [];
    this.searchValues = { user: '', organizationId: '' };
    this.selectedCompanyAutocompleteOption = DEFAULT_SELECT_OPTION;
  };

  clearFilters = (): Promise<void> => {
    runInAction(() => (this.isLoadingUsers = true));
    this.pageNumber = 1;

    this.resetFilters();
    return this.fetchUsers(this.pageSize, 1, true);
  };

  resetFiltersSortAndPagination = (): void => {
    this.resetPagination();
    this.resetSortBy();
    this.resetFilters();
  };

  confirmUserIdentity = (userId: string): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .confirmUserIdentity(userId)
      .then(() => {
        this.closeModal();
        this.fetchUser(userId);
        this.fetchUserIdentityDocument(userId);
      })
      .finally(() => {
        runInAction(() => (this.isSubmitting = false));
      });
  };

  getCompanyAutocompleteHints = (query: string): Promise<Option[] | void> => {
    runInAction(() => (this.isLoadingCompanyAutocompleteHints = true));

    return fetchCompanies({
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      kind: OrganizationKind.Corporate,
      sortBy: 'NameAsc',
      searchValues: { organization: query },
    })
      .then(({ data }) => {
        return data.results.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      })
      .finally(() => runInAction(() => (this.isLoadingCompanyAutocompleteHints = false)));
  };

  private setSelectedCompanyAutocompleteOption = (option: Option): void => {
    this.setSearchValues('organizationId', option.value);
    this.selectedCompanyAutocompleteOption = option;
  };

  private setUploadProgress = (value: number): void => {
    this.uploadProgress = value;
  };

  uploadFile = (userId: string, file: File): Promise<void> => {
    runInAction(() => (this.isUploadingAsset = true));

    return requests
      .uploadAsset({
        userId,
        assetKey: AssetTypeEnum.IdentityDocument,
        file,
        updateProgress: this.setUploadProgress,
      })
      .then(() => this.fetchUserIdentityDocument(userId))
      .finally(() => {
        runInAction(() => (this.isUploadingAsset = false));
        this.setUploadProgress(0);
      });
  };

  fetchAllUserData = async (userId: string | undefined, errFn: (error: AxiosError) => void): Promise<void> => {
    if (!userId) return;

    try {
      await Promise.all([this.fetchUser(userId), this.fetchQuestions(userId), this.fetchUserIdentityDocument(userId)]);
    } catch (error) {
      errFn(error as AxiosError);
    }
  };

  private filterAdmins = (users: RawUser[]): RawUser[] => {
    return users.filter((u) => !u.viewPermissions.length && !u.writePermissions.length);
  };

  getUserAutocompleteHints =
    (areAdminsFiltered = false): ((query: string) => Promise<Option[] | void>) =>
    (query: string): Promise<Option[] | void> => {
      runInAction(() => (this.isLoadingUserAutocompleteHints = true));

      return requests
        .fetchUsers({
          pageSize: DEFAULT_PAGE_SIZE,
          pageNumber: 1,
          sortBy: 'NameAsc',
          searchValues: { user: query, organizationId: '' },
        })
        .then(({ data }) => {
          const filteredResults = areAdminsFiltered ? this.filterAdmins(data.results) : data.results;
          return filteredResults
            .map((result) => this.modelUser({ user: result }))
            .map(({ id, name }) => ({ value: id, label: name }));
        })
        .finally(() => runInAction(() => (this.isLoadingUserAutocompleteHints = false)));
    };

  fetchQuestions = (userId: string): Promise<void | Question[]> => {
    runInAction(() => (this.isLoadingQuestions = true));

    return requests
      .fetchQuestions(userId)
      .then(({ data }) => {
        runInAction(() => {
          this.questions = data.map(({ question, answers, predefinedAnswers }: OnboardingItem) => ({
            ...question,
            answers,
            predefinedAnswers,
          }));
        });

        return this.questions;
      })
      .finally(() => runInAction(() => (this.isLoadingQuestions = false)));
  };
}

export default UsersStore;
