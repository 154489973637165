import { useEffect, useMemo, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import options from 'domain/options';
import { MembershipRole, MembershipRoleOption } from 'domain/types';
import { MemberModalName } from 'members/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import { useMemberRole } from 'utils/hooks';
import MemberRoleField from '../memberRoleField';

function EditMemberModal(): JSX.Element {
  const { companyId, memberId } = useParams();

  const { corporateAdminStore, membersStore, toastsStore } = useStores();
  const { corporateAdminRole } = corporateAdminStore;
  const { closeModal, editMember, isSubmitting, modalName, selectedMember } = membersStore;
  const { addToast, toastMessages } = toastsStore;

  const removeManagerOptionIfUserIsManager = (): MembershipRoleOption[] => {
    return corporateAdminRole === MembershipRole.Manager
      ? options.role.filter(({ value }) => value !== MembershipRole.Manager)
      : options.role;
  };

  const availableRoleOptions: MembershipRoleOption[] = useMemo(() => {
    return removeManagerOptionIfUserIsManager().filter(
      ({ value }) => value !== MembershipRole.Owner && value !== selectedMember?.role
    );
  }, [selectedMember]);

  const { memberRoleFieldProps, setIsManagingCharters, setIsManagingFinancial, setMonthlyCharterLimit } =
    useMemberRole(availableRoleOptions);
  const { handleRoleChange, isManagingCharters, isManagingFinancial, monthlyCharterLimit, role } = memberRoleFieldProps;

  const updateMembership = (): void | undefined => {
    if (!selectedMember) return;

    editMember(
      { role: role.value, isManagingFinancial, isManagingCharters, monthlyCharterLimit: monthlyCharterLimit || null },
      selectedMember.id,
      !!memberId,
      companyId
    )
      .then(() => {
        closeModal();
        addToast(toastMessages.MEMBER.UPDATE_SUCCESS, 'success');
      })
      .catch(() => addToast(toastMessages.MEMBER.UPDATE_ERROR));
  };

  const onModalClose = (): void => {
    closeModal();
    handleRoleChange(options.role[0]);
  };

  const submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateMembership();
  };

  useEffect(() => {
    if (selectedMember)
      handleRoleChange(options.role.find(({ value }) => value === selectedMember.role) || options.role[0]);
  }, [selectedMember]);

  useEffect(() => {
    if (selectedMember?.role === MembershipRole.Manager) {
      setIsManagingFinancial(selectedMember.isManagingFinancial);
      setIsManagingCharters(selectedMember.isManagingCharters);
    }
    if (selectedMember?.role === MembershipRole.Employee)
      setMonthlyCharterLimit(selectedMember.monthlyCharterLimit ?? 0);
  }, [selectedMember]);

  const isSubmitBtnDisabled =
    isSubmitting || (role.value === MembershipRole.Manager && !isManagingCharters && !isManagingFinancial);

  return (
    <Modal onClose={onModalClose} isOpen={modalName === MemberModalName.EditMember} title="Edit member">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <table className="vertical-table">
          <tbody>
            <MemberRoleField {...memberRoleFieldProps} options={options.role} />
          </tbody>
        </table>
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} type="submit" disabled={isSubmitBtnDisabled} />
        </div>
      </form>
    </Modal>
  );
}

export default observer(EditMemberModal);
