import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { MembershipRole, MembershipRoleOption } from 'domain/types';

interface UseMemberRoleReturn {
  memberRoleFieldProps: {
    handleIsManagingChartersChange: () => void;
    handleIsManagingFinancialChange: () => void;
    handleMonthlyCharterLimitChange: (value: string) => void;
    handleRoleChange: (option: MembershipRoleOption) => void;
    isManagingCharters: boolean;
    isManagingFinancial: boolean;
    monthlyCharterLimit: number;
    role: MembershipRoleOption;
  };
  setIsManagingCharters: Dispatch<SetStateAction<boolean>>;
  setIsManagingFinancial: Dispatch<SetStateAction<boolean>>;
  setMonthlyCharterLimit: Dispatch<SetStateAction<number | null>>;
}

export function useMemberRole(options: MembershipRoleOption[]): UseMemberRoleReturn {
  const [role, setRole] = useState<MembershipRoleOption>(options[0]);
  const [isManagingFinancial, setIsManagingFinancial] = useState(false);
  const [isManagingCharters, setIsManagingCharters] = useState(false);
  const [monthlyCharterLimit, setMonthlyCharterLimit] = useState<number | null>(0);

  const handleRoleChange = (option: MembershipRoleOption): void => {
    setRole(option);
  };

  const handleIsManagingFinancialChange = (): void => {
    setIsManagingFinancial(!isManagingFinancial);
  };

  const handleIsManagingChartersChange = (): void => {
    setIsManagingCharters(!isManagingCharters);
  };

  const handleMonthlyCharterLimitChange = (value: string): void => {
    setMonthlyCharterLimit(value ? parseInt(value, 10) : 0);
  };

  useEffect(() => {
    if (role.value !== MembershipRole.Manager) {
      setIsManagingFinancial(false);
      setIsManagingCharters(false);
    }
    if (role.value !== MembershipRole.Employee) setMonthlyCharterLimit(0);
  }, [role.value]);

  return {
    memberRoleFieldProps: {
      handleIsManagingChartersChange,
      handleIsManagingFinancialChange,
      handleMonthlyCharterLimitChange,
      handleRoleChange,
      isManagingCharters,
      isManagingFinancial,
      monthlyCharterLimit: monthlyCharterLimit ?? 0,
      role,
    },
    setIsManagingCharters,
    setIsManagingFinancial,
    setMonthlyCharterLimit,
  };
}
