import React from 'react';
import clsx from 'clsx';
import styles from './Tooltip.module.scss';

interface TooltipProps {
  className?: string;
  left?: boolean;
  text: string;
}

const Tooltip = ({ className = '', text, left = false }: TooltipProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, { [styles.left]: left })}>
      <div className={clsx(styles.arrow, { [styles.arrowLeft]: left })} />
      {text}
    </div>
  );
};

export default Tooltip;
