import { ChangeEvent } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { requestNewPassword } from 'auth/requests';
import { Company } from 'companies/types';
import { DEFAULT_PAGE_SIZE } from 'domain/constants';
import options from 'domain/options';
import { Option } from 'domain/types';
import { MembershipRole, OrganizationKind, SortColumn, SortDirection, SortingProps } from 'domain/types';
import { Filter, FilterType } from 'theme/searchPanel';
import { createUser } from 'users/requests';
import { RawUser, UsersRawData } from 'users/types';
import * as requests from './requests';
import {
  CreateMembershipDto,
  FetchMembersParams,
  Member,
  MemberInvitation,
  MemberModalName,
  MemberReturn,
  MemberSearchValues,
  RawMember,
  UpdateMembershipDto,
} from './types';

const RESET_PASSWORD_URL = `${location.origin}${paths.createPassword}`;

class MembersStore {
  rootStore: RootStore;

  isLoadingMember = false;
  isLoadingMembers = false;
  isSubmitting = false;
  member: Member | undefined;
  memberInvitationFetchError = false;
  members: Member[] = [];
  membersCount = 0;
  modalName: MemberModalName | null = null;
  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  resetPreviousData = true;
  roleOptions: Option[] = [];
  searchValues: MemberSearchValues = { userQuery: '' };
  selectedMember: Member | null = null;
  sortColumn: SortColumn = SortColumn.CreatedAt;
  sortDirection: SortDirection = SortDirection.Desc;
  statusOptions: Option[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private onSearchChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
    this.setSearchValues(key, event.target.value);
  };

  get filters(): Filter[] {
    return [
      {
        label: 'Role',
        name: 'role',
        onSelect: this.setRoleOptions,
        options: options.role,
        selectedOptions: this.roleOptions,
        type: FilterType.Multiselect,
      },
      {
        isSearchXL: true,
        label: 'Name / email / phone',
        name: 'userQuery',
        onChange: (e: ChangeEvent<HTMLInputElement>) => this.onSearchChange(e, 'userQuery'),
        placeholder: 'Type phrase...',
        type: FilterType.Search,
        value: this.searchValues.userQuery,
      },
      {
        label: 'Status',
        name: 'status',
        onSelect: this.setStatusOptions,
        options: options.status,
        selectedOptions: this.statusOptions,
        type: FilterType.Multiselect,
      },
    ];
  }

  private get EADCompanyId(): string {
    return this.rootStore.companiesStore.company?.id ?? '';
  }

  get sortingProps(): SortingProps {
    return { sortColumn: this.sortColumn, sortDirection: this.sortDirection, updateSort: this.updateSort };
  }

  private setRoleOptions = (option: Option): void => {
    this.roleOptions = this.rootStore.updateOptions(this.roleOptions, option, true);
  };

  private setStatusOptions = (option: Option): void => {
    this.statusOptions = this.rootStore.updateOptions(this.statusOptions, option);
  };

  openModal = (modalName: MemberModalName, item?: Company | Member): void => {
    if (modalName === MemberModalName.InviteMember) this.rootStore.companiesStore.setSelectedCompany(item as Company);
    else this.selectedMember = item as Member;

    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.modalName = null;
    this.selectedMember = null;
    this.rootStore.companiesStore.setSelectedCompany(undefined);
  };

  inviteMember = (data: CreateMembershipDto): MemberReturn => {
    this.isSubmitting = true;
    return requests.inviteMember(data).finally(() => (this.isSubmitting = false));
  };

  editMember = (
    data: UpdateMembershipDto,
    membershipId: string,
    isDetailsView = false,
    organizationId?: string
  ): Promise<void> => {
    this.isSubmitting = true;

    return requests
      .editMember(data, membershipId)
      .then(() => {
        isDetailsView
          ? this.fetchMember(membershipId, organizationId)
          : this.fetchMembers({
              companyIdParam: organizationId,
              pageSize: this.pageSize,
              pageNumber: this.pageNumber,
            });
      })
      .finally(() => (this.isSubmitting = false));
  };

  private modelMember = (member: RawMember): Member => ({
    ...member,
    user: {
      ...member.user,
      name: member.user.firstName + ' ' + member.user.lastName,
    },
  });

  private fetchMemberDetails = (memberId: string, organizationId: string | undefined): Promise<void> => {
    if (!this.rootStore.corporateAdminStore.corporateAdminOrganization) return Promise.reject();
    runInAction(() => (this.isLoadingMember = true));

    return requests
      .fetchMembers({
        organizationId: organizationId || this.rootStore.corporateAdminStore.corporateAdminOrganization.id,
        id: memberId,
        pageSize: 1,
        pageNumber: 1,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.member = this.modelMember(data.results[0]);
          this.membersCount = data.count;
        });
      })
      .finally(() => runInAction(() => (this.isLoadingMember = false)));
  };

  fetchMember = (memberId: string, organizationId?: string): Promise<void> => {
    runInAction(() => (this.isLoadingMember = true));

    return organizationId
      ? this.fetchMemberDetails(memberId, organizationId)
      : this.rootStore.corporateAdminStore
          .fetchCorporateAdminUser()
          .then(() => this.fetchMemberDetails(memberId, organizationId))
          .finally(() => runInAction(() => (this.isLoadingMember = false)));
  };

  fetchMembers = ({
    companyIdParam,
    onInit = false,
    pageNumber,
    pageSize,
    resetPreviousData = false,
  }: FetchMembersParams): Promise<UsersRawData | RawUser | void> => {
    const { corporateAdminOrganization, fetchCorporateAdminUser, setIsLoadingCorporateAdminUser } =
      this.rootStore.corporateAdminStore;
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    if (resetPreviousData) this.pageNumber = 1;
    runInAction(() => (this.isLoadingMembers = true));

    const req = (organizationId: string): Promise<UsersRawData | RawUser | void> => {
      return requests
        .fetchMembers({
          isIdentityConfirmed: this.rootStore.addFlagToFetch(this.statusOptions, 'verified'),
          organizationId: companyIdParam ?? organizationId,
          pageNumber: pageNumber || this.pageNumber,
          pageSize: pageSize || this.pageSize,
          roles: this.roleOptions.map((o) => o.value),
          searchValues: this.searchValues,
          sortBy: this.sortColumn + this.sortDirection,
        })
        .then(({ data }) =>
          runInAction(() => {
            this.members = data.results.map(this.modelMember);
            this.membersCount = data.count;
          })
        )
        .catch(() => {
          addToast(toastMessages.MEMBER.FETCH_LIST_ERROR);
        })
        .finally(() =>
          runInAction(() => {
            this.isLoadingMembers = false;
            setIsLoadingCorporateAdminUser(false);
          })
        );
    };

    if (onInit) {
      return fetchCorporateAdminUser().then((user) => {
        const userRole = user?.membershipViews.find(
          ({ organization }) => organization.kind === OrganizationKind.Corporate
        )?.role;

        const orgId =
          user?.membershipViews.find(
            ({ role, organization }) => role === userRole && organization.kind === OrganizationKind.Corporate
          )?.organization.id ?? '';

        req(orgId);
        return user;
      });
    } else return req(corporateAdminOrganization?.id ?? '');
  };

  deleteMember = ({ id, organization }: Member): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .deleteMember(id)
      .then(() => {
        this.fetchMembers({ pageSize: this.pageSize, pageNumber: this.pageNumber, companyIdParam: organization.id });
        this.closeModal();
      })
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  finishCorporateOwnerOnboarding = (): Promise<void> => {
    const { corporateAdminOrganization } = this.rootStore.corporateAdminStore;
    if (!corporateAdminOrganization) return Promise.reject();
    runInAction(() => (this.isSubmitting = true));

    return requests
      .finishCorporateOwnerOnboarding(corporateAdminOrganization.id)
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  fetchMemberInvitation = (
    accessToken: string,
    invitationId: string,
    userId: string | null
  ): Promise<MemberInvitation | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => {
      this.memberInvitationFetchError = false;
      this.isSubmitting = true;
    });

    return requests
      .fetchMemberInvitation(accessToken, invitationId)
      .then(({ data }) => {
        const { email, firstName, lastName, phone } = data;

        if (!userId) {
          createUser({ email, firstName, lastName, phone }).then(({ data: user }) => {
            requests
              .acceptInvitation({
                invitationId,
                token: accessToken,
                userId: user.id,
              })
              .then(() => {
                requestNewPassword(email, RESET_PASSWORD_URL);
              });
          });
        } else {
          requests
            .acceptInvitation({
              invitationId,
              token: accessToken,
              userId: userId,
            })
            .then(() => {
              requestNewPassword(email, RESET_PASSWORD_URL);
            });
        }

        return data;
      })
      .catch(() => {
        addToast(toastMessages.ERROR.DEFAULT);
        this.memberInvitationFetchError = true;
      })
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  changePageNumber = (newPageNumber: number): void | undefined => {
    if (newPageNumber === this.pageNumber) return;

    this.pageNumber = newPageNumber;
    this.fetchMembers({
      ...(this.EADCompanyId && { companyIdParam: this.EADCompanyId }),
      pageSize: this.pageSize,
      pageNumber: newPageNumber,
    });
  };

  changePageSize = (newPageSize: number): void | undefined => {
    if (newPageSize === this.pageSize) return;

    this.pageSize = newPageSize;
    this.fetchMembers({
      ...(this.EADCompanyId && { companyIdParam: this.EADCompanyId }),
      pageSize: newPageSize,
      pageNumber: 1,
      resetPreviousData: true,
    });
  };

  private resetPagination = (): void => {
    this.pageNumber = 1;
    this.pageSize = DEFAULT_PAGE_SIZE;
  };

  private resetSortBy = (): void => {
    this.sortColumn = SortColumn.CreatedAt;
    this.sortDirection = SortDirection.Desc;
  };

  private setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
  };

  private setSortColumn = (sortColumn: SortColumn): void => {
    this.sortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection): void => {
    this.sortDirection = sortDirection;
  };

  updateSort = (column: SortColumn): void => {
    const dir = this.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    this.setSortColumn(column);
    this.setSortDirection(dir);

    this.fetchMembers({
      ...(this.EADCompanyId && { companyIdParam: this.EADCompanyId }),
      pageSize: this.pageSize,
      pageNumber: 1,
      resetPreviousData: true,
    });
  };

  private resetFilters = (): void => {
    this.roleOptions = [];
    this.statusOptions = [];
    this.searchValues = { userQuery: '' };
  };

  clearFilters = (): Promise<void> => {
    runInAction(() => (this.isLoadingMembers = true));
    this.resetFilters();

    return requests
      .fetchMembers({
        organizationId: this.rootStore.corporateAdminStore.corporateAdminOrganization?.id || this.EADCompanyId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        sortBy: this.sortColumn + this.sortDirection,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.members = data.results.map(this.modelMember);
          this.membersCount = data.count;
        });
      })
      .finally(() => {
        runInAction(() => (this.isLoadingMembers = false));
      });
  };

  resetFiltersSortAndPagination = (): void => {
    this.resetPagination();
    this.resetSortBy();
    this.resetFilters();
  };

  renderManagerTagLabel = ({ isManagingCharters, isManagingFinancial, role }: Member): MembershipRole => {
    if (role === MembershipRole.Manager) {
      if (isManagingCharters && isManagingFinancial) return MembershipRole.Manager;
      if (isManagingCharters) return MembershipRole.BookingManager;
      if (isManagingFinancial) return MembershipRole.FinancialManager;
      else return MembershipRole.Manager;
    } else return role;
  };
}

export default MembersStore;
