import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import AdminFlightRow from 'adminFlights/adminFlightRow';
import Breadcrumbs from 'theme/breadcrumbs';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import SearchPanel from 'theme/searchPanel';
import { useTitle } from 'utils/hooks';
import styles from './AdminFlights.module.scss';
import AdminFlightDateRow from './adminFlightDateRow';
import AdminFlightPagination from './adminFlightPagination';

const AdminFlights = (): JSX.Element => {
  const { adminFlightsStore } = useStores();
  const {
    clearFilters,
    currentDates,
    currentMonthLabel,
    fetchFlights,
    filterBtnDisabled,
    filterFlightsByDay,
    filters,
    isLoadingFlights,
    updateFilters,
  } = adminFlightsStore;

  useTitle('Flights');

  useEffect(() => {
    fetchFlights();
  }, []);

  return (
    <div className={styles.root}>
      <Breadcrumbs current="Flights" />
      <PageHeader title="Flights" />
      <SearchPanel
        loading={isLoadingFlights}
        filters={filters}
        reset={clearFilters}
        search={updateFilters}
        submitDisabled={filterBtnDisabled}
        title="Search flights"
      />
      <LoadingArea loading={isLoadingFlights}>
        <div className={styles.calendar}>
          <header className={styles.calendarHeader}>
            <AdminFlightPagination />
            <h2 className="center">{currentMonthLabel}</h2>
            <div />
          </header>
          <div className={styles.calendarTable}>
            {currentDates.map((d) => (
              <AdminFlightDateRow key={d.date.toISOString()} flightDate={d}>
                {filterFlightsByDay(d.date).map((f) => (
                  <AdminFlightRow key={f.id} flight={f} />
                ))}
              </AdminFlightDateRow>
            ))}
          </div>
          <AdminFlightPagination className={styles.bottomPagination} />
        </div>
      </LoadingArea>
    </div>
  );
};

export default observer(AdminFlights);
