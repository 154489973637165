import React from 'react';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import styles from './Pagination.module.scss';
import PageSizeSelector from './pageSizeSelector';

interface PaginationProps {
  activePage: number;
  activePageItemCount: number;
  allItemCount: number;
  className?: string;
  disabled: boolean;
  goToPage: (page: number, pageSize: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  pageSize: number;
}

const pagesSubstr = [-3, -2, -1, 0, 1];
const firstPagesSubstr = [0, 1, 2, 3, 4];
const lastPagesSubstr = [-3, -2, -1, 0, 1];

const Pagination = ({
  activePage,
  activePageItemCount,
  allItemCount,
  className = '',
  disabled,
  goToPage,
  pageSize,
  onPageSizeChange,
}: PaginationProps): JSX.Element => {
  const pages = Math.ceil(allItemCount / pageSize);
  const backDisabled = disabled || activePage === 1;
  const nextDisabled = disabled || activePage === pages;

  const visiblePages = Array(5)
    .fill(0)
    .map((_, i) => {
      if (activePage + 3 > pages) return activePage + lastPagesSubstr[i];
      if (activePage > 3) return activePage + pagesSubstr[i];
      return firstPagesSubstr[i];
    })
    .filter((p) => p >= 1 && p <= pages);

  const lastPageVisible = visiblePages.some((p) => p === pages - 1);
  const morePages = visiblePages[visiblePages.length - 1] + 3;

  if (pages < 2 && !onPageSizeChange) return <div />;

  return (
    <div className={clsx(styles.root, className)}>
      {onPageSizeChange && (
        <PageSizeSelector
          pageSize={pageSize}
          onChange={(option) => {
            const newPageSize = parseInt(option.value, 10);
            onPageSizeChange(newPageSize);
            goToPage(1, newPageSize);
          }}
        />
      )}
      <p className={styles.info}>
        Showing <strong>{activePageItemCount}</strong> of <strong>{allItemCount}</strong>{' '}
        {allItemCount === 1 ? 'result' : 'results'}
      </p>
      <div className={styles.buttons}>
        <button
          className={clsx(styles.btn, styles.arrowBtnLeft, {
            [styles.btnDisabled]: backDisabled,
          })}
          onClick={() => !backDisabled && goToPage(1, pageSize)}
          disabled={backDisabled}
        >
          <Icon icon={icons.chevronLeftDouble} />
        </button>
        <button
          className={clsx(styles.btn, styles.arrowBtnLeft, {
            [styles.btnDisabled]: backDisabled,
          })}
          onClick={() => !backDisabled && goToPage(activePage - 1, pageSize)}
          disabled={backDisabled}
        >
          <Icon icon={icons.chevronLeft} />
        </button>
        {visiblePages.map((page) => (
          <button
            className={clsx(styles.btn, { [styles.activePage]: activePage === page })}
            disabled={disabled}
            key={page}
            onClick={() => goToPage(page, pageSize)}
          >
            {page}
          </button>
        ))}
        {!lastPageVisible && activePage < pages - 2 && (
          <button
            className={styles.btn}
            disabled={disabled}
            onClick={() => goToPage(morePages < pages ? morePages : pages, pageSize)}
          >
            ...
          </button>
        )}
        <button
          className={clsx(styles.btn, styles.arrowBtnRight, {
            [styles.btnDisabled]: nextDisabled,
          })}
          onClick={() => !nextDisabled && goToPage(activePage + 1, pageSize)}
          disabled={nextDisabled}
        >
          <Icon icon={icons.chevronRight} />
        </button>
        <button
          className={clsx(styles.btn, styles.arrowBtnRight, {
            [styles.btnDisabled]: nextDisabled,
          })}
          onClick={() => !nextDisabled && goToPage(pages, pageSize)}
          disabled={nextDisabled}
        >
          <Icon icon={icons.chevronRightDouble} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
