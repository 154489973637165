import { AxiosResponse } from 'axios';
import { AdminPermission, MembershipRole } from 'domain/types';

export enum AdminRole {
  SuperAdmin = 'SuperAdmin',
  WriteAdmin = 'WriteAdmin',
  ViewOnlyAdmin = 'ViewAdmin',
  CorporateAdmin = 'CorporateAdmin',
}

export interface MembershipRoleObject {
  organizationId: string;
  role: MembershipRole;
}

export interface AuthUser {
  exp: number;
  id: string;
  email: string;
  ownedOrganizationsWithCompletedOnboarding: string[];
  userRoles: AdminRole[];
  viewPermissions: AdminPermission[];
  writePermissions: AdminPermission[];
}

export interface Auth {
  token: string;
  user: AuthUser;
}

export interface RefreshAuth {
  userId: string;
  deviceId: string;
  value: string;
}

export type LoginReturn = Promise<AxiosResponse<{ token: string }>>;
export type RefreshTokenReturn = Promise<AxiosResponse<RefreshAuth>>;
export type ResetPasswordReturn = Promise<AxiosResponse<void>>;
