import { DEFAULT_PAGE_SIZE } from 'domain/constants';
import options from 'domain/options';
import { Option } from 'domain/types';
import Select from 'theme/select';
import styles from './PageSizeSelector.module.scss';

interface PageSizeSelectorProps {
  pageSize: number;
  onChange: (option: Option) => void;
}

function PageSizeSelector({ onChange, pageSize }: PageSizeSelectorProps): JSX.Element {
  const selectedOption = options.pageSizeSelector.find((o) => parseInt(o.value, 10) === pageSize);
  const defaultOptionIndex = options.pageSizeSelector.findIndex((o) => Number(o.value) === DEFAULT_PAGE_SIZE);

  return (
    <div className={styles.main}>
      Rows per page:
      <Select
        value={selectedOption || options.pageSizeSelector[defaultOptionIndex]}
        options={options.pageSizeSelector}
        onChange={onChange}
        className={styles.select}
        up
      />
    </div>
  );
}

export default PageSizeSelector;
