import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { MembershipRole, TableAction, TableActionBtn } from 'domain/types';
import { Member, MemberModalName } from 'members/types';
import TableActions from 'theme/tableActions';
import { useAdminPermissionsForUIManagement } from 'utils/hooks';

interface MemberRowButtonActionsProps {
  areBtnsAlwaysVisible?: boolean;
  isDetailsView?: boolean;
  isEAD: boolean;
  link?: string;
  member: Member;
}

const MemberRowButtonActions = ({
  areBtnsAlwaysVisible = false,
  isDetailsView = false,
  isEAD,
  link,
  member,
}: MemberRowButtonActionsProps): JSX.Element => {
  const isEditionForbidden = useAdminPermissionsForUIManagement();

  const { corporateAdminStore, membersStore } = useStores();
  const { corporateAdminRole } = corporateAdminStore;
  const { openModal } = membersStore;

  const detailsObj: TableActionBtn = {
    link,
    text: 'Details',
    hidden: isDetailsView,
    type: TableAction.Details,
  };

  const editObj: TableActionBtn = {
    action: () => openModal(MemberModalName.EditMember, member),
    text: 'Edit',
    hidden: isEditionForbidden,
    type: TableAction.Edit,
  };

  const removeObj: TableActionBtn = {
    action: () => openModal(MemberModalName.DeleteMember, member),
    text: 'Remove',
    hidden: isEditionForbidden,
    type: TableAction.Remove,
  };

  const all: TableActionBtn[] = [detailsObj, editObj, removeObj];

  const displayBtnsForRole = (): TableActionBtn[] => {
    if (corporateAdminRole === MembershipRole.Owner) {
      if (member.role === MembershipRole.Owner) return [detailsObj];
      return all;
    }
    if (corporateAdminRole === MembershipRole.Manager) {
      if (member.role === MembershipRole.Employee || member.role === MembershipRole.Guest) return all;
      return [detailsObj];
    }
    return [detailsObj];
  };

  const btns = isEAD ? all : displayBtnsForRole();
  const filteredBtns: TableActionBtn[] = btns.filter((btn) => !btn.hidden);

  return <TableActions actions={filteredBtns} alignLeft={!isEAD} areBtnsAlwaysVisible={areBtnsAlwaysVisible} />;
};

export default observer(MemberRowButtonActions);
