import { isBefore, parseISO } from 'date-fns';
import { DATE_FORMAT, formatDate, isPMTimestamp } from 'utils/helpers';
import { FLIGHT_TYPE_MAP } from './constants';
import { Booking, Flight, FlightDetails, FlightType, RawBooking, RawFlight, RawFlightDetails } from './types';

interface CommonFlightModeledData {
  arrivalTime: string;
  departureTime: string;
  fullArrivalTime: string;
  fullDepartureTime: string;
  seatCompletion: number;
  takenSeats: number;
  typeLabel: string;
}

export const addCommonFlightData = <T extends RawFlight | RawFlightDetails>(data: T): CommonFlightModeledData => {
  const { arrivalDate, availableSeats, departureDate, totalSeats } = data;
  const takenSeats = totalSeats - availableSeats;

  return {
    arrivalTime: formatDate(arrivalDate, DATE_FORMAT.HOUR),
    departureTime: formatDate(departureDate, DATE_FORMAT.HOUR),
    fullArrivalTime: formatDate(arrivalDate, DATE_FORMAT.FULL),
    fullDepartureTime: formatDate(departureDate, DATE_FORMAT.FULL),
    seatCompletion: (takenSeats / totalSeats) * 100,
    takenSeats,
    typeLabel: FLIGHT_TYPE_MAP[FlightType.OneTime],
  };
};

export const modelFlight = (f: RawFlight): Flight => {
  const { departureDate, id } = f;

  return {
    ...f,
    ...addCommonFlightData(f),
    charterType: FlightType.OneTime,
    disabled: isBefore(parseISO(departureDate), new Date()),
    dateDay: formatDate(departureDate, DATE_FORMAT.DATE_NUMBER),
    dateDayOfWeek: formatDate(departureDate, DATE_FORMAT.DAY_OF_WEEK),
    fullDepartureDayTime: formatDate(departureDate, DATE_FORMAT.DAY_FULL),
    id: id.replaceAll('/', '-'),
    isConfirmed: false,
    isPM: isPMTimestamp(departureDate),
    passengers: [],
  };
};

export const modelFlightDetails = (details: RawFlightDetails): FlightDetails => {
  const { arrivalDate, arrivalFBO, departureDate } = details;

  return {
    ...details,
    ...addCommonFlightData(details),
    arrivalDateWithDayOfWeek: formatDate(arrivalDate, DATE_FORMAT.DATE_WITH_DAY_OF_WEEK),
    arrivalFBO: arrivalFBO ? formatDate(arrivalFBO, DATE_FORMAT.FULL) : 'not specified',
    departureDateWithDayOfWeek: formatDate(departureDate, DATE_FORMAT.DATE_WITH_DAY_OF_WEEK),
    isConfirmed: false,
  };
};

export const modelBooking = (b: RawBooking): Booking => {
  return {
    ...b,
    arrivalTime: '',
    charterType: FlightType.OneTime,
    departureTime: formatDate(b.departureDate, DATE_FORMAT.HOUR),
    passengers: [], // TEMPORARY SOLUTION
  };
};
