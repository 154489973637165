import { AxiosResponse } from 'axios';
import { AdminRole } from 'auth/types';
import { AdminPermission, Gender } from 'domain/types';

export interface TeamMemberSearchValues extends Record<string, string> {
  user: string;
}

export enum TeamModalName {
  AddPermissions = 'AddPermissions',
  AddTeamMember = 'AddTeamMember',
  EditTeamMember = 'EditTeamMember',
  RemoveTeamMember = 'RemoveTeamMember',
}

export interface RawTeamMember {
  activeUntil: string;
  archivedAt: string | null;
  banned: null | boolean;
  birthDate: string | null;
  country: string | null;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  identityConfirmed: boolean;
  intercomId: string;
  intercomStatus: string;
  isSuperAdmin: boolean;
  lastName: string;
  phone: string;
  roles: string[];
  sequenceId: string;
  sex: Gender;
  viewPermissions: AdminPermission[];
  writePermissions: AdminPermission[];
}

export interface TeamMember extends RawTeamMember {
  name: string;
  role: AdminRole;
  viewPermissionLabels: string[];
  writePermissionLabels: string[];
}

export interface TeamMembersRawData {
  count: number;
  results: RawTeamMember[];
}

export interface TeamMemberDto {
  isSuperAdmin: boolean;
  viewPermissions: AdminPermission[];
  writePermissions: AdminPermission[];
}

export type TeamMemberReturn = Promise<AxiosResponse<RawTeamMember>>;
export type TeamMembersReturn = Promise<AxiosResponse<TeamMembersRawData>>;
