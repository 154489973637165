import { format } from 'date-fns';

export const DATE_FORMAT = {
  DATE_NUMBER: 'd',
  DATE_WITH_DAY_OF_WEEK: 'MMM d, EEE',
  DAY_BE_FORMAT: 'yyyy-MM-dd',
  DAY_FULL_ALT: 'MMM dd, yyyy',
  DAY_FULL: 'MMM d, yyyy',
  DAY_OF_WEEK: 'EEE',
  DAY: 'MM.dd.yyyy',
  FULL: 'MMM d yyyy, HH:mm aa',
  HOUR: 'HH:mm aa',
  MONTH_AND_YEAR: 'MMMM yyyy',
};

export const formatDate = (date: Date | string, dateFormat?: string): string => {
  const fmt = dateFormat || DATE_FORMAT.DAY;

  if (typeof date === 'string') return format(Date.parse(date.split('.')[0]), fmt);
  else return format(date, fmt);
};
