import { AxiosResponse } from 'axios';
import { RawCompany } from 'companies/types';
import { RawUser } from 'users/types';

export enum CorporateAdminModalName {
  Edit = 'Edit',
}

export type FetchCorporateAdminOrganizationReturn = Promise<AxiosResponse<RawCompany>>;
export type FetchCorporateAdminUserReturn = Promise<AxiosResponse<RawUser>>;
