import React from 'react';
import { Flight, FlightDetails } from 'flights/types';
import ProgressBar from 'theme/progressBar';
import styleVariables from 'theme/variables.module.scss';
import styles from './FlightSeats.module.scss';

interface FlightSeatsProps<T> {
  flight: T;
  smallProgressBar?: boolean;
  withLabel?: boolean;
}

const FlightSeats = <T extends Flight | FlightDetails>({
  flight,
  smallProgressBar = false,
  withLabel = false,
}: FlightSeatsProps<T>): JSX.Element => {
  const { isConfirmed, seatCompletion, takenSeats, totalSeats } = flight;

  return (
    <div className={styles.root}>
      <span className={styles.seats}>
        {withLabel && 'Seats filled: '}
        {takenSeats} / {totalSeats}
      </span>
      <ProgressBar
        progress={seatCompletion}
        color={isConfirmed ? undefined : styleVariables.colorError}
        small={smallProgressBar}
      />
    </div>
  );
};

export default FlightSeats;
