import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { MemberModalName } from 'members/types';
import Confirm from 'theme/confirm';
import Modal from 'theme/modal';

const DeleteMemberModal = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { companyId, memberId } = useParams();

  const { membersStore, toastsStore } = useStores();
  const { closeModal, deleteMember, isSubmitting, modalName, selectedMember } = membersStore;
  const { addToast, toastMessages } = toastsStore;

  if (!selectedMember) return null;

  const removeMember = (): void | undefined => {
    if (!selectedMember) return;

    deleteMember(selectedMember)
      .then(() => {
        addToast(toastMessages.MEMBER.DELETE_SUCCESS, 'success');

        if (companyId) navigate(paths.company(companyId));
        else if (memberId) navigate(paths.members);
      })
      .catch(() => {
        addToast(toastMessages.MEMBER.DELETE_ERROR);
      });
  };

  return (
    <Modal onClose={closeModal} isOpen={modalName === MemberModalName.DeleteMember}>
      <Confirm
        yesText="Confirm"
        action={removeMember}
        close={closeModal}
        submitting={isSubmitting}
        question={
          <>
            Are you sure that you want to remove <strong>{selectedMember.user.name}</strong> from{' '}
            {selectedMember.organization.name}?
          </>
        }
      />
    </Modal>
  );
};

export default observer(DeleteMemberModal);
