import api from 'api';
import { CompanyReturn, UpdateCompanyDto } from 'companies/types';
import { UsersReturn } from 'users/types';
import { FetchCorporateAdminOrganizationReturn } from './types';

export const fetchCorporateAdminOrganization = (organizationId: string): FetchCorporateAdminOrganizationReturn => {
  return api.get(`/organizations/${organizationId}`);
};

export const updateCorporateAdminOrganization = (data: UpdateCompanyDto, companyId: string): CompanyReturn => {
  return api.post(`/organizations/corporate/${companyId}`, data);
};

export const fetchCorporateAdminUser = (userId: string): UsersReturn => {
  return api.get('/users', { params: { pageSize: 1, pageNumber: 1, id: userId } });
};
