import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { paths } from 'app/routes/paths.const';
import FlightRoute from 'flights/flightRoute';
import FlightSeats from 'flights/flightSeats';
import FlightStatusTag from 'flights/flightStatusTag';
import { Flight } from 'flights/types';
import Tooltip from 'theme/tooltip';
import { useScreenSize } from 'utils/hooks';
import styles from './AdminFlightRow.module.scss';

const FLIGHT_SEATS_SMALL_PROGRESS_BAR_BREAKPOINT = 1440;

interface FlightTypeLabelProps {
  type: string;
}

const FlightTypeLabel = ({ type }: FlightTypeLabelProps): JSX.Element => (
  <div className={styles.label}>
    <div className={clsx(styles.labelIcon, styles[`labelIcon${type}`])} />
    <span>{type}</span>
  </div>
);

interface AdminFlightRowProps {
  flight: Flight;
}

const AdminFlightRow = ({ flight }: AdminFlightRowProps): JSX.Element => {
  const { charterType, departureDate, departureFrom, departureTo, disabled, flightNumber, status, typeLabel } = flight;

  const { width } = useScreenSize();

  const flightParams = {
    departureDate,
    departureFrom: departureFrom.iata,
    departureTo: departureTo.iata,
    flightNumber,
  };
  const flightDetailsUrl = `${paths.adminFlightDetails}?${new URLSearchParams(flightParams).toString()}`;

  return (
    <div className={clsx(styles.root, styles[charterType], { [styles.disabled]: disabled })}>
      <Link to={flightDetailsUrl} className={styles.link}>
        <div className={styles.container}>
          <div className={styles.left}>
            <span className={clsx(styles.flightNumber, styles[`flightNumber${charterType}`])}>
              Plane {flightNumber}
            </span>
            <FlightTypeLabel type={typeLabel} />
          </div>
          <FlightRoute flight={flight} />
        </div>

        <div className={styles.right}>
          <FlightSeats
            flight={flight}
            smallProgressBar={width <= FLIGHT_SEATS_SMALL_PROGRESS_BAR_BREAKPOINT}
            withLabel
          />
          <div className={styles.tagContainer}>
            <FlightStatusTag status={status} />
          </div>
        </div>
      </Link>
      {disabled && <Tooltip text="No details available for past flights" />}
    </div>
  );
};

export default AdminFlightRow;
