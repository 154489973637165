export type ToastMessages = Record<string, Record<string, string>>;

export const toastMessages: ToastMessages = {
  USER: {
    CREATE_SUCCESS: 'The user has been successfully added.',
    UPDATE_SUCCESS: 'The user has been successfully updated.',
    EMAIL_IN_USE_ERROR: 'This email address is already in use.',
    PHONE_IN_USE_ERROR: 'This phone number is already in use.',
    PASSWORD_FORMAT_ERROR: "The password doesn't respect the policy.",
    FETCH_ERROR: 'Failed to fetch users.',
    FETCH_DETAILS_ERROR: 'Failed to fetch the user details.',
    FETCH_BALANCE_ERROR: 'Failed to fetch the user balance.',
    ID_CONFIRMATION_SUCCESS: 'The user identification has been successfully confirmed.',
    ID_CONFIRMATION_ERROR: 'An error has occurred while trying to confirm the user identification. Please try again.',
    ID_PHOTO_REMOVAL_SUCCESS: 'The user ID photo has been successfully removed.',
    ID_PHOTO_REMOVAL_ERROR: 'An error has occurred while trying to remove the user ID photo. Please try again.',
    ID_UPLOAD_SUCCESS: 'The user ID photo has been successfully uploaded.',
    ID_UPLOAD_ERROR: 'An error has occurred while trying to upload the user ID photo. Please try again.',
    INTERCOM_CONFIG_ERROR: 'Failed to fetch Intercom config.',
  },
  COMPANY: {
    CREATE_SUCCESS: 'The company has been successfully added.',
    UPDATE_SUCCESS: 'The company has been successfully updated.',
    CREATE_ERROR: 'An error has occurred while creating the company.',
    UPDATE_ERROR: 'An error has occurred while updating the company.',
    FETCH_ERROR: 'Failed to fetch companies.',
    FETCH_DETAILS_ERROR: 'Failed to fetch the company details.',
    FETCH_BALANCE_ERROR: 'Failed to fetch the company balance.',
    ADDING_MEMBER_SUCCESS: 'The user has been successfully added to the company.',
    ADDING_MEMBER_ERROR: 'Failed to add a user to the company. Please try again.',
    ADDING_MEMBER_ERROR_DUPLICATE: 'The selected user is already a member of this company.',
    DELETE_SUCCESS: 'The company has been successfully deleted.',
    DELETE_ERROR: 'Failed to delete the company.',
  },
  MEMBER: {
    INVITE_SUCCESS: 'The member has been successfully invited to your organization.',
    INVITE_ERROR: 'There was a problem, the member could not be invited.',
    UPDATE_SUCCESS: 'The member has been successfully updated.',
    UPDATE_ERROR: 'Failed to update the member.',
    FETCH_LIST_ERROR: 'Failed to fetch members.',
    DELETE_SUCCESS: 'The member has been successfully deleted.',
    DELETE_ERROR: 'Failed to delete the member.',
    COMPANY_ADDED_BUT_MEMBER_ERROR: 'The company has been created, but failed to send an invitation to the owner.',
  },
  REQUEST_NEW_PASSWORD: {
    SUCCESS: 'The password change request has been successfully submitted. Please check your email.',
  },
  RESET_PASSWORD: {
    SUCCESS: 'The password has been successfully updated.',
    EMAIL_NOT_FOUND: 'The email was not found.',
    PASSWORD_TOO_SHORT: 'The password must have at least 7 characters.',
    TOKEN_MISSING: 'You are unauthorized to see this page.',
  },
  TEAM_MEMBER: {
    CREATE_SUCCESS: 'The team member has been successfully created.',
    UPDATE_SUCCESS: 'The team member has been successfully updated.',
    DELETE_SUCCESS: 'The team member has been successfully deleted.',
    DELETE_ERROR: 'Failed to delete the team member.',
    FETCH_ERROR: 'Failed to fetch team members.',
    FETCH_DETAILS_ERROR: 'Failed to fetch the team member details.',
    PERMISSIONS_ERROR: 'Failed to save the team member permissions.',
    ASSIGN_PERMISSIONS_SUCCESS: 'The team member permissions have been successfully assigned.',
    ASSIGN_PERMISSIONS_ERROR: 'Failed to assign the team member permissions.',
  },
  GUESTS: {
    FETCH_ERROR: 'Failed to fetch guests.',
    FETCH_DETAILS_ERROR: 'Failed to fetch the guest details.',
    CREATE_SUCCESS: 'The guest has been successfully created.',
    CREATE_ERROR: 'Failed to create the guest.',
    UPDATE_SUCCESS: 'The guest has been successfully updated.',
    UPDATE_ERROR: 'Failed to update the guest.',
    DELETE_SUCCESS: 'The guest has been successfully deleted.',
    DELETE_ERROR: 'Failed to delete the guest.',
  },
  FLIGHTS: {
    FETCH_FLIGHTS_ERROR: 'Failed to fetch flights.',
    FETCH_FLIGHT_DETAILS_ERROR: 'Failed to fetch the flight details.',
    FETCH_PLANNED_FLIGHT_ERROR: 'Failed to fetch the planned flight.',
    FETCH_PLANNED_FLIGHTS_ERROR: 'Failed to fetch planned flights.',
    FETCH_AIRPORTS_ERROR: 'Failed to fetch airports.',
    BOOKING_SUCCESS: 'The flight has been successfully booked.',
    BOOKING_ERROR: 'Failed to book the flight.',
  },
  ONBOARDING: {
    FINISH_SUCCESS: 'The onboarding has been successfully completed.',
  },
  ERROR: {
    DEFAULT: 'Oops, something went wrong. Please try again.',
  },
};
