import React from 'react';
import clsx from 'clsx';
import { Booking, Flight, FlightDetails, FlightType } from 'flights/types';
import AirportCodeLabel from 'theme/airportCodeLabel';
import Icon, { icons } from 'theme/icon';
import styles from './FlightRoute.module.scss';

enum HeaderType {
  From = 'From',
  To = 'To',
}

interface FlightRouteProps<T> {
  flight: T;
  simplified?: boolean;
  type?: 'hours' | 'airports';
}

const renderHeader = (content: string, type: HeaderType): JSX.Element => (
  <h2 className={clsx(styles[`label${type}`])}>{content}</h2>
);

const FlightRoute = <T extends Booking | Flight | FlightDetails>({
  flight,
  simplified = false,
  type = 'hours',
}: FlightRouteProps<T>): JSX.Element => {
  const { arrivalTime, charterType, departureFrom, departureTime, departureTo } = flight;
  const isColoredImageDisplayed = charterType === FlightType.OneTime || simplified || type === 'airports';

  const renderLabel = (name: string, headerType: HeaderType): JSX.Element | null => {
    if (simplified) return null;

    return type === 'airports' ? (
      renderHeader(name, headerType)
    ) : (
      <span className={styles[headerType === HeaderType.From ? 'labelFrom' : 'labelTo']}>
        {headerType === HeaderType.From ? departureTime : arrivalTime}
      </span>
    );
  };

  return (
    <div className={styles.root}>
      {renderLabel(departureFrom.city, HeaderType.From)}
      <AirportCodeLabel code={departureFrom.iata} />
      <Icon icon={isColoredImageDisplayed ? icons.route : icons.routeWhite} width={136} height={20} />
      <AirportCodeLabel code={departureTo.iata} />
      {renderLabel(departureTo.city, HeaderType.To)}
    </div>
  );
};

export default FlightRoute;
