import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import Icon, { icons } from 'theme/icon';
import { useOnClickOutside } from 'utils/hooks';
import styles from './UserMenu.module.scss';

const UserMenu = (): JSX.Element => {
  const { authStore } = useStores();
  const { loggedInUser, logout } = authStore;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: menuRef, handler: () => setIsMenuOpen(false) });

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuActions = [{ name: 'Logout', action: logout }];

  return (
    <div className={styles.root}>
      <span className={styles.username}>Hi, {loggedInUser?.email}</span>
      <div className={styles.content} ref={menuRef}>
        <button className={styles.menuBtn} onClick={toggleMenu}>
          <div className={styles.avatar}>
            <Icon icon={icons.user} />
          </div>
          <Icon
            icon={icons.dropdown}
            className={clsx(styles.arrowIcon, { [styles.arrowIconClosed]: !isMenuOpen })}
            height={20}
            width={20}
          />
        </button>
        <div className={clsx(styles.menu, { [styles.menuOpen]: isMenuOpen })}>
          <ul>
            {menuActions.map(({ action, name }, i) => (
              <li key={i} onClick={action}>
                {name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default observer(UserMenu);
