import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { AdminRole } from 'auth/types';
import Icon from 'theme/icon';
import { MenuLink } from '../types';
import styles from './SideMenu.module.scss';

interface LinkComponentProps {
  disabled?: boolean;
  isSublink?: boolean;
  link: MenuLink;
}

const LinkComponent = ({ isSublink, link }: LinkComponentProps): JSX.Element => {
  const { label, to, icon } = link;
  const location = useLocation();

  return (
    <Link
      to={to ?? '#'}
      className={clsx(location.pathname.includes(link.name) ? styles.linkActive : styles.linkNotActive, {
        [styles.hidden]: true,
        [styles.disabled]: link.disabled,
      })}
    >
      {!isSublink && icon && (
        <div className={styles.linkIcon}>
          <Icon icon={icon} />
        </div>
      )}
      <span>{label}</span>
    </Link>
  );
};

interface SideMenuProps {
  menuLinks: MenuLink[];
  title: string;
}

const SideMenu = ({ menuLinks, title }: SideMenuProps): JSX.Element | null => {
  const { authStore } = useStores();
  const { isEAD, loggedInUser, userRole } = authStore;

  const menuLinksFilteredByPermissions =
    !isEAD || userRole === AdminRole.SuperAdmin
      ? menuLinks
      : menuLinks.filter(
          (l) =>
            l.permissionId &&
            (loggedInUser?.viewPermissions.includes(l.permissionId) ||
              loggedInUser?.writePermissions.includes(l.permissionId))
        ) ?? [];

  if (menuLinksFilteredByPermissions.length === 0) return null;

  return (
    <div className={styles.root}>
      <span className={styles.category}>{title}</span>
      <ul>
        {menuLinksFilteredByPermissions.map((l, i) => (
          <li key={i} className={styles.link}>
            <LinkComponent link={l} />

            {l.sublinks && (
              <ul>
                {l.sublinks.map((l, i) => (
                  <li key={i} className={clsx(styles.link, styles.sublink)}>
                    <LinkComponent link={l} isSublink />
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(SideMenu);
