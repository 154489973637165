import clsx from 'clsx';
import { Option } from 'domain/types';
import { AdminPermissionOption } from 'domain/types';
import RadioButton from 'theme/radioButton';
import styles from './RadioOptionRow.module.scss';

interface RadioOptionRowProps {
  label: string;
  name: string;
  hiddenOption?: AdminPermissionOption;
  onChange: (rowValue: string) => void;
  selected: Option | undefined;
  selectionOptions: Option[];
}

const RadioOptionRow = ({
  hiddenOption,
  label,
  name,
  onChange,
  selected,
  selectionOptions,
}: RadioOptionRowProps): JSX.Element => {
  return (
    <tr>
      <td className={styles.nameCell}>{label}</td>
      {selectionOptions.map((o) => (
        <td key={o.value}>
          <div
            className={clsx(styles.checkboxContainer, {
              [styles.hidden]: hiddenOption?.value === o.label && hiddenOption?.name === name,
            })}
          >
            <RadioButton checked={selected?.value === o.value} onClick={() => onChange(o.value)} />
          </div>
        </td>
      ))}
    </tr>
  );
};

export default RadioOptionRow;
