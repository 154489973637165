import { FlightType } from 'flights/types';
import { FlightTypeOption, GenderOption, MembershipRole, MembershipRoleOption, Option } from './types';

const ROLE_OPTIONS: MembershipRoleOption[] = [
  { label: 'Owner', value: MembershipRole.Owner },
  { label: 'Manager', value: MembershipRole.Manager },
  { label: 'Employee', value: MembershipRole.Employee },
  { label: 'Guest', value: MembershipRole.Guest },
];

const INVITABLE_ROLE_OPTIONS: MembershipRoleOption[] = ROLE_OPTIONS.filter(
  ({ value }) => value !== MembershipRole.Owner
);
const INVITABLE_ROLE_OPTIONS_FOR_MANAGER: MembershipRoleOption[] = INVITABLE_ROLE_OPTIONS.filter(
  ({ value }) => value !== MembershipRole.Manager
);

const PAGE_SIZE_SELECTOR_OPTIONS: Option[] = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];

const STATUS_OPTIONS: Option[] = [
  { value: 'verified', label: 'verified' },
  { value: 'unverified', label: 'unverified' },
];

const FLIGHT_STATUS_OPTIONS: Option[] = [
  { value: 'confirmed', label: 'confirmed' },
  { value: 'unconfirmed', label: 'unconfirmed' },
];

const FLIGHT_TYPE_OPTIONS: FlightTypeOption[] = [
  { value: FlightType.OneTime, label: 'one time' },
  { value: FlightType.Requested, label: 'requested' },
  { value: FlightType.Weekly, label: 'weekly' },
];

const MEMBERSHIP_OPTIONS: Option[] = [
  { value: 'premium', label: 'Premium' },
  { value: 'free', label: 'Free' },
];

const GENDER_OPTIONS: GenderOption[] = [
  { value: 'Male', label: 'male' },
  { value: 'Female', label: 'female' },
];

const ENTOURAGE_TEAM_MEMBER_OPTIONS: Option[] = [
  { value: '3', label: 'Super Admin' },
  { value: '2', label: 'Admin' },
  { value: '1', label: 'View-only' },
];

const ENTOURAGE_TEAM_MEMBER_PERMISSION_OPTIONS: Option[] = [
  { label: 'Edit', value: 'writePermissions' },
  { label: 'View only', value: 'viewPermissions' },
  { label: 'No access', value: 'noAccess' },
];

const ENTOURAGE_TEAM_MEMBER_PERMISSION_KEYS: Option[] = [
  { label: 'Individual memberships', value: 'IndividualMemberships' },
  { label: 'Corporate memberships', value: 'CorporateMemberships' },
  { label: 'Corporate memberships invoices', value: 'CorporateInvoices' },
  { label: 'Flights', value: 'Charters' },
  { label: 'Invoices', value: 'IndividualInvoices' },
  { label: 'Team', value: 'EntourageTeam' },
];

export default {
  entourageTeamMember: ENTOURAGE_TEAM_MEMBER_OPTIONS,
  entourageTeamMemberPermission: ENTOURAGE_TEAM_MEMBER_PERMISSION_OPTIONS,
  entourageTeamMemberPermissionKey: ENTOURAGE_TEAM_MEMBER_PERMISSION_KEYS,
  flightStatus: FLIGHT_STATUS_OPTIONS,
  flightType: FLIGHT_TYPE_OPTIONS,
  gender: GENDER_OPTIONS,
  invitableRole: INVITABLE_ROLE_OPTIONS,
  invitableRoleForManager: INVITABLE_ROLE_OPTIONS_FOR_MANAGER,
  membership: MEMBERSHIP_OPTIONS,
  pageSizeSelector: PAGE_SIZE_SELECTOR_OPTIONS,
  role: ROLE_OPTIONS,
  status: STATUS_OPTIONS,
};
