import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import Tag from 'theme/tag';
import { Question, Answer } from 'users/types';
import styles from './OnboardingQuestionAnswer.module.scss';

interface OnboardingQuestionAnswerProps {
  question: Question;
}

function OnboardingQuestionAnswer({ question }: OnboardingQuestionAnswerProps): JSX.Element {
  const { answers, predefinedAnswers, kind } = question;
  const { id: userId } = useParams();
  const currentUserAnswers = answers.filter((a) => a.userId === userId);

  const matchAnswer = ({ customValue, predefinedAnswerId }: Answer): string => {
    return customValue || (predefinedAnswers.find((pa) => pa.id === predefinedAnswerId)?.value ?? '');
  };

  const displayAnswers = (): JSX.Element | null => {
    switch (kind) {
      case 'SingleChoice':
        return (
          <div className={styles.textContainer}>
            {currentUserAnswers.map((a) => (
              <p key={a.id}>{matchAnswer(a)}</p>
            ))}
          </div>
        );
      case 'MultipleChoice':
        return (
          <div className={styles.tags}>
            {currentUserAnswers.map((a) => (
              <Tag key={a.id} text={matchAnswer(a)} />
            ))}
          </div>
        );
      case 'Ordering':
        return (
          <ol className={clsx(styles.textContainer, styles.orderedList)}>
            {currentUserAnswers
              .sort((a, b) => a.orderingIdx - b.orderingIdx)
              .map((a) => (
                <li key={a.id}>{matchAnswer(a)}</li>
              ))}
          </ol>
        );
      default:
        return null;
    }
  };

  return <div className={styles.root}>{displayAnswers()}</div>;
}

export default OnboardingQuestionAnswer;
