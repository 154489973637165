import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import Icon, { icons } from 'theme/icon';
import { ToastType } from '../types';
import styles from './Toast.module.scss';

interface ToastProps {
  children: React.ReactNode;
  id: string;
  key: string;
  timeout?: number;
  type?: ToastType;
}

const ICON_MAP = {
  error: icons.sad,
  success: icons.tick,
  warning: icons.exclamationMark,
};

const LABEL_MAP = {
  error: 'Error',
  success: 'Confirmation',
  warning: 'Warning',
};

function Toast({ children, id, timeout = 6000, type = 'error' }: ToastProps): JSX.Element {
  const { toastsStore } = useStores();
  const { removeToast } = toastsStore;

  useEffect(() => {
    const timer = setTimeout(() => removeToast(id), timeout);
    return () => clearTimeout(timer);
  }, [id, removeToast, timeout]);

  return (
    <div className={clsx(styles.root, styles[type])}>
      <Icon icon={icons.cross} className={styles.closeIcon} onClick={() => removeToast(id)} />
      <div className={clsx(styles.icon, styles[`icon-${type}`])}>
        <Icon icon={ICON_MAP[type]} />
      </div>
      <div className={styles.textContainer}>
        <span className={clsx(styles.label, styles[`text-${type}`])}>{LABEL_MAP[type]}</span>
        <div className={clsx(styles.childrenContainer, styles[`text-${type}`])}>{children}</div>
      </div>
    </div>
  );
}

export default observer(Toast);
