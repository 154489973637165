import React from 'react';
import { MembershipRole, MembershipRoleOption } from 'domain/types';
import Checkbox from 'theme/checkbox';
import Input from 'theme/input';
import Select from 'theme/select';
import styles from './MemberRoleField.module.scss';

interface MemberRoleFieldProps {
  handleIsManagingChartersChange: () => void;
  handleIsManagingFinancialChange: () => void;
  handleMonthlyCharterLimitChange: (value: string) => void;
  handleRoleChange: (option: MembershipRoleOption) => void;
  isManagingCharters: boolean;
  isManagingFinancial: boolean;
  monthlyCharterLimit: number;
  options: MembershipRoleOption[];
  role: MembershipRoleOption;
}

const MemberRoleField = ({
  handleIsManagingChartersChange,
  handleIsManagingFinancialChange,
  handleMonthlyCharterLimitChange,
  handleRoleChange,
  isManagingCharters,
  isManagingFinancial,
  monthlyCharterLimit,
  options,
  role,
}: MemberRoleFieldProps): JSX.Element => {
  return (
    <tr>
      <th>Role</th>
      <td data-th="Role">
        <Select
          value={role || options[0]}
          options={options}
          onChange={handleRoleChange}
          className="table-style-form-field"
        />
        {role.value === MembershipRole.Employee && (
          <div className={styles.flightLimit}>
            <p className={styles.flightLimitText}>Monthly limit of flights to be booked:</p>
            <Input
              name="monthlyCharterLimit"
              onChange={(e) => handleMonthlyCharterLimitChange(e.target.value)}
              value={monthlyCharterLimit}
              type="number"
            />
          </div>
        )}
        {role.value === MembershipRole.Manager && (
          <div className={styles.checkboxes}>
            <Checkbox
              checked={isManagingFinancial}
              onClick={handleIsManagingFinancialChange}
              label="financial manager"
            />
            <Checkbox checked={isManagingCharters} onClick={handleIsManagingChartersChange} label="booking manager" />
          </div>
        )}
      </td>
    </tr>
  );
};

export default MemberRoleField;
