import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { IND_MEMBERSHIPS_COLUMNS_STORAGE_NAME, VERIFICATION_STATUSES } from 'domain/constants';
import { TableAction, TableActionBtn } from 'domain/types';
import { Member } from 'members/types';
import Breadcrumbs from 'theme/breadcrumbs';
import HorizontalScrollTable from 'theme/horizontalScrollTable';
import IconButton from 'theme/iconButton';
import LoadingArea from 'theme/loadingArea';
import MembershipTag from 'theme/membershipTag';
import PageHeader from 'theme/pageHeader';
import Pagination from 'theme/pagination';
import SearchPanel from 'theme/searchPanel';
import SortableHeadCell from 'theme/sortableHeadCell';
import TableActions from 'theme/tableActions';
import TableColumnSelectionModal from 'theme/tableColumnSelectionModal';
import TableItemName from 'theme/tableItemName';
import Tag, { TagColor } from 'theme/tag';
import { User, UserColumn, UserModalName } from 'users/types';
import UserFormModal from 'users/userFormModal';
import { useAdminPermissionsForUIManagement, useCustomizedTableColumns, useTitle } from 'utils/hooks';
import styles from './Users.module.scss';
import { initiallySelectedColumns, tableColumns } from './tableColumns';

const Users = (): JSX.Element => {
  const { membersStore, usersStore } = useStores();
  const { renderManagerTagLabel } = membersStore;
  const {
    changePageNumber,
    changePageSize,
    clearFilters,
    closeModal,
    fetchUsers,
    filters,
    isLoadingUsers,
    modalName,
    openModal,
    pageNumber,
    pageSize,
    resetFiltersSortAndPagination,
    sortingProps,
    updateFilters,
    users,
    usersCount,
  } = usersStore;

  const isEditionForbidden = useAdminPermissionsForUIManagement();
  const { activeColumnCount, columnsToSave, handleColumnsToSave, saveActiveColumns, savedActiveColumns } =
    useCustomizedTableColumns({
      initiallySelectedColumns,
      localStorageName: IND_MEMBERSHIPS_COLUMNS_STORAGE_NAME,
      tableColumns,
    });

  useEffect(() => {
    fetchUsers(pageSize, pageNumber);
  }, []);

  useTitle('Individual Memberships');

  useEffect(() => {
    return () => {
      resetFiltersSortAndPagination();
    };
  }, []);

  const rowButtonActions = (user: User): TableActionBtn[] => {
    const btns: TableActionBtn[] = [
      {
        hidden: false,
        link: paths.individualMembership(user.id, 'details'),
        text: 'Details',
        type: TableAction.Details,
      },
      {
        disabled: !user.intercomUrl,
        hidden: false,
        link: user.intercomUrl,
        text: 'Intercom',
        type: TableAction.ExtLink,
      },
      {
        action: () => openModal(UserModalName.UserForm, user),
        hidden: isEditionForbidden,
        text: 'Edit',
        type: TableAction.Edit,
      },
    ];

    return btns.filter((btn) => !btn.hidden);
  };

  const renderMembershipTagLabel = (membership: Member): string =>
    `${membership.organization.name} ${renderManagerTagLabel(membership)}`;

  const renderTableCellContent = (columnName: UserColumn, user: User): JSX.Element | null => {
    let content;

    switch (columnName) {
      case UserColumn.Sex:
        content = user.sex;
        break;
      case UserColumn.BirthDate:
        content = user.birthDate;
        break;
      case UserColumn.Country:
        content = user.country;
        break;
      case UserColumn.Phone:
        content = user.phone;
        break;
      case UserColumn.ActiveUntil:
        content = user.activeUntil;
        break;
      case UserColumn.IndMembership:
        content = user.isPremium ? <Tag text="Premium" color={TagColor.Brown} /> : <Tag text="Free" />;
        break;
      case UserColumn.CorporateMembership:
        content = (
          <div className={styles.tagContainer}>
            {user.corporateOrganizations.map((o) => (
              <MembershipTag
                key={o.id}
                label={renderMembershipTagLabel(o)}
                userRole={o.role}
                isManagingCharters={user.isManagingCharters}
                isManagingFinancial={user.isManagingFinancial}
              />
            ))}
          </div>
        );
        break;
      default:
        content = null;
    }

    return (
      <td data-th={columnName} key={columnName}>
        {content}
      </td>
    );
  };

  const leftColumn = (
    <table className="horizontal-table">
      <thead>
        <tr>
          <SortableHeadCell columnName="CreatedAt" name={UserColumn.No} {...sortingProps} />
          <SortableHeadCell columnName="Name" name={UserColumn.Name} {...sortingProps} />
        </tr>
      </thead>
      <tbody>
        {users.map((u) => (
          <tr key={u.id}>
            <td data-th={UserColumn.No}>{u.sequenceId}</td>
            <td data-th={UserColumn.Name}>
              <TableItemName
                title={u.name}
                subtitle={u.email}
                active={u.identityConfirmed}
                tooltipTexts={VERIFICATION_STATUSES}
                link={paths.individualMembership(u.id, 'details')}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const middleColumn = (
    <table className="horizontal-table">
      <thead>
        <tr>
          {savedActiveColumns.map(({ name }) => (
            <th data-th={name} key={name}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((u) => (
          <tr key={u.id}>{savedActiveColumns.map(({ name }) => renderTableCellContent(name, u))}</tr>
        ))}
      </tbody>
    </table>
  );

  const rightColumn = (
    <table className="horizontal-table">
      <thead>
        <tr>
          <th data-th="Action">
            <IconButton action={() => openModal(UserModalName.TableColumnSelection)} type={TableAction.Settings} />
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map((u) => (
          <tr key={u.id}>
            <td data-th="Action">
              <TableActions actions={rowButtonActions(u)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      <Breadcrumbs current="Individual memberships" />
      <PageHeader title="Individual memberships" />
      <SearchPanel
        loading={isLoadingUsers}
        filters={filters}
        reset={clearFilters}
        search={updateFilters}
        title="Search users"
      />
      <LoadingArea loading={isLoadingUsers}>
        <div className="main-container">
          <HorizontalScrollTable
            left={leftColumn}
            middle={middleColumn}
            right={rightColumn}
            columnCount={activeColumnCount}
          />
          <Pagination
            activePage={pageNumber}
            activePageItemCount={users.length}
            allItemCount={usersCount}
            disabled={isLoadingUsers}
            goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        </div>
      </LoadingArea>

      <UserFormModal />
      <TableColumnSelectionModal
        close={closeModal}
        columns={tableColumns}
        saveColumns={saveActiveColumns}
        tableColumnsToSave={columnsToSave}
        handleColumn={handleColumnsToSave}
        isOpen={modalName === UserModalName.TableColumnSelection}
      />
    </>
  );
};

export default observer(Users);
