import clsx from 'clsx';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
  checked: boolean;
  className?: string;
  label?: string;
  onClick?: () => void;
  readOnly?: boolean;
};

const Checkbox = ({ checked, className = '', label, onClick, readOnly }: CheckboxProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, { [styles.readOnly]: readOnly })}>
      <div className={styles.container} onClick={() => onClick?.()}>
        <input
          type="checkbox"
          readOnly
          className={clsx(styles.checkbox, { [styles.checkboxReadOnly]: readOnly })}
          checked={checked}
        />
        {label && <span className={styles.label}>{label}</span>}
      </div>
    </div>
  );
};

export default Checkbox;
