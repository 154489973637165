import React from 'react';
import { FlightStatus, FlightBookingStatus } from 'flights/types';
import Tag, { TagColor, TagType } from 'theme/tag';

interface FlightStatusTagProps<T> {
  status: T;
}

interface TagProps {
  color?: TagColor;
  type?: TagType;
}

const FlightStatusTag = <T extends FlightStatus | FlightBookingStatus>({
  status,
}: FlightStatusTagProps<T>): JSX.Element => {
  const tagProps = (): TagProps => {
    switch (status) {
      case FlightStatus.Canceled:
      case FlightBookingStatus.Cancelled:
      case FlightBookingStatus.Unprocessable:
        return { type: TagType.Error };
      case FlightStatus.Confirmed:
      case FlightBookingStatus.Confirmed:
      case FlightStatus.Ongoing:
        return { type: TagType.Success };
      case FlightStatus.Finished:
        return { type: TagType.Success, color: TagColor.Gray };
      case FlightStatus.Unconfirmed:
      case FlightBookingStatus.Unconfirmed:
      case FlightBookingStatus.NotFilled:
      case FlightBookingStatus.Processing:
        return { color: TagColor.Orange };
      case FlightBookingStatus.Refunded:
        return { color: TagColor.Gray };
      default:
        return {};
    }
  };

  return <Tag text={status} {...tagProps()} />;
};

export default FlightStatusTag;
