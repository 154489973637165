import { Outlet, Navigate } from 'react-router-dom';
import { useStores } from 'Root.store';
import { AdminPermission } from 'domain/types';
import { paths } from './paths.const';

interface AdminPermissionRouteProps {
  permission: AdminPermission;
}

const AdminPermissionRoute = ({ permission }: AdminPermissionRouteProps): JSX.Element | null => {
  const { authStore } = useStores();
  const { hasPermissionToView } = authStore;

  return hasPermissionToView(permission) ? <Outlet /> : <Navigate to={paths.individualMemberships} replace />;
};

export default AdminPermissionRoute;
