import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Loader from 'theme/loader';
import styles from './Button.module.scss';

type ButtonProps = {
  active?: boolean;
  buttonType?: 'primary' | 'secondary' | 'transparent';
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
  text?: string;
  to?: string;
  type?: 'button' | 'submit';
};

const Button = ({
  active = false,
  buttonType = 'primary',
  className = '',
  disabled,
  icon,
  loading,
  onClick,
  text,
  to = '',
  type = 'button',
}: ButtonProps): JSX.Element => {
  const classes = {
    className: clsx(styles.root, className, styles[buttonType], {
      [styles.active]: active,
    }),
  };

  return (
    <>
      {to ? (
        <Link to={to} {...classes}>
          <span>{text}</span>
          {icon}
        </Link>
      ) : (
        <button {...classes} type={type} onClick={() => onClick?.()} disabled={disabled}>
          <Loader small white loading={loading} className={styles.loader} />
          {!loading && text}
          {icon}
        </button>
      )}
    </>
  );
};

export default Button;
