import { runInAction, observable, makeObservable } from 'mobx';
import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isToday,
  format,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import { MOCK_MENUS, MOCK_PASSENGERS, MOCK_SEATS } from 'domain/mockData';
import { Option, SortColumn, SortDirection, SortingProps } from 'domain/types';
import { FlightsStore } from 'flights';
import { modelFlight, modelFlightDetails } from 'flights/modelFlight';
import * as requests from 'flights/requests';
import {
  CurrentMonths,
  FetchFlightDetailsParams,
  Flight,
  FlightDate,
  FlightDetails,
  FlightPassenger,
  FlightSeat,
} from 'flights/types';
import { DATE_FORMAT, formatDate } from 'utils/helpers';
import { AdminFlightModalName, FlightDetailsTabDataType, Meal, MealSearchValues, Menu } from './types';

const DEFAULT_DIFF_IN_DAYS = 14;
const TODAY = new Date();

class AdminFlightsStore extends FlightsStore {
  endDate: Date = addDays(TODAY, DEFAULT_DIFF_IN_DAYS);
  flightDetails: FlightDetails | null = null;
  flightTypeOptions: Option[] = [];
  isLoadingMenus = false;
  mealSortColumn: SortColumn = SortColumn.CreatedAt;
  mealSortDirection: SortDirection = SortDirection.Desc;
  menus: Menu[] = MOCK_MENUS;
  modalName: AdminFlightModalName | null = null;
  passengers: FlightPassenger[] = MOCK_PASSENGERS;
  passengerSortColumn: SortColumn = SortColumn.BookedAt;
  passengerSortDirection: SortDirection = SortDirection.Desc;
  searchValues: MealSearchValues = {
    description: '',
    name: '',
  };
  seats: FlightSeat[] = MOCK_SEATS;
  selectedMenuOption: Option = DEFAULT_SELECT_OPTION;
  startDate: Date = TODAY;
  statusOptions: Option[] = [];

  constructor(rootStore) {
    super(rootStore);
    makeObservable(this, {
      airportFrom: observable,
      airportTo: observable,
      datepickerEndDate: observable,
      datepickerStartDate: observable,
      endDate: observable,
      flightDetails: observable,
      flights: observable,
      flightTypeOptions: observable,
      isLoadingAirportAutocompleteHints: observable,
      isLoadingFlightDetails: observable,
      isLoadingFlights: observable,
      isLoadingMenus: observable,
      isSubmitting: observable,
      mealSortColumn: observable,
      mealSortDirection: observable,
      menus: observable,
      modalName: observable,
      numberOfDays: observable,
      passengers: observable,
      passengerSortColumn: observable,
      passengerSortDirection: observable,
      searchValues: observable,
      seats: observable,
      selectedMenuOption: observable,
      startDate: observable,
      statusOptions: observable,
    });
  }

  setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
  };

  get currentDates(): FlightDate[] {
    return Array.from({ length: this.numberOfDays + 1 }, (_, i) => {
      const date = addDays(this.startDate, i);
      return { date, isToday: isToday(date) };
    });
  }

  private get currentMonths(): CurrentMonths {
    const dateObjects = this.currentDates.map(({ date }) => date);
    const earliestMonth = format(dateObjects[0], DATE_FORMAT.MONTH_AND_YEAR);
    const latestMonth = format(dateObjects[dateObjects.length - 1], DATE_FORMAT.MONTH_AND_YEAR);

    return { earliestMonth, latestMonth };
  }

  get currentMonthLabel(): string {
    const { earliestMonth, latestMonth } = this.currentMonths;
    return earliestMonth === latestMonth ? earliestMonth : `${earliestMonth} - ${latestMonth}`;
  }

  get mappedPassengers(): FlightPassenger[] {
    // temporary
    return this.passengers.map(this.modelPassenger);
  }

  get pastFlights(): Flight[] {
    return this.flights.filter(({ departureDate }) => isBefore(parseISO(departureDate), TODAY));
  }

  get upcomingFlights(): Flight[] {
    return this.flights.filter(
      ({ departureDate }) => isAfter(parseISO(departureDate), TODAY) || isEqual(parseISO(departureDate), TODAY)
    );
  }

  get isFlightTablePaginationVisible(): boolean {
    return !!(!this.datepickerStartDate || !this.datepickerEndDate);
  }

  get passengerSortingProps(): SortingProps {
    return {
      sortColumn: this.passengerSortColumn,
      sortDirection: this.passengerSortDirection,
      updateSort: this.updatePassengerSort,
    };
  }

  get menuSortingProps(): SortingProps {
    return {
      sortColumn: this.mealSortColumn,
      sortDirection: this.mealSortDirection,
      updateSort: this.updateMenuSort,
    };
  }

  get menuOptions(): Option[] {
    return this.menus.map(({ id, name }) => ({ value: id, label: name }));
  }

  get selectedMenu(): Menu | undefined {
    return this.menus.find(({ id }) => id === this.selectedMenuOption.value);
  }

  get selectedMenuMeals(): Meal[] {
    if (!this.selectedMenu) return [];
    const { name, description } = this.searchValues;

    const filteredMeals = this.selectedMenu.meals.filter(
      (m) =>
        m.name.toLowerCase().includes(name.toLowerCase()) &&
        m.description.toLowerCase().includes(description.toLowerCase())
    );

    const sortMultiplier = this.mealSortDirection === SortDirection.Asc ? 1 : -1;

    return filteredMeals.sort((a, b) => {
      if (this.mealSortColumn === SortColumn.Name) return sortMultiplier * a.name.localeCompare(b.name);
      else if (this.mealSortColumn === SortColumn.Description)
        return sortMultiplier * a.description.localeCompare(b.description);
      else if (this.mealSortColumn === SortColumn.CreatedAt)
        return sortMultiplier * (parseInt(a.sequenceId) - parseInt(b.sequenceId));

      return 0;
    });
  }

  private modelPassenger = (p: FlightPassenger): FlightPassenger => ({
    ...p,
    bookedAt: formatDate(p.bookedAt, DATE_FORMAT.DAY),
  });

  fetchFlights = (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingFlights = true));

    if (this.datepickerStartDate && this.datepickerEndDate)
      this.numberOfDays = differenceInCalendarDays(this.datepickerEndDate, this.datepickerStartDate);

    return requests
      .fetchFlights({
        departureAfter: formatISO(startOfDay(this.startDate)),
        departureBefore: formatISO(endOfDay(this.endDate)),
        sourceAirport: this.airportFrom.value,
        targetAirport: this.airportTo.value,
      })
      .then(({ data }) => {
        runInAction(() => (this.flights = data.results.map(modelFlight)));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_FLIGHTS_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingFlights = false));
      });
  };

  fetchFlightDetails = (params: FetchFlightDetailsParams): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingFlightDetails = true));

    return requests
      .fetchFlightDetails(params)
      .then(({ data }) => {
        runInAction(() => (this.flightDetails = modelFlightDetails(data)));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_FLIGHT_DETAILS_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingFlightDetails = false));
      });
  };

  updateFilters = (): void => {
    if (this.datepickerStartDate) this.startDate = this.datepickerStartDate;
    if (this.datepickerEndDate) this.endDate = this.datepickerEndDate;

    this.fetchFlights();
  };

  setFlightTypeOptions = (option: Option): void => {
    this.flightTypeOptions = this.rootStore.updateOptions(this.flightTypeOptions, option);
  };

  setStatusOptions = (option: Option): void => {
    this.statusOptions = this.rootStore.updateOptions(this.statusOptions, option);
  };

  showPrevDays = (): void => {
    this.startDate = subDays(this.startDate, DEFAULT_DIFF_IN_DAYS + 1);
    this.endDate = subDays(this.endDate, DEFAULT_DIFF_IN_DAYS + 1);

    this.fetchFlights();
  };

  showNextDays = (): void => {
    this.startDate = addDays(this.startDate, DEFAULT_DIFF_IN_DAYS + 1);
    this.endDate = addDays(this.endDate, DEFAULT_DIFF_IN_DAYS + 1);

    this.fetchFlights();
  };

  filterFlightsByDay = (date: Date): Flight[] => {
    return this.flights.filter(({ departureDate }) => isSameDay(parseISO(departureDate), date));
  };

  private setSortColumn = (sortColumn: SortColumn, type: FlightDetailsTabDataType): void => {
    if (type === FlightDetailsTabDataType.Passengers) this.passengerSortColumn = sortColumn;
    else this.mealSortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection, type: FlightDetailsTabDataType): void => {
    if (type === FlightDetailsTabDataType.Passengers) this.passengerSortDirection = sortDirection;
    else this.mealSortDirection = sortDirection;
  };

  updateMenuSort = (column: SortColumn): void => {
    const dir = this.mealSortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.setSortColumn(column, FlightDetailsTabDataType.Meals);
    this.setSortDirection(dir, FlightDetailsTabDataType.Meals);
  };

  updatePassengerSort = (column: SortColumn): void => {
    const dir = this.passengerSortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.setSortColumn(column, FlightDetailsTabDataType.Passengers);
    this.setSortDirection(dir, FlightDetailsTabDataType.Passengers);
    // this.fetchUsers(this.pageSize, 1, true);
  };

  clearFilters = (): Promise<void> => {
    this.datepickerEndDate = undefined;
    this.datepickerStartDate = undefined;
    this.endDate = addDays(TODAY, DEFAULT_DIFF_IN_DAYS);
    this.startDate = TODAY;
    this.numberOfDays = DEFAULT_DIFF_IN_DAYS;
    this.flightTypeOptions = [];
    this.statusOptions = [];
    this.airportFrom = DEFAULT_SELECT_OPTION;
    this.airportTo = DEFAULT_SELECT_OPTION;

    return this.fetchFlights();
  };

  openModal = (modalName: AdminFlightModalName): void => {
    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.modalName = null;
  };

  setSelectedMenuOption = (option: Option): void => {
    this.selectedMenuOption = option;
  };
}

export default AdminFlightsStore;
