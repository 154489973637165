import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { CORPORATE_FLIGHTS_PAGE_SIZE } from 'domain/constants';
import DataList from 'theme/dataList';
// import Button from 'theme/button';
import LoadingArea from 'theme/loadingArea';
import Pagination from 'theme/pagination';
import SearchPanel from 'theme/searchPanel';
import styles from './AvailableFlights.module.scss';
import AvailableFlightRow from './availableFlightRow';

const AvailableFlights = (): JSX.Element => {
  const { corporateFlightsStore } = useStores();
  const {
    changePageNumber,
    clearFilters,
    fetchFlights,
    filterBtnDisabled,
    filters,
    flightCount,
    flights,
    isLoadingFlights,
    pageNumber,
  } = corporateFlightsStore;

  return (
    <div className={styles.root}>
      <SearchPanel
        className={styles.searchPanel}
        loading={isLoadingFlights}
        filters={filters}
        reset={clearFilters}
        search={fetchFlights}
        submitDisabled={filterBtnDisabled}
        title="Search flights"
      />
      <div className={clsx(styles.card, 'card')}>
        <LoadingArea loading={isLoadingFlights}>
          <h2 className={styles.title}>Available flights:</h2>
          <DataList dataLength={flights.length} textEmpty="No flights found!">
            <div className={styles.flightList}>
              {flights.map((f) => (
                <AvailableFlightRow key={f.id} flight={f} />
              ))}
            </div>
          </DataList>
        </LoadingArea>
      </div>
      <footer className={styles.footer}>
        <Pagination
          className={styles.pagination}
          activePage={pageNumber}
          activePageItemCount={flights.length}
          allItemCount={flightCount}
          disabled={isLoadingFlights}
          goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
          pageSize={CORPORATE_FLIGHTS_PAGE_SIZE}
        />
        {/* <Button onClick={() => alert('Request flight')} text="Request flight" /> */}
      </footer>
    </div>
  );
};

export default observer(AvailableFlights);
