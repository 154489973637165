import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import { DateType } from 'theme/searchPanel';
import { DATE_FORMAT } from 'utils/helpers';
import styles from './Dates.module.scss';

interface DatesProps {
  className?: string;
  clear?: () => void;
  endDate: Date | undefined;
  handleDate: ((date: Date, type: DateType) => void) | undefined;
  label: string;
  minStartDate?: Date;
  startDate: Date | undefined;
}

const Dates = ({
  className = '',
  clear,
  endDate,
  handleDate,
  label,
  minStartDate,
  startDate,
}: DatesProps): JSX.Element | null => {
  if (!handleDate) return null;

  const icon =
    startDate && endDate ? (
      <button className={styles.clearBtn} onClick={() => clear?.()}>
        <Icon icon={icons.cross} />
      </button>
    ) : (
      <Icon icon={icons.calendar} />
    );

  return (
    <div className={clsx(styles.root, className)}>
      <label className={styles.label}>{label}</label>
      <div className={styles.picker}>
        <div className={styles.pickerContainer}>
          <DatePicker
            className={styles.datePicker}
            selected={startDate}
            onChange={(date) => handleDate(date, DateType.Start)}
            dateFormat={DATE_FORMAT.DAY_FULL_ALT}
            minDate={minStartDate}
            placeholderText="Start date"
          />
          <hr className={styles.separator} />
          <DatePicker
            className={styles.datePicker}
            selected={endDate}
            onChange={(date) => handleDate(date, DateType.End)}
            dateFormat={DATE_FORMAT.DAY_FULL_ALT}
            minDate={startDate}
            placeholderText="End date"
          />
        </div>

        {icon}
      </div>
    </div>
  );
};

export default Dates;
