import Tag, { TagType } from 'theme/tag';

type VerificationTagProps = {
  altErrorStyling?: boolean;
  className?: string;
  labels?: string[];
  verified: boolean;
};

const DEFAULT_LABELS = ['verified', 'unverified'];

const VerificationTag = ({
  altErrorStyling,
  className = '',
  labels = DEFAULT_LABELS,
  verified,
}: VerificationTagProps): JSX.Element => {
  const verifiedTag = <Tag className={className} text={labels[0]} type={TagType.Success} />;
  const unverifiedTag = (
    <Tag className={className} text={labels[1]} type={altErrorStyling ? TagType.ErrorAlt : TagType.Error} />
  );

  return verified ? verifiedTag : unverifiedTag;
};

export default VerificationTag;
