import { useState, useEffect, FormEvent, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Input from 'theme/input';
import { useFormValidation } from 'utils/hooks';
import styles from './OnboardingCompanyForm.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  nameRequired: '',
};

interface OnboardingCompanyFormProps {
  children: ReactNode;
  className: string;
}

function OnboardingCompanyForm({ children, className }: OnboardingCompanyFormProps): JSX.Element {
  const navigate = useNavigate();

  const { companiesStore, corporateAdminStore, toastsStore } = useStores();
  const { isSubmitting, updateCompany } = companiesStore;
  const { corporateAdminOrganization } = corporateAdminStore;
  const { addToast, toastMessages } = toastsStore;

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [taxId, setTaxId] = useState('');
  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleNameChange = (value: string): void => {
    cleanValidationErrors(['nameRequired']);
    setName(value);
  };

  const handleAddressChange = (value: string): void => {
    setAddress(value);
  };

  const handleTaxIdChange = (value: string): void => {
    setTaxId(value);
  };

  const validateOnSubmit = (): void => {
    handleValidation('nameRequired', formValidationMessages.NAME_REQUIRED, !name);
  };

  const editCompany = (): void => {
    validateOnSubmit();
    if (!corporateAdminOrganization || !name) return;

    updateCompany(
      {
        name: name.trim(),
        address: address.trim(),
        taxId: taxId.trim(),
      },
      corporateAdminOrganization.id,
      false,
      true
    )
      .then(() => {
        addToast(toastMessages.COMPANY.UPDATE_SUCCESS, 'success');
        navigate(paths.onboarding('members'));
      })
      .catch(() => {
        addToast(toastMessages.COMPANY.UPDATE_ERROR);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    editCompany();
  };

  useEffect(() => {
    if (corporateAdminOrganization) setName(corporateAdminOrganization.name);
  }, [corporateAdminOrganization]);

  return (
    <div className={clsx(styles.root, className)}>
      <h1>Complete company details</h1>
      <form className={clsx(styles.form, 'table-style-form')} onSubmit={submit} autoComplete="off">
        <table className="vertical-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td data-th="Name">
                <Input
                  name="name"
                  onChange={(e) => handleNameChange(e.target.value)}
                  value={name}
                  error={validation.nameRequired}
                  inTable
                />
              </td>
            </tr>
            <tr>
              <th>Tax ID</th>
              <td data-th="Tax ID">
                <Input name="taxId" onChange={(e) => handleTaxIdChange(e.target.value)} value={taxId} inTable />
              </td>
            </tr>
            <tr>
              <th>Address</th>
              <td data-th="Address">
                <Input name="address" onChange={(e) => handleAddressChange(e.target.value)} value={address} inTable />
              </td>
            </tr>
          </tbody>
        </table>
        <footer className={styles.footer}>
          {children}
          <Button text="Next" type="submit" disabled={isSubmitting} loading={isSubmitting} />
        </footer>
      </form>
    </div>
  );
}

export default observer(OnboardingCompanyForm);
