import appleStoreImage from './apple-store.png';
import googlePlayImage from './google-play.png';
import holdingHandImage from './holding-hand.png';
import miniLogo from './mini-logo.png';
import plane from './plane.png';
import screensImage from './screens.png';

export const pngs = {
  appleStoreImage,
  googlePlayImage,
  holdingHandImage,
  miniLogo,
  plane,
  screensImage,
};
