import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { CompanyModalName } from 'companies/types';
import { TableAction } from 'domain/types';
import Members from 'members';
import EditMemberModal from 'members/editMemberModal';
import Breadcrumbs from 'theme/breadcrumbs';
import Confirm from 'theme/confirm';
import IconButton from 'theme/iconButton';
import LoadingArea from 'theme/loadingArea';
import Modal from 'theme/modal';
import PageHeader from 'theme/pageHeader';
import { useAdminPermissionsForUIManagement, useTitle } from 'utils/hooks';
import EditCompanyModal from '../editCompanyModal';
import CompanyDetailsTable from './companyDetailsTable';

const ANCESTORS = [{ title: 'Corporate memberships', to: paths.companies }];

function CompanyDetails(): JSX.Element | null {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const { companiesStore, membersStore, toastsStore } = useStores();
  const { closeModal, company, deleteCompany, fetchCompany, modalName, isLoadingCompany, isSubmitting, openModal } =
    companiesStore;
  const { fetchMembers, pageNumber, pageSize } = membersStore;
  const { addToast, toastMessages } = toastsStore;

  const isEditionForbidden = useAdminPermissionsForUIManagement();
  const companyName = company?.name ?? '';

  useTitle(`${companyName} details`);

  const removeCompany = (): void => {
    deleteCompany().then(() => navigate(paths.companies));
  };

  useEffect(() => {
    if (companyId) {
      Promise.all([
        fetchCompany(companyId),
        fetchMembers({ pageSize, pageNumber, onInit: false, companyIdParam: companyId }),
      ]).catch(() => addToast(toastMessages.COMPANY.FETCH_DETAILS_ERROR));
    }
  }, [companyId]);

  if (!companyId) return null;

  const renderHeaderBtns = (): JSX.Element | null => {
    if (isEditionForbidden) return null;

    return (
      <div className="page-header-btn-container">
        <IconButton
          action={() => openModal(CompanyModalName.EditCompany, company)}
          text="Edit"
          type={TableAction.Edit}
        />
        <IconButton
          action={() => openModal(CompanyModalName.DeleteCompany, company)}
          text="Delete"
          type={TableAction.Delete}
        />
      </div>
    );
  };

  return (
    <>
      <Breadcrumbs ancestors={ANCESTORS} current="Company details" />
      <LoadingArea loading={isLoadingCompany}>
        <PageHeader title={companyName}>{renderHeaderBtns()}</PageHeader>
        <CompanyDetailsTable />
        <Members isEAD />
      </LoadingArea>

      <Modal onClose={closeModal} isOpen={modalName === CompanyModalName.DeleteCompany}>
        <Confirm
          yesText="Confirm"
          action={removeCompany}
          close={closeModal}
          submitting={isSubmitting}
          question={
            <>
              Are you sure that you want to delete <strong>{companyName}</strong>?
            </>
          }
        />
      </Modal>
      <EditMemberModal />
      <EditCompanyModal />
    </>
  );
}

export default observer(CompanyDetails);
