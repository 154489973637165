import { useSearchParams } from 'react-router-dom';

export interface FlightUrlParams {
  departureDate: string;
  departureFrom: string;
  departureTo: string;
  flightNumber: string;
}

export function useFlightUrlParams(): FlightUrlParams {
  const [searchParams] = useSearchParams();
  const urlParams = {
    departureDate: searchParams.get('departureDate')?.slice(0, -1) || '',
    departureFrom: searchParams.get('departureFrom') || '',
    departureTo: searchParams.get('departureTo') || '',
    flightNumber: searchParams.get('flightNumber') || '',
  };

  return urlParams;
}
