import { useState, useCallback, useMemo } from 'react';
import { formValidationMessages } from 'domain/formValidationMessages';
import { FormValidation } from 'domain/types';

interface UseFormValidationReturn {
  cleanValidationErrors: (keys: string[]) => void;
  formValidationMessages: Record<string, string>;
  handleValidation: (key: string, message: string, condition: boolean) => void;
  isSubmitBtnDisabled: boolean;
  resetValidation: () => void;
  validation: FormValidation;
}

export function useFormValidation(validations: FormValidation, isSubmitting = false): UseFormValidationReturn {
  const [validation, setValidation] = useState<FormValidation>(validations);

  const cleanValidationErrors = (keys: string[]): void => {
    setValidation(
      (prev): FormValidation => ({
        ...prev,
        ...keys.reduce((a, b) => ({ ...a, [b]: '' }), {}),
      })
    );
  };

  const handleValidation = (key: string, message: string, condition: boolean): void => {
    if (condition) setValidation((prev) => ({ ...prev, [key]: message }));
  };

  const resetValidation = useCallback((): void => {
    setValidation(validations);
  }, [validations]);

  const isSubmitBtnDisabled = useMemo(
    () => isSubmitting || !!Object.values(validation).filter(Boolean).length,
    [isSubmitting, validation]
  );

  return {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  };
}
