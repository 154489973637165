import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { DEBOUNCE_INTERVAL, DEFAULT_PAGE_SIZE } from 'domain/constants';
import { MembershipRole, SortColumn, SortDirection, SortingProps } from 'domain/types';
import { debounce } from 'lodash';
import { DATE_FORMAT, formatDate } from 'utils/helpers';
import * as requests from './requests';
import {
  FlightGuest,
  FlightGuestCreateDto,
  FlightGuestSearchValues,
  FlightGuestModalName,
  RawFlightGuest,
  FlightGuestUpdateDto,
} from './types';

class GuestsStore {
  rootStore: RootStore;

  guest: FlightGuest | undefined;
  guests: FlightGuest[] = [];
  guestCount = 0;
  isLoadingGuest = false;
  isLoadingGuests = false;
  isResettingGuests = false;
  isSubmitting = false;
  modalName: FlightGuestModalName | null = null;
  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  searchValues: FlightGuestSearchValues = {
    name: '',
  };
  selectedGuest: FlightGuest | undefined;
  sortColumn: SortColumn = SortColumn.CreatedAt;
  sortDirection: SortDirection = SortDirection.Desc;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private modelGuest = (guest: RawFlightGuest): FlightGuest => ({
    ...guest,
    birthDate: formatDate(guest.birthDate, DATE_FORMAT.DAY),
    fullName: guest.firstName + ' ' + guest.lastName,
    role: MembershipRole.Guest,
  });

  private get currentOrganizationId(): string {
    return this.rootStore.corporateAdminStore.currentContextOrganizationId;
  }

  fetchGuests = (resetPreviousData = false): Promise<void> => {
    if (!this.currentOrganizationId) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;

    if (resetPreviousData) {
      runInAction(() => {
        this.pageNumber = 1;
        this.isResettingGuests = true;
      });
    } else runInAction(() => (this.isLoadingGuests = true));

    return requests
      .fetchGuests({
        organizationId: this.currentOrganizationId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        searchValues: this.searchValues,
        sortBy: this.sortColumn + this.sortDirection,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.guests = data.results.map(this.modelGuest);
          this.guestCount = data.count;
        });
      })
      .catch(() => addToast(toastMessages.GUESTS.FETCH_ERROR))
      .finally(() =>
        runInAction(() => {
          if (resetPreviousData) this.isResettingGuests = false;
          this.isLoadingGuests = false;
        })
      );
  };

  fetchGuest = (guestId: string): Promise<void> => {
    if (!this.currentOrganizationId) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingGuest = true));

    return requests
      .fetchGuest(this.currentOrganizationId, guestId)
      .then(({ data }) => {
        runInAction(() => {
          this.guest = this.modelGuest(data.results[0]);
        });
      })
      .catch(() => addToast(toastMessages.GUESTS.FETCH_DETAILS_ERROR))
      .finally(() => runInAction(() => (this.isLoadingGuest = false)));
  };

  createGuest = (data: FlightGuestCreateDto): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .createGuest(data)
      .then(() => {
        addToast(toastMessages.GUESTS.CREATE_SUCCESS, 'success');
        this.fetchGuests();
      })
      .catch(() => addToast(toastMessages.GUESTS.CREATE_ERROR))
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  updateGuest = (data: FlightGuestUpdateDto): Promise<void> => {
    if (!this.selectedGuest) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateGuest(this.selectedGuest.id, data)
      .then(() => {
        if (!this.selectedGuest) return;

        addToast(toastMessages.GUESTS.UPDATE_SUCCESS, 'success');
        this.fetchGuest(this.selectedGuest.id);
      })
      .catch(() => addToast(toastMessages.GUESTS.UPDATE_ERROR))
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  deleteGuest = (guestId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .deleteGuest(guestId)
      .then(() => {
        addToast(toastMessages.GUESTS.DELETE_SUCCESS, 'success');
        runInAction(() => {
          this.fetchGuests();
          this.closeModal();
        });
      })
      .catch(() => addToast(toastMessages.GUESTS.DELETE_ERROR))
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  private setSortColumn = (sortColumn: SortColumn): void => {
    this.sortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection): void => {
    this.sortDirection = sortDirection;
  };

  get sortingProps(): SortingProps {
    return { sortColumn: this.sortColumn, sortDirection: this.sortDirection, updateSort: this.updateSort };
  }

  private fetchGuestsDebounced = debounce(() => {
    this.fetchGuests(true);
  }, DEBOUNCE_INTERVAL);

  setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
    this.fetchGuestsDebounced();
  };

  updateSort = (column: SortColumn): void => {
    const dir = this.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
    this.setSortColumn(column);
    this.setSortDirection(dir);

    this.fetchGuests(true);
  };

  changePageSize = (newPageSize: number): void => {
    if (newPageSize !== this.pageSize) {
      this.pageSize = newPageSize;
      this.fetchGuests(true);
    }
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) {
      this.pageNumber = newPageNumber;
      this.fetchGuests();
    }
  };

  openModal = (modalName: FlightGuestModalName, guest?: FlightGuest): void => {
    const isSelectingGuest = modalName === FlightGuestModalName.Edit || modalName === FlightGuestModalName.Delete;

    if (isSelectingGuest && guest) this.selectedGuest = guest;
    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.selectedGuest = undefined;
    this.modalName = null;
  };
}

export default GuestsStore;
