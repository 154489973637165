import clsx from 'clsx';
import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  checked: boolean;
  onClick?: () => void;
};

const RadioButton = ({ checked, onClick }: RadioButtonProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, { [styles.checked]: checked })} onClick={() => onClick?.()}>
      <div className={clsx(styles.dot, { [styles.dotChecked]: checked })} />
    </div>
  );
};

export default RadioButton;
