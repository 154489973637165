export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum SortColumn {
  BookedAt = 'BookedAt',
  CreatedAt = 'CreatedAt',
  Description = 'Description',
  Name = 'Name',
  Seat = 'Seat',
  UserName = 'UserName',
}

export interface SortingProps {
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  updateSort: (column: SortColumn) => void;
}
