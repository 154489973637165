import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { AdminFlightModalName } from 'adminFlights/types';
import { paths } from 'app/routes/paths.const';
import FlightInfo from 'flights/flightInfo';
// import Button from 'theme/button';
import Confirm from 'theme/confirm';
// import IconButton from 'theme/iconButton';
import Modal from 'theme/modal';
import { useFlightUrlParams } from 'utils/hooks';
// import { TableAction } from 'domain/types';
import EditAdminFlightModal from '../editAdminFlightModal';

const ANCESTORS = [{ title: 'Flights', to: paths.adminFlights }];

const AdminFlightDetails = (): JSX.Element => {
  const urlParams = useFlightUrlParams();

  const { adminFlightsStore } = useStores();
  const {
    closeModal,
    fetchFlightDetails,
    flightDetails,
    isLoadingFlightDetails,
    isSubmitting,
    modalName,
    // openModal
  } = adminFlightsStore;

  useEffect(() => {
    fetchFlightDetails(urlParams);
  }, []);

  //   const headerButtons = <div className="page-header-btn-container">
  //   <IconButton action={() => openModal(AdminFlightModalName.FlightEdit)} text="Edit" type={TableAction.Edit} />
  //   <IconButton
  //     action={() => openModal(AdminFlightModalName.FlightDelete)}
  //     text="Delete"
  //     type={TableAction.Delete}
  //   />
  //   <Button
  //     buttonType="secondary"
  //     onClick={() => openModal(AdminFlightModalName.FlightUndoConfirmation)}
  //     text="Undo confirmation"
  //   />
  // </div>;

  const modals = (
    <>
      <EditAdminFlightModal />
      <Modal onClose={closeModal} isOpen={modalName === AdminFlightModalName.FlightDelete}>
        <Confirm
          yesText="Confirm"
          action={() => alert('Delete flight')}
          close={closeModal}
          submitting={isSubmitting}
          question="Are you sure that you want to delete this flight?"
        />
      </Modal>
      <Modal onClose={closeModal} isOpen={modalName === AdminFlightModalName.FlightUndoConfirmation}>
        <Confirm
          yesText="Confirm"
          action={() => alert('Undo confirmation')}
          close={closeModal}
          submitting={isSubmitting}
          question="Are you sure that you want to undo confirmation?"
        />
      </Modal>
    </>
  );

  return (
    <FlightInfo
      ancestors={ANCESTORS}
      flightDetails={flightDetails}
      loading={isLoadingFlightDetails}
      // headerButtons={headerButtons}
      modals={modals}
    />
  );
};

export default observer(AdminFlightDetails);
