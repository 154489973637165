import { useState, useEffect, useMemo } from 'react';
import { Company, UpdateCompanyDto } from 'companies/types';
import { FormValidation } from 'domain/types';
import Input from 'theme/input';

interface UseCompanyDetailsFormProps {
  cleanValidationErrors: (errors: string[]) => void;
  selected: Company | undefined;
  validation: FormValidation;
}

interface UseCompanyDetailsFormReturn {
  companyName: string;
  companyDto: UpdateCompanyDto;
  formTable: JSX.Element;
}

export const useCompanyDetailsForm = ({
  cleanValidationErrors,
  selected,
  validation,
}: UseCompanyDetailsFormProps): UseCompanyDetailsFormReturn => {
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [taxId, setTaxId] = useState('');

  const handleCompanyNameChange = (value: string): void => {
    cleanValidationErrors(['companyNameRequired']);
    setCompanyName(value);
  };

  const handleAddressChange = (value: string): void => {
    setAddress(value);
  };

  const handleTaxIdChange = (value: string): void => {
    setTaxId(value);
  };

  const companyDto = useMemo(
    () => ({
      address: address.trim(),
      name: companyName.trim(),
      taxId: taxId.trim(),
    }),
    [address, companyName, taxId]
  );

  useEffect(() => {
    if (selected) {
      setCompanyName(selected.name);
      setAddress(selected.address || '');
      setTaxId(selected.taxId || '');
    }
  }, [selected]);

  const formTable = (
    <table className="vertical-table">
      <tbody>
        <tr>
          <th>Company Name</th>
          <td data-th="Company Name">
            <Input
              name="name"
              onChange={(e) => handleCompanyNameChange(e.target.value)}
              value={companyName}
              error={validation.companyNameRequired}
              inTable
            />
          </td>
        </tr>
        <tr>
          <th>Tax ID</th>
          <td data-th="Tax ID">
            <Input name="taxId" onChange={(e) => handleTaxIdChange(e.target.value)} value={taxId} inTable />
          </td>
        </tr>
        <tr>
          <th>Address</th>
          <td data-th="Address">
            <Input name="address" onChange={(e) => handleAddressChange(e.target.value)} value={address} inTable />
          </td>
        </tr>
      </tbody>
    </table>
  );

  return {
    companyDto,
    companyName,
    formTable,
  };
};
