import { makeAutoObservable, runInAction } from 'mobx';
import { isBefore } from 'date-fns';
import { RootStore } from 'Root.store';
import { DEBOUNCE_INTERVAL, DEFAULT_PAGE_SIZE, REDIRECT_LINKS } from 'domain/constants';
import { MembershipRole, OrganizationKind, SortColumn, SortDirection, SortingProps } from 'domain/types';
import { debounce } from 'lodash';
import { Member } from 'members/types';
import { formatDate } from 'utils/helpers';
import * as requests from './requests';
import {
  Company,
  CompanyDto,
  CompanyModalName,
  CompanySearchValues,
  CreateMembershipDto,
  RawCompany,
  UpdateCompanyDto,
} from './types';

class CompaniesStore {
  rootStore: RootStore;

  companies: Company[] = [];
  companiesCount = 0;
  company: Company | undefined;
  isLoadingCompanies = false;
  isLoadingCompany = false;
  isLoadingMember = false;
  isLoadingMembers = false;
  isResettingCompanies = false;
  isSubmitting = false;
  member: Member | undefined;
  members: Member[] = [];
  membersCount = 0;
  modalName: CompanyModalName | null = null;
  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  searchValues: CompanySearchValues = {
    organization: '',
  };
  selectedCompany: Company | undefined;
  sortColumn: SortColumn = SortColumn.CreatedAt;
  sortDirection: SortDirection = SortDirection.Desc;
  uploadProgress = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get sortingProps(): SortingProps {
    return { sortColumn: this.sortColumn, sortDirection: this.sortDirection, updateSort: this.updateSort };
  }

  private setSortColumn = (sortColumn: SortColumn): void => {
    this.sortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection): void => {
    this.sortDirection = sortDirection;
  };

  modelCompany = (company: RawCompany): Company => {
    const { activeUntil, createdAt } = company;

    return {
      ...company,
      activeUntil: activeUntil ? formatDate(activeUntil) : '',
      createdAt: formatDate(createdAt),
      isActive: activeUntil ? isBefore(new Date(), new Date(activeUntil)) : false,
    };
  };

  fetchCompany = (companyId: string): Promise<void> => {
    runInAction(() => (this.isLoadingCompany = true));

    return requests
      .fetchCompanies({
        id: companyId,
        pageSize: 1,
        pageNumber: 1,
      })
      .then(({ data }) => {
        runInAction(() => (this.company = this.modelCompany(data.results[0])));
      })
      .finally(() => runInAction(() => (this.isLoadingCompany = false)));
  };

  fetchCompanies = (pageSize: number, pageNumber: number, resetPreviousData?: boolean): Promise<void> => {
    if (resetPreviousData) {
      this.pageNumber = 1;
      runInAction(() => (this.isResettingCompanies = true));
    } else runInAction(() => (this.isLoadingCompanies = true));

    return requests
      .fetchCompanies({
        pageSize,
        pageNumber,
        kind: OrganizationKind.Corporate,
        searchValues: this.searchValues,
        sortBy: this.sortColumn + this.sortDirection,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.companies = data.results.map(this.modelCompany);
          this.companiesCount = data.count;
        });
      })
      .catch(() => {
        const { addToast, toastMessages } = this.rootStore.toastsStore;
        addToast(toastMessages.COMPANY.FETCH_ERROR);
      })
      .finally(() => {
        runInAction(() => {
          if (resetPreviousData) this.isResettingCompanies = false;
          this.isLoadingCompanies = false;
        });
      });
  };

  createCompany = async ({
    name,
    ownerFirstName,
    ownerLastName,
    ownerEmail,
    ownerPhone,
  }: CompanyDto): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    try {
      const { data } = await requests.createCompany(name);
      await this.rootStore.membersStore.inviteMember({
        firstName: ownerFirstName,
        lastName: ownerLastName,
        monthlyCharterLimit: null,
        organizationId: data.id,
        userEmail: ownerEmail,
        userPhone: ownerPhone,
        role: MembershipRole.Owner,
        ...REDIRECT_LINKS,
      });

      this.fetchCompanies(this.pageSize, this.pageNumber);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      throw err;
    } finally {
      runInAction(() => (this.isSubmitting = false));
    }
  };

  updateCompany = (
    data: UpdateCompanyDto,
    organizationId: string,
    isCompanyDetailsView = false,
    shouldNotFetchCompanies = false
  ): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateCompany(data, organizationId)
      .then(() => {
        if (isCompanyDetailsView) this.fetchCompany(organizationId);
        else if (!shouldNotFetchCompanies) this.fetchCompanies(this.pageSize, this.pageNumber);

        this.closeModal();
      })
      .finally(() => {
        runInAction(() => (this.isSubmitting = false));
      });
  };

  deleteCompany = (): Promise<void> => {
    if (!this.selectedCompany) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .deleteCompany(this.selectedCompany.id)
      .then(() => {
        this.fetchCompanies(this.pageSize, this.pageNumber);
        addToast(toastMessages.COMPANY.DELETE_SUCCESS, 'success');
        this.closeModal();
      })
      .catch(() => addToast(toastMessages.COMPANY.DELETE_ERROR))
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  setSelectedCompany = (company: Company | undefined): void => {
    this.selectedCompany = company;
  };

  openModal = (modalName: CompanyModalName, company?: Company): void => {
    const isSelectingCompany =
      modalName === CompanyModalName.EditCompany ||
      modalName === CompanyModalName.DeleteCompany ||
      modalName === CompanyModalName.AddUserToCompany;

    if (isSelectingCompany && company) this.selectedCompany = company;
    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.selectedCompany = undefined;
    this.modalName = null;
  };

  private fetchCompaniesDebounced = debounce(() => {
    this.fetchCompanies(this.pageSize, 1, true);
  }, DEBOUNCE_INTERVAL);

  setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
    this.fetchCompaniesDebounced();
  };

  private resetPagination = (): void => {
    this.pageNumber = 1;
    this.pageSize = DEFAULT_PAGE_SIZE;
  };

  private resetSortBy = (): void => {
    this.sortColumn = SortColumn.CreatedAt;
    this.sortDirection = SortDirection.Desc;
  };

  private resetFilters = (): void => {
    this.searchValues = { organization: '' };
  };

  resetFiltersSortAndPagination = (): void => {
    this.resetPagination();
    this.resetSortBy();
    this.resetFilters();
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) {
      this.pageNumber = newPageNumber;
      this.fetchCompanies(this.pageSize, newPageNumber);
    }
  };

  changePageSize = (newPageSize: number): void => {
    if (newPageSize !== this.pageSize) {
      this.pageSize = newPageSize;
      this.fetchCompanies(newPageSize, 1, true);
    }
  };

  updateSort = (column: SortColumn): void => {
    const dir = this.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.setSortColumn(column);
    this.setSortDirection(dir);
    this.fetchCompanies(this.pageSize, 1, true);
  };

  addUserToCompany = (data: CreateMembershipDto, isCompanyDetailsView = false): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .createMembership(data)
      .then(() => {
        if (isCompanyDetailsView) this.rootStore.membersStore.fetchMembers({ companyIdParam: data.organizationId });
      })
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  private setUploadProgress = (value: number): void => {
    this.uploadProgress = value;
  };
}

export default CompaniesStore;
