import api from 'api';
import { addOptionalParamsToAPIRequest } from 'utils/helpers';
import {
  FetchAirportsReturn,
  FetchFlightDetailsParams,
  FetchFlightDetailsReturn,
  FetchFlightsParams,
  FetchFlightsReturn,
  FetchBookingReturn,
  FetchBookingsReturn,
  FlightBookingDto,
  FlightBookingReturn,
} from './types';

export const fetchFlights = <T extends FetchFlightsParams>(data: T): FetchFlightsReturn => {
  return api.get('/charters', {
    params: addOptionalParamsToAPIRequest(data),
  });
};

export const fetchFlightDetails = (params: FetchFlightDetailsParams): FetchFlightDetailsReturn => {
  return api.get('/charters/details', { params });
};

export const fetchBookings = (): FetchBookingsReturn => {
  return api.get('/charters/bookings');
};

export const fetchPlannedFlight = (pnrCode: string): FetchBookingReturn => {
  return api.get(`/charters/bookings/${pnrCode}`);
};

export const bookFlight = (data: FlightBookingDto): FlightBookingReturn => {
  return api.post('/charters/bookings', data);
};

export const fetchAirports = (): FetchAirportsReturn => {
  return api.get('/airports');
};
