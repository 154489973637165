import React, { useState, useRef, MouseEvent } from 'react';
import clsx from 'clsx';
import { TableAction } from 'domain/types';
import Icon, { icons } from 'theme/icon';
import IconButton from 'theme/iconButton';
import { useOnClickOutside } from 'utils/hooks';
import styles from './ActionDropdown.module.scss';

type ClickAction = () => void;

export interface ActionDropdownOption {
  action?: ClickAction;
  link?: string;
  text?: string;
  type: TableAction;
}

interface ActionDropdownProps {
  className?: string;
  hiddenOnDesktop?: boolean;
  options: ActionDropdownOption[];
}

const ActionDropdown = ({ className = '', hiddenOnDesktop = false, options }: ActionDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: rootRef, handler: () => setIsOpen(false) });

  const handleAction = (action: ClickAction): void => {
    action();
    setIsOpen(false);
  };

  const handleOpen = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className={clsx(styles.root, className, {[styles.hiddenOnDesktop]: hiddenOnDesktop})} ref={rootRef}>
      <button onClick={handleOpen}>
        <Icon icon={icons.more} />
      </button>
      {isOpen && (
        <div className={styles.dropdown}>
          <ul>
            {options.map(({ action, link, text, type }, i) => (
              <li key={i}>
                <IconButton text={text} type={type} link={link} action={() => action && handleAction(action)} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionDropdown;
