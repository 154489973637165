import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import Icon, { icons } from 'theme/icon';
import styles from './AdminFlightPagination.module.scss';

interface AdminFlightPaginationProps {
  className?: string;
}

const AdminFlightPagination = ({ className = '' }: AdminFlightPaginationProps): JSX.Element => {
  const { adminFlightsStore } = useStores();
  const { isFlightTablePaginationVisible, showPrevDays, showNextDays } = adminFlightsStore;

  const renderContent = (): JSX.Element | null => {
    if (!isFlightTablePaginationVisible) return null;

    return (
      <>
        <button className={styles.btn} onClick={showPrevDays}>
          <Icon icon={icons.chevronLeft} />
        </button>
        <button className={styles.btn} onClick={showNextDays}>
          <Icon icon={icons.chevronRight} />
        </button>
      </>
    );
  };

  return <div className={clsx(styles.root, className)}>{renderContent()}</div>;
};

export default observer(AdminFlightPagination);
