import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import LoadingArea from 'theme/loadingArea';
import NonAuthedPageLayoutTitle from 'theme/nonAuthedPageLayoutTitle';

const CompanyMemberInvitation = (): JSX.Element => {
  const [search] = useSearchParams();
  const invitationId = search.get('invitationId');
  const token = search.get('token');
  const userId = search.get('userId');

  const { membersStore } = useStores();
  const { fetchMemberInvitation, isSubmitting, memberInvitationFetchError } = membersStore;

  useEffect(() => {
    if (!invitationId || !token) return;
    fetchMemberInvitation(token, invitationId, userId);
  }, []);

  const title = memberInvitationFetchError
    ? 'There was an error when trying to finish the process of setting up your account. Please contact the administrator.'
    : 'The process of setting up your account is almost finished.';

  return (
    <LoadingArea loading={isSubmitting}>
      <NonAuthedPageLayoutTitle title={title} centered />
      {!memberInvitationFetchError && (
        <p className="center">
          In order to create a password for your account, please check your email and follow the instructions.
        </p>
      )}
    </LoadingArea>
  );
};

export default observer(CompanyMemberInvitation);
