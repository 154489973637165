import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { Member } from 'members/types';
import MembershipTag from 'theme/membershipTag';
import TableCard from 'theme/tableCard';
import VerificationTag from 'theme/verificationTag';
import styles from './MemberDetailsTable.module.scss';

interface MemberDetailsTableProps {
  member: Member;
}

function MemberDetailsTable({ member }: MemberDetailsTableProps): JSX.Element {
  const { membersStore } = useStores();
  const { renderManagerTagLabel } = membersStore;
  const { isManagingCharters, isManagingFinancial, role, user } = member;

  return (
    <TableCard title="Personal information" className={styles.root} vertical>
      <table>
        <tbody>
          <tr>
            <th>First name</th>
            <td data-th="First name">{user.firstName}</td>
          </tr>
          <tr>
            <th>Last name</th>
            <td data-th="Last name">{user.lastName}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td data-th="Email">{user.email}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td data-th="Phone">{user.phone}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td data-th="Status">
              <VerificationTag verified={user.identityConfirmed} />
            </td>
          </tr>
          <tr>
            <th>Role</th>
            <td data-th="Role">
              <MembershipTag
                label={renderManagerTagLabel(member)}
                userRole={role}
                isManagingCharters={isManagingCharters}
                isManagingFinancial={isManagingFinancial}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </TableCard>
  );
}

export default observer(MemberDetailsTable);
