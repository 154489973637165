import { useMemo } from 'react';
import { FormValidation } from 'domain/types';
import Input from 'theme/input';
import { UserFormData } from 'users/types';

interface UserDetailsFormTableProps {
  isOwner?: boolean;
  tableChildren?: JSX.Element;
  userFormData: UserFormData;
  validation: FormValidation;
}

const UserDetailsFormTable = ({
  isOwner = false,
  userFormData,
  tableChildren,
  validation,
}: UserDetailsFormTableProps): JSX.Element => {
  const {
    firstName,
    lastName,
    email,
    phone,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handlePhoneChange,
  } = userFormData;

  const renderRowLabel = (name: string): string => (isOwner ? `Owner's ${name}` : `${name}`);

  const labels = {
    firstName: renderRowLabel('First name'),
    lastName: renderRowLabel('Last name'),
    email: renderRowLabel('Email'),
    phone: renderRowLabel('Phone'),
  };

  const firstNameFormRow = useMemo(() => {
    return (
      <tr>
        <th>{labels.firstName}</th>
        <td data-th={labels.firstName}>
          <Input
            name="first-name"
            onChange={(e) => handleFirstNameChange(e.target.value)}
            value={firstName}
            error={validation.firstNameRequired}
            inTable
          />
        </td>
      </tr>
    );
  }, [firstName, validation.firstNameRequired]);

  const lastNameFormRow = useMemo(() => {
    return (
      <tr>
        <th>{labels.lastName}</th>
        <td data-th={labels.lastName}>
          <Input
            name="last-name"
            onChange={(e) => handleLastNameChange(e.target.value)}
            value={lastName}
            error={validation.lastNameRequired}
            inTable
          />
        </td>
      </tr>
    );
  }, [lastName, validation.lastNameRequired]);

  const emailFormRow = useMemo(() => {
    return (
      <tr>
        <th>{labels.email}</th>
        <td data-th={labels.email}>
          <Input
            name="new-email"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            error={validation.emailInvalid || validation.emailRequired}
            inTable
          />
        </td>
      </tr>
    );
  }, [email, validation.emailInvalid, validation.emailRequired]);

  const phoneFormRow = useMemo(() => {
    return (
      <tr>
        <th>{labels.phone}</th>
        <td data-th={labels.phone}>
          <Input
            name="phone"
            onChange={(e) => handlePhoneChange(e.target.value)}
            value={phone}
            error={validation.phoneInvalid || validation.phoneRequired}
            inTable
          />
        </td>
      </tr>
    );
  }, [phone, validation.phoneInvalid, validation.phoneRequired]);

  const formRows = (
    <>
      {firstNameFormRow}
      {lastNameFormRow}
      {emailFormRow}
      {phoneFormRow}
    </>
  );

  const defaultFormLayout = (
    <tbody>
      {formRows}
      {tableChildren}
    </tbody>
  );

  const ownerFormLayout = (
    <tbody>
      {tableChildren}
      {formRows}
    </tbody>
  );

  return <table className="vertical-table">{isOwner ? ownerFormLayout : defaultFormLayout}</table>;
};

export default UserDetailsFormTable;
