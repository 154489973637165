import { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { toastMessages } from 'domain/toastMessages';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Input from 'theme/input';
import NonAuthedPageLayoutTitle from 'theme/nonAuthedPageLayoutTitle';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
};

function RequestNewPassword(): JSX.Element {
  const navigate = useNavigate();
  const { authStore, toastsStore } = useStores();
  const { loading, requestNewPassword } = authStore;
  const { addToast } = toastsStore;

  const [email, setEmail] = useState('');
  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  useTitle('Request New Password');

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired']);
    setEmail(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);

    requestNewPassword(email)
      .then(() => {
        navigate(paths.login);
        addToast(toastMessages.REQUEST_NEW_PASSWORD.SUCCESS, 'success');
      })
      .catch((err) => {
        if (err.response.status === 404) addToast(toastMessages.RESET_PASSWORD.EMAIL_NOT_FOUND);
        else addToast(toastMessages.ERROR.DEFAULT);
      });
  };

  return (
    <>
      <NonAuthedPageLayoutTitle title="Request new password" />
      <form onSubmit={onSubmit}>
        <Input
          label="Email"
          name="email"
          onChange={(e) => handleEmailChange(e.target.value)}
          placeholder="Enter your email"
          value={email}
          error={validation.emailRequired}
        />

        <div>
          <Button text="Send" loading={loading} disabled={loading} type="submit" />
        </div>
      </form>
    </>
  );
}

export default observer(RequestNewPassword);
