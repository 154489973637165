import { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import clsx from 'clsx';
import Button from 'theme/button';
import Icon, { icons } from 'theme/icon';
import LazyImage from 'theme/lazyImage';
import ProgressBar from 'theme/progressBar';
import styles from './FileUpload.module.scss';

interface FileUploadProps {
  className?: string;
  disabled?: boolean;
  imageUrl: string;
  loading: boolean;
  onFileUpload: (file: File | null) => Promise<void>;
  removeImage: () => void;
  text: string;
  uploadProgress: number;
}

const FileUpload = ({
  className = '',
  disabled = false,
  imageUrl,
  loading,
  onFileUpload,
  removeImage,
  text = '',
  uploadProgress,
}: FileUploadProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item: { files: File[] }) => {
      if (disabled) return;
      const { files } = item;
      if (files?.length === 1) setUploadedFile(files[0]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop() && !disabled,
    }),
  });

  const handleClickBrowse = (): void => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) setUploadedFile(file);
  };

  const handleRemoveFile = (): void => {
    setUploadedFile(null);
  };

  const isActive = canDrop && isOver;

  const sendFile = (): void => {
    if (uploadedFile)
      onFileUpload(uploadedFile).then(() => {
        setUploadedFile(null);
      });
  };

  const imageContainer = (
    <div className={styles.imgContainer}>
      {!disabled && (
        <Button
          buttonType="secondary"
          icon={<Icon icon={icons.delete} />}
          className={styles.removeDocumentBtn}
          onClick={removeImage}
        />
      )}
      <LazyImage src={imageUrl} alt="User ID photo" contained />
    </div>
  );

  const instructionText = uploadedFile ? (
    <>
      <p className={styles.text}>
        <strong>Uploaded file:</strong> {uploadedFile.name}
      </p>
      <div className={styles.fileManagementBtns}>
        <Button onClick={sendFile} text="Upload" buttonType="primary" loading={loading} disabled={loading} />
        <Button onClick={handleRemoveFile} text="Remove" buttonType="secondary" />
      </div>
    </>
  ) : (
    <p className={styles.text}>
      Drop your file or <span onClick={handleClickBrowse}>browse</span>
    </p>
  );

  const progressArea = (
    <div className={styles.progress}>
      <span>Uploading... {uploadProgress} %</span>
      <ProgressBar progress={uploadProgress} className={styles.progressBar} />
    </div>
  );

  const infoArea = disabled ? (
    <></>
  ) : (
    <div className={styles.info}>
      <h2>{text}</h2>
      <Icon className={styles.icon} icon={icons.cloud} width={69} height={50} />
      <input ref={fileInputRef} type="file" className={styles.fileInput} onChange={handleFileChange} />
      {instructionText}
    </div>
  );

  return (
    <>
      {imageUrl ? (
        imageContainer
      ) : (
        <div ref={drop} className={clsx(styles.root, className, { [styles.active]: isActive })}>
          {uploadProgress > 0 ? progressArea : infoArea}
        </div>
      )}
    </>
  );
};

export default FileUpload;
