import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { TeamModalName } from 'team/types';
import Breadcrumbs from 'theme/breadcrumbs';
import Button from 'theme/button';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import VerificationTag from 'theme/verificationTag';
import { useAdminPermissionsForUIManagement, useTitle } from 'utils/hooks';
import TeamMemberModal from '../teamMemberModal';
import TeamMemberRoleTag from '../teamMemberRoleTag';
import styles from './TeamMemberDetails.module.scss';

const TeamMemberDetails = (): JSX.Element => {
  const { teamMemberId } = useParams();
  const { teamStore } = useStores();
  const { fetchTeamMember, isLoadingTeamMember, openModal, resetTeamMember, teamMember } = teamStore;

  useTitle(`${teamMember?.name} details`);
  const isEditionForbidden = useAdminPermissionsForUIManagement();

  useEffect(() => {
    if (!teamMemberId) return;
    fetchTeamMember(teamMemberId);
  }, [teamMemberId]);

  useEffect(() => {
    return () => {
      resetTeamMember();
    };
  }, []);

  return (
    <div className={styles.root}>
      <Breadcrumbs ancestors={[{ title: 'Team', to: paths.team('entourage') }]} current="Team member details" />
      <LoadingArea loading={isLoadingTeamMember}>
        <div className={styles.main}>
          <PageHeader title={teamMember?.name ?? ''}>
            {!isEditionForbidden && (
              <Button text="Edit" onClick={() => openModal(TeamModalName.EditTeamMember, teamMember)} />
            )}
          </PageHeader>
          {teamMember && (
            <div className={clsx(styles.details, 'card')}>
              <table className="vertical-table">
                <tbody>
                  <tr>
                    <th>First name</th>
                    <td data-th="First name">{teamMember.firstName}</td>
                  </tr>
                  <tr>
                    <th>Last name</th>
                    <td data-th="Last name">{teamMember.lastName}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td data-th="Email">{teamMember.email}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td data-th="Phone">{teamMember.phone}</td>
                  </tr>
                  <tr>
                    <th>Sex</th>
                    <td data-th="Sex">{teamMember.sex}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td data-th="Status">
                      <VerificationTag verified={teamMember.identityConfirmed} />
                    </td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td data-th="Role">
                      <TeamMemberRoleTag role={teamMember.role} />
                    </td>
                  </tr>
                  {!teamMember.isSuperAdmin && (
                    <>
                      <tr>
                        <th>Write permissions</th>
                        <td data-th="Write permissions">{teamMember.writePermissionLabels.join(', ')}</td>
                      </tr>
                      <tr>
                        <th>View permissions</th>
                        <td data-th="View permissions">{teamMember.viewPermissionLabels.join(', ')}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </LoadingArea>

      <TeamMemberModal isMemberDetailsView />
    </div>
  );
};

export default observer(TeamMemberDetails);
