import { useState, useEffect, useMemo, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import { Option } from 'domain/types';
import RoleAndPermissions from 'team/roleAndPermissions';
import { TeamModalName } from 'team/types';
import Autocomplete from 'theme/autocomplete';
import Button from 'theme/button';
import Modal from 'theme/modal';
import { useRoleAndPermissions } from 'utils/hooks';

function AddPermissionsModal(): JSX.Element {
  const { usersStore, teamStore, toastsStore } = useStores();
  const { isLoadingUserAutocompleteHints, getUserAutocompleteHints } = usersStore;
  const { closeModal, isSubmitting, modalName, updateTeamMember, teamMember } = teamStore;
  const { addToast, toastMessages } = toastsStore;

  const [user, setUser] = useState<Option>(DEFAULT_SELECT_OPTION);

  const { resetRoleAndPermissions, roleAndPermissionsProps } = useRoleAndPermissions();
  const { isSuperAdmin, setIsSuperAdmin, setViewPermissions, setWritePermissions, viewPermissions, writePermissions } =
    roleAndPermissionsProps;

  const resetForm = (): void => {
    setUser(DEFAULT_SELECT_OPTION);
    resetRoleAndPermissions();
  };

  const onModalClose = (): void => {
    closeModal();
    resetForm();
  };

  const requestTeamMember = (): void | undefined => {
    updateTeamMember(user.value, {
      isSuperAdmin,
      writePermissions: isSuperAdmin ? [] : writePermissions,
      viewPermissions: isSuperAdmin ? [] : viewPermissions,
    })
      .then(() => {
        addToast(toastMessages.TEAM_MEMBER.ASSIGN_PERMISSIONS_SUCCESS, 'success');
        onModalClose();
      })
      .catch(() => {
        addToast(toastMessages.TEAM_MEMBER.ASSIGN_PERMISSIONS_ERROR);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    requestTeamMember();
  };

  useEffect(() => {
    if (teamMember) {
      setIsSuperAdmin(teamMember.isSuperAdmin);
      setViewPermissions(teamMember.viewPermissions);
      setWritePermissions(teamMember.writePermissions);
    }
  }, [teamMember]);

  const isSubmitBtnDisabled = useMemo(() => isSubmitting || !user.value, [isSubmitting, user.value]);

  return (
    <Modal onClose={onModalClose} isOpen={modalName === TeamModalName.AddPermissions} title="Add permissions">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <table className="vertical-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td data-th="Name">
                <Autocomplete
                  className="table-style-form-field"
                  loading={isLoadingUserAutocompleteHints}
                  placeholder="Search users..."
                  onChange={getUserAutocompleteHints(true)}
                  onSelect={setUser}
                  value={user}
                />
              </td>
            </tr>
            <RoleAndPermissions {...roleAndPermissionsProps} />
          </tbody>
        </table>
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(AddPermissionsModal);
