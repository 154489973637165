import React from 'react';
import Loader from 'theme/loader';
import styles from './LoadingArea.module.scss';

interface LoadingAreaProps {
  loading: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

const LoadingArea = ({ children, loading }: LoadingAreaProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <Loader loading={loading} className={styles.loader} />
      {!loading && children}
    </div>
  );
};

export default LoadingArea;
