export enum TableAction {
  Add = 'Add',
  Block = 'Block',
  Delete = 'Delete',
  Details = 'Details',
  Edit = 'Edit',
  ExtLink = 'ExtLink',
  Remove = 'Remove',
  Settings = 'Settings',
}

export interface TableActionBtn {
  action?: () => void;
  className?: string;
  disabled?: boolean;
  hidden?: boolean | false;
  link?: string | undefined;
  text?: string;
  type: TableAction;
}
