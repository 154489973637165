import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Option } from 'domain/types';
import AirportCodeLabel from 'theme/airportCodeLabel';
import Input from 'theme/input';
import SelectDropdown from 'theme/selectDropdown';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import styles from './Autocomplete.module.scss';

interface AutocompleteProps {
  className?: string;
  isStatic?: boolean;
  label?: string;
  loading: boolean;
  onChange: (query: string) => Promise<Option[] | void>;
  onSelect: (option: Option) => void;
  placeholder: string;
  value: Option;
}

const Autocomplete = ({
  className = '',
  isStatic = false,
  label,
  loading,
  onChange,
  onSelect,
  placeholder,
  value,
}: AutocompleteProps): JSX.Element => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: rootRef, handler: () => setIsDropdownOpen(false) });

  const handleValueChange = (val: string): void | undefined => {
    setQuery(val);

    if (!val) return;
    onChange(val)?.then((data) => {
      data && setOptions(data);
    });
  };

  const handleSelect = (option: Option): void => {
    onSelect(option);
    setQuery(option.label);
    setIsDropdownOpen(false);
  };

  const fetchOptionsIfStatic = (): void => {
    if (isStatic) {
      setIsDropdownOpen(true);
      onChange('').then((data) => {
        data && setOptions(data);
      });
    }
  };

  useEffect(() => {
    if (!isStatic && options.length > 0 && !query) setIsDropdownOpen(false);
  }, [isStatic, options.length, query]);

  useEffect(() => {
    if (!isStatic && loading) setIsDropdownOpen(true);
  }, [isStatic, loading]);

  useEffect(() => {
    if (!value.label) setQuery('');
  }, [value.label]);

  useEffect(() => {
    if (value.label) setQuery(value.label);
  }, [value.label]);

  return (
    <div className={clsx(styles.root, className)} ref={rootRef}>
      <Input
        className={clsx({ [styles.hasIata]: value.iata })}
        name="autocomplete"
        placeholder={placeholder}
        label={label}
        value={query}
        autoCompleteOff
        onFocus={fetchOptionsIfStatic}
        onChange={(e) => handleValueChange(e.target.value)}
      />
      {isDropdownOpen && (
        <SelectDropdown loading={loading} options={options} selectedOption={value} onSelect={handleSelect} />
      )}
      {value.iata && <AirportCodeLabel className={styles.iataLabel} code={value.iata} />}
    </div>
  );
};

export default Autocomplete;
