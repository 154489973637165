import React from 'react';
import { CompanyColumn } from 'companies/types';
import { TableColumn } from 'domain/types';
import Button from 'theme/button';
import Checkbox from 'theme/checkbox';
import Modal from 'theme/modal';
import { UserColumn } from 'users/types';
import styles from './TableColumnSelectionModal.module.scss';

interface TableColumnSelectionModalProps<T extends UserColumn | CompanyColumn> {
  close: () => void;
  columns: TableColumn<T>[];
  handleColumn: (column: TableColumn<T>) => void;
  isOpen: boolean;
  saveColumns: () => void;
  tableColumnsToSave: TableColumn<T>[];
}

const TableColumnSelectionModal = <T extends UserColumn | CompanyColumn>({
  close,
  columns,
  handleColumn,
  isOpen,
  saveColumns,
  tableColumnsToSave,
}: TableColumnSelectionModalProps<T>): JSX.Element => {
  const isColumnSelected = (column: TableColumn<T>): boolean => tableColumnsToSave.some((c) => c.name === column.name);

  const handleSave = (): void => {
    saveColumns();
    close();
  };

  return (
    <Modal onClose={close} isOpen={isOpen} title="Choose columns">
      <div className={styles.content}>
        {columns.map((c) => (
          <Checkbox
            key={c.name}
            checked={isColumnSelected(c) || c.static}
            readOnly={c.static}
            onClick={() => handleColumn(c)}
            label={c.name}
          />
        ))}
      </div>
      <div className="modal-btns-container">
        <Button onClick={close} text="Cancel" buttonType="transparent" />
        <Button text="Apply columns" onClick={handleSave} />
      </div>
    </Modal>
  );
};

export default TableColumnSelectionModal;
