import { useRef, useState } from 'react';
import clsx from 'clsx';
import { MembershipRoleOption, Option } from 'domain/types';
import Icon, { icons } from 'theme/icon';
import Input from 'theme/input';
import SelectDropdown from 'theme/selectDropdown';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import styles from './Select.module.scss';

interface SelectProps<T> {
  className?: string;
  disabled?: boolean;
  label?: string;
  lightTheme?: boolean;
  onChange: (option: T) => void;
  options: T[];
  up?: boolean;
  value: T;
  withSearchIcon?: boolean;
}

const Select = <T extends Option | MembershipRoleOption>({
  className = '',
  disabled = false,
  label,
  onChange,
  options,
  up,
  value,
  withSearchIcon = false,
}: SelectProps<T>): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: rootRef, handler: () => setIsDropdownOpen(false) });

  const handleChange = (option: T): void => {
    setIsDropdownOpen(false);
    onChange(option);
  };

  return (
    <div className={clsx(styles.root, className)} ref={rootRef}>
      <div
        className={clsx(styles.inputContainer, { [styles.inputContainerDisabled]: disabled })}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <Input
          className={styles.input}
          type={withSearchIcon ? 'select-search' : 'text'}
          name="select"
          value={value.label}
          label={label}
          readOnly
        />
        <Icon icon={icons.dropdown} className={clsx(styles.icon, { [styles.iconClose]: !isDropdownOpen })} />
      </div>
      {isDropdownOpen && <SelectDropdown options={options} selectedOption={value} onSelect={handleChange} up={up} />}
    </div>
  );
};

export default Select;
