import { Menu } from 'adminFlights/types';
import { FlightPassenger, FlightSeat } from 'flights/types';

export const MOCK_PASSENGERS: FlightPassenger[] = [
  {
    bookedAt: '2023-07-21T10:00:00',
    id: 'passenger1',
    identityConfirmed: true,
    isPremium: true,
    meal: 'vegetarian',
    name: 'John Doe',
    seat: {
      number: '12A',
      location: 'window',
    },
  },
  {
    bookedAt: '2023-07-21T11:30:00',
    id: 'passenger2',
    identityConfirmed: true,
    isPremium: false,
    meal: 'standard',
    name: 'Jane Smith',
    seat: {
      number: '17B',
      location: 'aisle',
    },
  },
  {
    bookedAt: '2023-07-21T12:15:00',
    id: 'passenger3',
    identityConfirmed: true,
    isPremium: false,
    meal: 'standard',
    name: 'Michael Johnson',
    seat: {
      number: '21C',
      location: 'middle',
    },
  },
  {
    bookedAt: '2023-07-21T13:00:00',
    id: 'passenger4',
    identityConfirmed: true,
    isPremium: true,
    meal: 'gluten-free',
    name: 'Emily Brown',
    seat: {
      number: '14A',
      location: 'window',
    },
  },
  {
    bookedAt: '2023-07-21T14:45:00',
    id: 'passenger5',
    identityConfirmed: false,
    isPremium: false,
    meal: 'standard',
    name: 'David Lee',
    seat: {
      number: '8C',
      location: 'aisle',
    },
  },
  {
    bookedAt: '2023-07-21T15:30:00',
    id: 'passenger6',
    identityConfirmed: true,
    isPremium: false,
    meal: 'standard',
    name: 'Sarah Wilson',
    seat: {
      number: '10B',
      location: 'middle',
    },
  },
];

export const MOCK_SEATS: FlightSeat[] = [
  { taken: true, user: { id: '1', name: 'John Doe' }, seatNumber: '1A' },
  { taken: true, user: { id: '2', name: 'John Doe' }, seatNumber: '1B' },
  { taken: true, user: { id: '3', name: 'John Doe' }, seatNumber: '1C' },
  { taken: true, user: { id: '4', name: 'John Doe' }, seatNumber: '1D' },
  { taken: true, user: { id: '5', name: 'John Doe' }, seatNumber: '2A' },
  { taken: true, user: { id: '6', name: 'John Doe' }, seatNumber: '2B' },
  { taken: true, user: { id: '7', name: 'John Doe' }, seatNumber: '2C' },
  { taken: true, user: { id: '8', name: 'John Doe' }, seatNumber: '2D' },
  { taken: true, user: { id: '9', name: 'John Doe' }, seatNumber: '3A' },
  { taken: true, user: { id: '10', name: 'John Doe' }, seatNumber: '3B' },
  { taken: true, user: { id: '11', name: 'John Doe' }, seatNumber: '3C' },
  { taken: true, user: { id: '12', name: 'John Doe' }, seatNumber: '3D' },
  { taken: true, user: { id: '13', name: 'John Doe' }, seatNumber: '4A' },
  { taken: true, user: { id: '14', name: 'John Doe' }, seatNumber: '4B' },
  { taken: false, user: { id: '15', name: 'John Doe' }, seatNumber: '4C' },
  { taken: false, user: { id: '16', name: 'John Doe' }, seatNumber: '4D' },
  { taken: false, user: { id: '17', name: 'John Doe' }, seatNumber: '5A' },
  { taken: false, user: { id: '18', name: 'John Doe' }, seatNumber: '5B' },
  { taken: false, user: { id: '19', name: 'John Doe' }, seatNumber: '5C' },
  { taken: false, user: { id: '20', name: 'John Doe' }, seatNumber: '5D' },
  { taken: false, user: { id: '21', name: 'John Doe' }, seatNumber: '6A' },
  { taken: false, user: { id: '22', name: 'John Doe' }, seatNumber: '6B' },
  { taken: false, user: { id: '23', name: 'John Doe' }, seatNumber: '6C' },
  { taken: false, user: { id: '24', name: 'John Doe' }, seatNumber: '6D' },
  { taken: false, user: { id: '25', name: 'John Doe' }, seatNumber: '7A' },
  { taken: false, user: { id: '26', name: 'John Doe' }, seatNumber: '7B' },
  { taken: false, user: { id: '27', name: 'John Doe' }, seatNumber: '7C' },
  { taken: false, user: { id: '28', name: 'John Doe' }, seatNumber: '7D' },
];

export const MOCK_MENUS: Menu[] = [
  {
    id: '1',
    name: 'Standard',
    createdAt: '2023-07-21T10:00:00',
    type: 'standard',
    meals: [
      {
        available: true,
        id: '1',
        name: 'Chicken with rice',
        description: 'Chicken with rice and vegetables',
        type: 'standard',
        sequenceId: '1',
      },
      {
        available: true,
        id: '2',
        name: 'Pasta with tomato sauce',
        description: 'Pasta with tomato sauce and parmesan',
        type: 'standard',
        sequenceId: '2',
      },
      {
        available: true,
        id: '3',
        name: 'Salad with chicken',
        description: 'Salad with chicken and vegetables',
        type: 'standard',
        sequenceId: '3',
      },
      {
        available: true,
        id: '4',
        name: 'Salad with salmon',
        description: 'Salad with salmon and vegetables',
        type: 'standard',
        sequenceId: '4',
      },
    ],
  },
  {
    id: '2',
    name: 'Vegetarian',
    createdAt: '2023-07-21T10:00:00',
    type: 'vegetarian',
    meals: [
      {
        available: true,
        id: '5',
        name: 'Pasta with tomato sauce',
        description: 'Pasta with tomato sauce and parmesan',
        type: 'vegetarian',
        sequenceId: '1',
      },
      {
        available: true,
        id: '6',
        name: 'Salad with tofu',
        description: 'Salad with tofu and vegetables',
        type: 'vegetarian',
        sequenceId: '2',
      },
      {
        available: true,
        id: '7',
        name: 'Salad with salmon',
        description: 'Salad with salmon and vegetables',
        type: 'vegetarian',
        sequenceId: '3',
      },
    ],
  },
  {
    id: '3',
    name: 'Gluten-free',
    createdAt: '2023-07-21T10:00:00',
    type: 'gluten-free',
    meals: [
      {
        available: true,
        id: '8',
        name: 'Chicken with rice',
        description: 'Chicken with rice and vegetables',
        type: 'gluten-free',
        sequenceId: '1',
      },
      {
        available: true,
        id: '9',
        name: 'Salad with chicken',
        description: 'Salad with chicken and vegetables',
        type: 'gluten-free',
        sequenceId: '2',
      },
      {
        available: true,
        id: '10',
        name: 'Salad with salmon',
        description: 'Salad with salmon and vegetables',
        type: 'gluten-free',
        sequenceId: '3',
      },
    ],
  },
  {
    id: '4',
    name: 'Vegan',
    createdAt: '2023-07-21T10:00:00',
    type: 'vegan',
    meals: [
      {
        available: true,
        id: '11',
        name: 'Pasta with tomato sauce',
        description: 'Pasta with tomato sauce and parmesan',
        type: 'vegan',
        sequenceId: '1',
      },
      {
        available: true,
        id: '12',
        name: 'Salad with tofu',
        description: 'Salad with tofu and vegetables',
        type: 'vegan',
        sequenceId: '2',
      },
    ],
  },
];
