import { AxiosResponse } from 'axios';
import { MembershipRole, OrganizationKind } from 'domain/types';
import { Member as MembershipView } from 'members/types';

export enum CompanyColumn {
  ActiveUntil = 'Active until',
  Address = 'Address',
  Name = 'Name',
  No = 'No.',
  Payment = 'Payment',
  TaxID = 'Tax number',
}

export enum CompanyModalName {
  AddCompany = 'AddCompany',
  AddUserToCompany = 'AddUserToCompany',
  DeleteCompany = 'DeleteCompany',
  EditCompany = 'EditCompany',
  TableColumnSelection = 'TableColumnSelection',
}

export interface CompanySearchValues extends Record<string, string> {
  organization: string;
}

export interface RawCompany {
  activeUntil: string;
  address: string;
  archivedAt: string | undefined;
  createdAt: string;
  id: string;
  kind: OrganizationKind;
  membershipViews: MembershipView[];
  modifiedAt: string | undefined;
  name: string;
  sequenceId: string;
  status: string | undefined;
  taxId: string;
}

export interface Company extends RawCompany {
  activeUntil: string;
  isActive: boolean;
}

export interface Member {
  archivedAt: string | null;
  createdAt: string;
  id: string;
  modifiedAt: string;
  organizationId: string;
  role: MembershipRole;
  userId: string;
}

export interface CompaniesRawData {
  count: number;
  results: RawCompany[];
}

export type CompanyDto = {
  name: string;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerPhone: string;
};

export type UpdateCompanyDto = {
  address: string | null;
  name: string;
  taxId: string | null;
};

export type CreateMembershipDto = {
  isManagingCharters: boolean;
  isManagingFinancial: boolean;
  monthlyCharterLimit: number | null;
  organizationId: string;
  role: MembershipRole;
  userId: string;
};

export type CompanyReturn = Promise<AxiosResponse<RawCompany>>;
export type FetchCompaniesReturn = Promise<AxiosResponse<CompaniesRawData>>;
export type MemberReturn = Promise<AxiosResponse<Member>>;
export type FetchMembersReturn = Promise<AxiosResponse<Member[]>>;
export type DeleteCompanyReturn = Promise<AxiosResponse<void>>;
