import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import FlightInfo from 'flights/flightInfo';

const ANCESTORS = [{ title: 'Flights', to: paths.corporateFlights('planned') }];

const CorporateFlightDetails = (): JSX.Element | null => {
  const { pnrCode } = useParams();

  if (!pnrCode) return null;

  const { corporateFlightsStore } = useStores();
  const { fetchPlannedFlight, isLoadingFlightDetails, resetSelectedFlightDetails, selectedFlightDetails } =
    corporateFlightsStore;

  useEffect(() => {
    fetchPlannedFlight(pnrCode);

    return () => {
      resetSelectedFlightDetails();
    };
  }, []);

  return <FlightInfo ancestors={ANCESTORS} flightDetails={selectedFlightDetails} loading={isLoadingFlightDetails} />;
};

export default observer(CorporateFlightDetails);
