export const formValidationMessages: Record<string, string> = {
  COMPANY_NAME_REQUIRED: 'Company name is required',
  DATE_OF_BIRTH_REQUIRED: 'Date of birth is required',
  EMAIL_INVALID: 'Invalid email format',
  EMAIL_REQUIRED: 'Email is required',
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  NAME_REQUIRED: 'Name is required',
  OWNER_FIRST_NAME_REQUIRED: 'Owner first name is required',
  OWNER_LAST_NAME_REQUIRED: 'Owner last name is required',
  PASSWORD_REQUIRED: 'Password is required',
  PHONE_INVALID: 'Phone no. must start with a (+), followed by a CC, e.g.: +14123456789',
  PHONE_REQUIRED: 'Phone is required',
  ROLE_REQUIRED: 'Role is required',
};
