import React from 'react';
import { paths } from 'app/routes/paths.const';
import FlightDetailsHeader from 'flights/flightDetailsHeader';
import { FlightPassenger, Booking } from 'flights/types';
import Button from 'theme/button';
import { DATE_FORMAT, formatDate } from 'utils/helpers';
import styles from './BookingRow.module.scss';

interface PassengerRowProps {
  passenger: FlightPassenger;
}

const PassengerRow = ({ passenger }: PassengerRowProps): JSX.Element => {
  const { name, seat } = passenger;

  return (
    <tr>
      <td className={styles.passengersName}>{name}</td>
      <td className="text">
        {seat.number}, {seat.location}
      </td>
    </tr>
  );
};

interface BookingRowProps {
  booking: Booking;
}

const BookingRow = ({ booking }: BookingRowProps): JSX.Element => {
  const { bookedBy, departureDate, passengers, pnrCode } = booking;

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.headerTop}>
          <span>{formatDate(departureDate, DATE_FORMAT.DAY_FULL_ALT)}</span>
          <span>
            Booked by user <strong>{bookedBy}</strong>
          </span>
        </div>
        <FlightDetailsHeader flight={booking} />
      </header>
      <div className={styles.content}>
        <div className={styles.passengers}>
          <span className="text-small">Company's passengers:</span>
          <table className={styles.passengersList}>
            <tbody>
              {passengers.map((p) => (
                <PassengerRow key={p.id} passenger={p} />
              ))}
            </tbody>
          </table>
        </div>
        <Button to={paths.corporateFlightDetails(pnrCode)} text="Details" className={styles.detailsLink} />
      </div>
    </div>
  );
};

export default BookingRow;
