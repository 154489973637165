import { MembershipRole } from 'domain/types';
import Tag, { TagColor } from 'theme/tag';

const getManagerTagColor = (isManagingCharters?: boolean, isManagingFinancial?: boolean): TagColor => {
  if (isManagingCharters && isManagingFinancial) return TagColor.Orange;
  if (isManagingCharters) return TagColor.Gold;
  if (isManagingFinancial) return TagColor.OrangeAlt;
  else return TagColor.Orange;
};

const getMembershipTagColor = (
  userRole: MembershipRole,
  isManagingCharters?: boolean,
  isManagingFinancial?: boolean
): TagColor => {
  switch (userRole) {
    case MembershipRole.Owner:
      return TagColor.Green;
    case MembershipRole.Manager:
      return getManagerTagColor(isManagingCharters, isManagingFinancial);
    case MembershipRole.Guest:
      return TagColor.Sea;
    case MembershipRole.Employee:
    default:
      return TagColor.Default;
  }
};

type MembershipTagProps = {
  className?: string;
  label: string | MembershipRole;
  isManagingCharters?: boolean;
  isManagingFinancial?: boolean;
  userRole: MembershipRole;
};

const MembershipTag = ({
  className = '',
  label,
  userRole,
  isManagingFinancial,
  isManagingCharters,
}: MembershipTagProps): JSX.Element => {
  return (
    <Tag
      text={label}
      className={className}
      color={getMembershipTagColor(userRole, isManagingCharters, isManagingFinancial)}
    />
  );
};

export default MembershipTag;
