import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import Tooltip from 'theme/tooltip';
import styles from './TableItemName.module.scss';

interface TableItemNameProps {
  active?: boolean;
  link?: string;
  oneLine?: boolean;
  subtitle?: string;
  title: string;
  tooltipTexts?: string[];
}

const MAX_LENGTH = 30;

const TableItemName = ({
  active,
  link = '',
  oneLine = false,
  subtitle,
  title,
  tooltipTexts,
}: TableItemNameProps): JSX.Element => {
  const displayedTitle = title.length > MAX_LENGTH ? `${title.slice(0, MAX_LENGTH)}...` : title;

  const titleLink = (
    <Link className={clsx(styles.title, styles.titleLink, { [styles.titleOneLine]: oneLine })} to={link}>
      {displayedTitle}
    </Link>
  );

  const defaultTitle = <div className={clsx(styles.title, { [styles.titleOneLine]: oneLine })}>{displayedTitle}</div>;

  return (
    <div className={clsx(styles.root, { [styles.oneLine]: oneLine })}>
      <div className={styles.titleContainer}>
        {link ? titleLink : defaultTitle}
        {typeof active !== 'undefined' && (
          <div className={clsx(styles.icon, active ? styles.iconActive : styles.iconInactive)}>
            <Icon icon={active ? icons.tick : icons.blockedWhite} />
            {tooltipTexts && <Tooltip text={tooltipTexts[active ? 0 : 1]} />}
          </div>
        )}
      </div>
      {subtitle && (
        <span className={clsx(styles.subtitle, { [styles.subtitleFontIncreased]: oneLine })}>{subtitle}</span>
      )}
    </div>
  );
};

export default TableItemName;
