export interface Meal {
  available: boolean;
  description: string;
  id: string;
  name: string;
  sequenceId: string;
  type: string;
}

export interface Menu {
  createdAt: string;
  meals: Meal[];
  id: string;
  name: string;
  type: string;
}

export enum AdminFlightModalName {
  FlightDelete = 'FlightDelete',
  FlightEdit = 'FlightEdit',
  FlightUndoConfirmation = 'FlightUndoConfirmation',
  Menu = 'Menu',
}

export enum FlightDetailsTabDataType {
  Meals = 'Meals',
  Passengers = 'Passengers',
}

export interface MealSearchValues extends Record<string, string> {
  description: string;
  name: string;
}
