import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { Company, UpdateCompanyDto } from 'companies/types';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import { MembershipRole, Option, OrganizationKind } from 'domain/types';
import { MembershipOrganization } from 'members/types';
import { RawUser } from 'users/types';
import * as requests from './requests';
import { CorporateAdminModalName } from './types';

class CorporateAdminStore {
  rootStore: RootStore;

  corporateAdminUser: RawUser | undefined;
  corporateAdminDeposit: number | null = null;
  corporateAdminOrganization: Company | undefined;
  isLoadingCorporateAdminDeposit = false;
  isLoadingCorporateAdminUser = false;
  isLoadingCorporateAdminOrganization = false;
  isSubmitting = false;
  modalName: CorporateAdminModalName | null = null;
  selectedCorporateAdminOrganization: Company | undefined;
  selectedCorporateAdminOrganizationOption: Option = DEFAULT_SELECT_OPTION;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get corporateAdminRole(): MembershipRole {
    return (
      this.corporateAdminUser?.membershipViews.find(
        ({ organization }) => organization.kind === OrganizationKind.Corporate
      )?.role ?? MembershipRole.Guest
    );
  }

  get corporateAdminOrganizations(): MembershipOrganization[] {
    if (!this.corporateAdminUser) return [];

    return this.corporateAdminUser.membershipViews
      .filter(({ organization }) => organization.kind === OrganizationKind.Corporate)
      .map(({ organization }) => organization);
  }

  get corporateAdminOrganizationOptions(): Option[] {
    return this.corporateAdminOrganizations.map(({ id, name }) => ({ label: name, value: id }));
  }

  get defaultCorporateAdminOrganizationOption(): Option {
    if (!this.corporateAdminOrganizationOptions.length) return DEFAULT_SELECT_OPTION;
    return this.corporateAdminOrganizationOptions[0];
  }

  get currentContextOrganizationId(): string {
    if (!this.corporateAdminOrganization) return '';
    return this.corporateAdminOrganization.id;
  }

  setIsLoadingCorporateAdminUser = (isLoading: boolean): void => {
    this.isLoadingCorporateAdminUser = isLoading;
  };

  private fetchSelectedCorporateAdminOrganizationMembers = (): void | undefined => {
    if (!this.corporateAdminOrganization) return;
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    this.rootStore.membersStore
      .fetchMembers({
        companyIdParam: this.corporateAdminOrganization.id,
        pageSize: this.rootStore.membersStore.pageSize,
        pageNumber: this.rootStore.membersStore.pageNumber,
      })
      .catch(() => addToast(toastMessages.MEMBER.FETCH_LIST_ERROR));
  };

  fetchCorporateAdminDeposit = (organizationId: string): Promise<number> => {
    if (this.corporateAdminRole !== MembershipRole.Owner && this.corporateAdminRole !== MembershipRole.Manager)
      return Promise.reject();

    runInAction(() => (this.isLoadingCorporateAdminDeposit = true));

    return this.rootStore.usersStore
      .fetchOrganizationFee(organizationId)
      .then(({ data }) => runInAction(() => (this.corporateAdminDeposit = data.membershipFeeInCents)))
      .finally(() => runInAction(() => (this.isLoadingCorporateAdminDeposit = false)));
  };

  setCorporateAdminOrganization = (option: Option, onInit?: boolean): void => {
    this.selectedCorporateAdminOrganizationOption = option;

    this.fetchCorporateAdminOrganization(option.value);
    this.fetchCorporateAdminDeposit(option.value);

    if (!onInit) this.fetchSelectedCorporateAdminOrganizationMembers();
  };

  fetchCorporateAdminUser = (isOrganizationFetched = false): Promise<RawUser | void> => {
    if (!this.rootStore.authStore.token?.user.id) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingCorporateAdminUser = true));

    return requests
      .fetchCorporateAdminUser(this.rootStore.authStore.token?.user.id)
      .then(({ data }) => {
        const user = data.results[0];
        const userCorpOrgId =
          user.membershipViews.find(
            ({ role, organization }) =>
              role === MembershipRole.Owner && organization.kind === OrganizationKind.Corporate
          )?.organization.id ?? '';

        runInAction(() => (this.corporateAdminUser = data.results[0]));

        if (isOrganizationFetched) this.fetchCorporateAdminOrganization(userCorpOrgId);
        return user;
      })
      .catch(() => {
        addToast(toastMessages.USER.FETCH_ERROR);
      })
      .finally(() => runInAction(() => (this.isLoadingCorporateAdminUser = false)));
  };

  fetchCorporateAdminOrganization = (organizationId: string): Promise<Company | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingCorporateAdminOrganization = true));

    return requests
      .fetchCorporateAdminOrganization(organizationId)
      .then(({ data }) =>
        runInAction(() => (this.corporateAdminOrganization = this.rootStore.companiesStore.modelCompany(data)))
      )
      .catch(() => {
        addToast(toastMessages.COMPANY.FETCH_DETAILS_ERROR);
      })
      .finally(() => runInAction(() => (this.isLoadingCorporateAdminOrganization = false)));
  };

  updateCorporateAdminOrganization = (data: UpdateCompanyDto, organizationId: string): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateCorporateAdminOrganization(data, organizationId)
      .then(() => {
        this.fetchCorporateAdminOrganization(organizationId);
      })
      .finally(() => {
        runInAction(() => (this.isSubmitting = false));
      });
  };

  openModal = (): void => {
    this.selectedCorporateAdminOrganization = this.corporateAdminOrganization;
    this.modalName = CorporateAdminModalName.Edit;
  };

  closeModal = (): void => {
    this.selectedCorporateAdminOrganization = undefined;
    this.modalName = null;
  };
}

export default CorporateAdminStore;
