import React from 'react';
import clsx from 'clsx';
import { Option } from 'domain/types';
import { LoadingAirportsFlags } from 'flights/types';
import Autocomplete from 'theme/autocomplete';
import Icon, { icons } from 'theme/icon';
import styles from './FlightSearch.module.scss';

interface FlightSearchProps {
  className?: string;
  isStatic?: boolean;
  loadingAirports: LoadingAirportsFlags;
  onAutocompleteChange: (from: boolean) => (query: string) => Promise<Option[] | void>;
  onAutocompleteSelect: (from: boolean) => (option: Option) => void;
  swapValues: () => void;
  valueFrom: Option;
  valueTo: Option;
}

const FlightSearch = ({
  className = '',
  isStatic,
  loadingAirports,
  onAutocompleteChange,
  onAutocompleteSelect,
  swapValues,
  valueFrom,
  valueTo,
}: FlightSearchProps): JSX.Element => {
  const autocompleteCommonProps = {
    className: styles.autocomplete,
    isStatic,
    placeholder: 'Search location...',
  };

  return (
    <div className={clsx(styles.root, className)}>
      <Autocomplete
        label="From"
        loading={loadingAirports.from}
        value={valueFrom}
        onChange={onAutocompleteChange(true)}
        onSelect={onAutocompleteSelect(true)}
        {...autocompleteCommonProps}
      />
      <button className={styles.swapBtn} onClick={swapValues}>
        <Icon icon={icons.swap} />
      </button>
      <Autocomplete
        label="To"
        loading={loadingAirports.to}
        value={valueTo}
        onChange={onAutocompleteChange(false)}
        onSelect={onAutocompleteSelect(false)}
        {...autocompleteCommonProps}
      />
    </div>
  );
};

export default FlightSearch;
