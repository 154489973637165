import { ChangeEvent } from 'react';
import { Option } from 'domain/types';
import { LoadingAirportsFlags } from 'flights/types';

interface AutocompleteSettings {
  label: string;
  loading: boolean;
  onChange: (query: string) => Promise<Option[] | void>;
  onSelect: (option: Option) => void;
  placeholder: string;
  value: Option;
}

interface FlightAutocompleteSettings
  extends Omit<AutocompleteSettings, 'label' | 'loading' | 'value' | 'onChange' | 'onSelect'> {
  isStatic?: boolean;
  loadingAirports: LoadingAirportsFlags;
  onAutocompleteChange: (from: boolean) => (query: string) => Promise<Option[] | void>;
  onAutocompleteSelect: (from: boolean) => (option: Option) => void;
  swapValues: () => void;
  valueFrom: Option;
  valueTo: Option;
}

export enum DateType {
  End = 'end',
  Start = 'start',
}

export enum FilterType {
  Autocomplete = 'autocomplete',
  Dates = 'dates',
  FlightSearch = 'flightsearch',
  Multiselect = 'multiselect',
  Search = 'search',
}

export interface Filter {
  autocompleteSettings?: AutocompleteSettings;
  clear?: () => void;
  endDate?: Date;
  flightAutocompleteSettings?: FlightAutocompleteSettings;
  isSearchXL?: boolean;
  label: string;
  minStartDate?: Date;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (date: Date, type: DateType) => void;
  onSelect?: (option: Option) => void;
  options?: Option[];
  placeholder?: string;
  selectedOptions?: Option[];
  startDate?: Date;
  type: FilterType;
  value?: string;
}
