import { useState, useEffect, useRef, KeyboardEvent } from 'react';
import clsx from 'clsx';
import { Option } from 'domain/types';
import { MembershipRole } from 'domain/types';
import Checkbox from 'theme/checkbox';
import Icon, { icons } from 'theme/icon';
import Tag, { TagColor } from 'theme/tag';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import styles from './MultiSelect.module.scss';

type MultiSelectProps = {
  className?: string;
  handleOptions: ((option: Option) => void) | undefined;
  label?: string;
  name?: string;
  options: Option[];
  selectedOptions: Option[];
};

const MultiSelect = ({
  className = '',
  handleOptions,
  label,
  name,
  options,
  selectedOptions,
}: MultiSelectProps): JSX.Element => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

  const rootRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<HTMLElement[]>([]);

  useOnClickOutside({ ref: rootRef, handler: () => setIsDropdownOpen(false) });

  const clickOptionBox = (): void | undefined => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (o: Option): void => {
    handleOptions?.(o);
    if (options.length < 3) setIsDropdownOpen(false);
  };

  const getTagColor = ({ value }: Option): TagColor | undefined => {
    switch (value) {
      case 'verified':
      case 'confirmed':
      case '3':
      case MembershipRole.Owner:
        return TagColor.Green;
      case 'unverified':
      case 'unconfirmed':
        return TagColor.Red;
      case 'premium':
        return TagColor.Brown;
      case 'free':
      case '1':
        return TagColor.Blue;
      case MembershipRole.Manager:
      case '2':
        return TagColor.Orange;
      case MembershipRole.Guest:
        return TagColor.Sea;
      case MembershipRole.Employee:
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (isDropdownOpen && optionRefs.current.length > 0) optionRefs.current[focusedOptionIndex]?.focus();
  }, [isDropdownOpen, focusedOptionIndex]);

  const optionList = selectedOptions
    .filter((sc, index) => index === 0)
    .map((sc) => (
      <div key={sc.label} className={styles.selectedOptionLabel}>
        <span className={styles.optionLabel}>
          {sc.label}
          {selectedOptions.length > 1 ? ` +${selectedOptions.length - 1}` : null}
        </span>
      </div>
    ));

  const handleKeyDown = (e: React.KeyboardEvent): void | undefined => {
    if (!isDropdownOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedOptionIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedOptionIndex !== -1) handleOptionClick(options[focusedOptionIndex]);
        break;
      case 'Escape':
        setIsDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  const handleBackspaceKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Backspace' && selectedOptions.length > 0)
      handleOptions?.(selectedOptions[selectedOptions.length - 1]);
  };

  const handleKeyDownOnSelectedOptionsBox = (e: KeyboardEvent): void => {
    if (e.key === 'Backspace') handleBackspaceKeyDown(e);
    else handleKeyDown(e);
  };

  return (
    <div className={clsx(styles.root, className)} ref={rootRef}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <div
        className={styles.selectedOptionsBox}
        onClick={clickOptionBox}
        onKeyDown={handleKeyDownOnSelectedOptionsBox}
        tabIndex={0}
      >
        <div>{selectedOptions.length ? optionList : <span className={styles.dropdownPlaceholder}>Select</span>}</div>
        <Icon icon={icons.dropdown} className={clsx(styles.arrowIcon, { [styles.arrowIconClosed]: !isDropdownOpen })} />
      </div>

      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownRef} onKeyDown={handleKeyDown}>
          <ul>
            {options.map((o, i) => (
              <li
                key={o.label}
                onClick={() => handleOptionClick(o)}
                className={clsx(styles.dropdownOption, { [styles.dropdownOptionFocused]: i === focusedOptionIndex })}
              >
                <div className={styles.dropdownContainer}>
                  <Checkbox className={styles.checkbox} checked={selectedOptions.some((so) => so.value === o.value)} />
                  <Tag text={o.label} color={getTagColor(o)} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
