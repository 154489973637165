import { useState, useEffect, useMemo, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import options from 'domain/options';
import { FormValidation, GenderOption } from 'domain/types';
import { FlightGuestModalName } from 'guests/types';
import { FlightGuestCreateDto, FlightGuestUpdateDto } from 'guests/types';
import Button from 'theme/button';
import Datepicker from 'theme/datepicker';
import Modal from 'theme/modal';
import RadioOptions from 'theme/radioOptions';
import UserDetailsFormTable from 'theme/userDetailsFormTable';
import { DATE_FORMAT, formatDate } from 'utils/helpers';
import { useFormValidation, useUserDetailsForm } from 'utils/hooks';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';

const FORM_VALIDATIONS: FormValidation = {
  dateOfBirthRequired: '',
  emailInvalid: '',
  firstNameRequired: '',
  lastNameRequired: '',
  phoneInvalid: '',
};

function GuestFormModal(): JSX.Element {
  const { guestsStore, corporateAdminStore } = useStores();
  const { closeModal, createGuest, modalName, isSubmitting, selectedGuest, updateGuest } = guestsStore;
  const { selectedCorporateAdminOrganizationOption } = corporateAdminStore;

  const [birthDate, setBirthDate] = useState<Date>();
  const [gender, setGender] = useState<GenderOption>(options.gender[0]);

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const handleDateOfBirthChange = (value: Date | undefined): void => {
    cleanValidationErrors(['dateOfBirthRequired']);
    setBirthDate(value);
  };

  const handleGenderChange = (value: GenderOption): void => {
    setGender(value);
  };

  const birthDateAndGenderRows = (
    <>
      <tr>
        <th>DOB</th>
        <td data-th="DOB">
          <Datepicker
            className="table-style-form-field"
            clear={() => handleDateOfBirthChange(undefined)}
            handleDate={handleDateOfBirthChange}
            maxDate={new Date()}
            error={validation.dateOfBirthRequired}
            selectedDate={birthDate}
            inTable
            showYearDropdown
            showMonthDropdown
          />
        </td>
      </tr>
      <tr>
        <th className="aligned-top">Gender</th>
        <td data-th="Gender">
          <RadioOptions options={options.gender} selected={gender} onChange={handleGenderChange} />
        </td>
      </tr>
    </>
  );

  const { isFormSubmitDisabled, resetFormValues, userFormData } = useUserDetailsForm({
    cleanValidationErrors,
    selected: selectedGuest,
    validation,
  });
  const { email, firstName, lastName, phone } = userFormData;

  const onModalClose = (): void => {
    closeModal();
    setBirthDate(undefined);
    setGender(options.gender[0]);
    resetValidation();
    resetFormValues();
  };

  const validateOnSubmit = (): void => {
    handleValidation('firstNameRequired', formValidationMessages.FIRST_NAME_REQUIRED, !firstName);
    handleValidation('lastNameRequired', formValidationMessages.LAST_NAME_REQUIRED, !lastName);
    handleValidation('dateOfBirthRequired', formValidationMessages.DATE_OF_BIRTH_REQUIRED, !birthDate);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, !!phone && !PHONE_REGEX.test(phone));
  };

  const isAddingGuestBlocked = useMemo(() => isFormSubmitDisabled || !birthDate, [isFormSubmitDisabled, birthDate]);

  const structureGuestDto = <T extends FlightGuestCreateDto | FlightGuestUpdateDto>(): T => {
    const defaultDto = {
      birthDate: birthDate ? formatDate(birthDate, DATE_FORMAT.DAY_BE_FORMAT) : '',
      contactRequestUrl: null,
      email: email || null,
      firstName,
      lastName,
      phone: phone || null,
      sex: gender.value,
    };

    return modalName === FlightGuestModalName.Add
      ? ({ ...defaultDto, organizationId: selectedCorporateAdminOrganizationOption.value } as T)
      : ({ ...defaultDto, shouldResendMail: true } as T);
  };

  const addGuest = (): void | undefined => {
    validateOnSubmit();
    if (isAddingGuestBlocked || !selectedCorporateAdminOrganizationOption.value) return;

    createGuest(structureGuestDto<FlightGuestCreateDto>()).then(() => onModalClose());
  };

  const editGuest = (): void | undefined => {
    validateOnSubmit();
    if (isAddingGuestBlocked || !selectedCorporateAdminOrganizationOption.value) return;

    updateGuest(structureGuestDto<FlightGuestUpdateDto>()).then(() => onModalClose());
  };

  const submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    modalName === FlightGuestModalName.Add ? addGuest() : editGuest();
  };

  const isOpen = modalName === FlightGuestModalName.Add || modalName === FlightGuestModalName.Edit;
  const modalTitle = modalName === FlightGuestModalName.Add ? 'Add guest' : 'Edit guest';

  useEffect(() => {
    if (selectedGuest) {
      setBirthDate(selectedGuest.birthDate ? new Date(selectedGuest.birthDate) : undefined);
      setGender(options.gender.find(({ value }) => value === selectedGuest.sex) || options.gender[0]);
    }
  }, [selectedGuest]);

  return (
    <Modal onClose={onModalClose} isOpen={isOpen} title={modalTitle}>
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <UserDetailsFormTable
          userFormData={userFormData}
          tableChildren={birthDateAndGenderRows}
          validation={validation}
        />
        <div>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(GuestFormModal);
