import { ChangeEvent } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { AdminRole } from 'auth/types';
import { DEFAULT_PAGE_SIZE } from 'domain/constants';
import options from 'domain/options';
import { Option } from 'domain/types';
import { SortColumn, SortDirection, SortingProps } from 'domain/types';
import { Filter, FilterType } from 'theme/searchPanel';
import { createUser, updateUser } from 'users/requests';
import { UserDto } from 'users/types';
import * as requests from './requests';
import { RawTeamMember, TeamMember, TeamMemberDto, TeamModalName, TeamMemberSearchValues } from './types';

class TeamStore {
  rootStore: RootStore;

  isLoadingTeamMember = false;
  isLoadingTeamMembers = false;
  isSubmitting = false;
  modalName: TeamModalName | null = null;
  pageNumber = 1;
  pageSize = DEFAULT_PAGE_SIZE;
  roleOptions: Option[] = [];
  searchValues: TeamMemberSearchValues = {
    user: '',
  };
  selectedTeamMember: TeamMember | undefined;
  sortColumn: SortColumn = SortColumn.CreatedAt;
  sortDirection: SortDirection = SortDirection.Desc;
  teamMember: TeamMember | undefined = undefined;
  teamMembers: TeamMember[] = [];
  teamMembersCount = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get sortingProps(): SortingProps {
    return { sortColumn: this.sortColumn, sortDirection: this.sortDirection, updateSort: this.updateSort };
  }

  get filters(): Filter[] {
    return [
      {
        label: 'Role',
        name: 'role',
        onSelect: this.setRoleOptions,
        options: options.entourageTeamMember,
        selectedOptions: this.roleOptions,
        type: FilterType.Multiselect,
      },
      {
        isSearchXL: true,
        label: 'Name / email / phone',
        name: 'user',
        onChange: (e: ChangeEvent<HTMLInputElement>) => this.onSearchChange(e, 'user'),
        placeholder: 'Type phrase...',
        type: FilterType.Search,
        value: this.searchValues.user,
      },
    ];
  }

  private setSortColumn = (sortColumn: SortColumn): void => {
    this.sortColumn = sortColumn;
  };

  private setSortDirection = (sortDirection: SortDirection): void => {
    this.sortDirection = sortDirection;
  };

  private setSearchValues = (key: string, value: string): void => {
    this.searchValues[key] = value;
  };

  private setRoleOptions = (option: Option): void => {
    this.roleOptions = this.rootStore.updateOptions(this.roleOptions, option);
  };

  private onSearchChange = (event: ChangeEvent<HTMLInputElement>, key: string): void => {
    this.setSearchValues(key, event.target.value);
  };

  private defineTeamMemberRole = (member: RawTeamMember): AdminRole => {
    if (member.isSuperAdmin) return AdminRole.SuperAdmin;
    else if (member.writePermissions.length > 0) return AdminRole.WriteAdmin;
    else return AdminRole.ViewOnlyAdmin;
  };

  private addTeamMemberPermissionLabel = (permission: string): string =>
    options.entourageTeamMemberPermissionKey.find((o) => o.value === permission)?.label ?? '';

  private modelTeamMember = (member: RawTeamMember): TeamMember => ({
    ...member,
    name: `${member.firstName} ${member.lastName}`,
    role: this.defineTeamMemberRole(member),
    viewPermissionLabels: member.viewPermissions.map(this.addTeamMemberPermissionLabel),
    writePermissionLabels: member.writePermissions.map(this.addTeamMemberPermissionLabel),
  });

  fetchTeamMember = (memberId: string): Promise<void> => {
    runInAction(() => (this.isLoadingTeamMember = true));

    return requests
      .fetchTeamMembers({
        id: memberId,
        pageSize: 1,
        pageNumber: 1,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.teamMember = this.modelTeamMember(data.results[0]);
          this.teamMembersCount = data.count;
        });
      })
      .catch(() => {
        const { addToast, toastMessages } = this.rootStore.toastsStore;
        addToast(toastMessages.TEAM_MEMBER.FETCH_DETAILS_ERROR);
      })
      .finally(() => runInAction(() => (this.isLoadingTeamMember = false)));
  };

  fetchTeamMembers = (pageSize: number, pageNumber: number, resetPreviousData?: boolean): Promise<void> => {
    runInAction(() => (this.isLoadingTeamMembers = true));
    if (resetPreviousData) this.pageNumber = 1;

    return requests
      .fetchTeamMembers({
        pageNumber,
        pageSize,
        roles: this.roleOptions.map(({ value }) => value),
        searchValues: this.searchValues,
        sortBy: this.sortColumn + this.sortDirection,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.teamMembers = data.results.map((member) => this.modelTeamMember(member));
          this.teamMembersCount = data.count;
        });
      })
      .catch(() => {
        const { addToast, toastMessages } = this.rootStore.toastsStore;
        addToast(toastMessages.TEAM_MEMBER.FETCH_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingTeamMembers = false));
      });
  };

  submitTeamMember = async (
    userData: UserDto,
    permissionsData: TeamMemberDto,
    userId: string | undefined
  ): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    const req = userId ? updateUser(userId, userData) : createUser(userData);

    try {
      const { data } = await req;
      await this.updateTeamMember(data.id, permissionsData, !!userId);
      runInAction(() => (this.isSubmitting = false));
    } catch (error) {
      runInAction(() => (this.isSubmitting = false));
      throw error;
    }
  };

  updateTeamMember = (teamMemberId: string, data: TeamMemberDto, isTeamMemberDetailsView?: boolean): Promise<void> => {
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateTeamMember(teamMemberId, data)
      .then(({ data: member }) => {
        isTeamMemberDetailsView
          ? (this.teamMember = this.modelTeamMember(member))
          : this.fetchTeamMembers(this.pageSize, this.pageNumber);
      })
      .finally(() => {
        runInAction(() => (this.isSubmitting = false));
      });
  };

  resetTeamMember = (): void => {
    this.teamMember = undefined;
  };

  removeTeamMember = (): Promise<void> => {
    if (!this.selectedTeamMember) return Promise.reject();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .updateTeamMember(this.selectedTeamMember.id, {
        isSuperAdmin: false,
        viewPermissions: [],
        writePermissions: [],
      })
      .then(() => {
        this.fetchTeamMembers(this.pageSize, this.pageNumber);
        addToast(toastMessages.TEAM_MEMBER.DELETE_SUCCESS, 'success');
        this.closeModal();
      })
      .catch(() => addToast(toastMessages.TEAM_MEMBER.DELETE_ERROR))
      .finally(() => runInAction(() => (this.isSubmitting = false)));
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) {
      this.pageNumber = newPageNumber;
      this.fetchTeamMembers(this.pageSize, newPageNumber);
    }
  };

  changePageSize = (newPageSize: number): void => {
    if (newPageSize !== this.pageSize) {
      this.pageSize = newPageSize;
      this.fetchTeamMembers(newPageSize, 1, true);
    }
  };

  updateSort = (column: SortColumn): void => {
    const dir = this.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;

    this.setSortColumn(column);
    this.setSortDirection(dir);
    this.fetchTeamMembers(this.pageSize, 1, true);
  };

  private resetPagination = (): void => {
    this.pageNumber = 1;
    this.pageSize = DEFAULT_PAGE_SIZE;
  };

  private resetSortBy = (): void => {
    this.sortColumn = SortColumn.CreatedAt;
    this.sortDirection = SortDirection.Desc;
  };

  updateFilters = (): void => {
    this.fetchTeamMembers(this.pageSize, 1, true);
  };

  private resetFilters = (): void => {
    this.roleOptions = [];
    this.searchValues = { user: '' };
  };

  clearFilters = (): Promise<void> => {
    runInAction(() => (this.isLoadingTeamMembers = true));
    this.pageNumber = 1;
    this.resetFilters();

    return requests
      .fetchTeamMembers({ pageSize: this.pageSize, pageNumber: 1, sortBy: this.sortColumn + this.sortDirection })
      .then(({ data }) => {
        runInAction(() => {
          this.teamMembers = data.results.map(this.modelTeamMember);
          this.teamMembersCount = data.count;
        });
      })
      .finally(() => runInAction(() => (this.isLoadingTeamMembers = false)));
  };

  resetFiltersSortAndPagination = (): void => {
    this.resetPagination();
    this.resetSortBy();
    this.resetFilters();
  };

  openModal = (modalName: TeamModalName, member?: TeamMember): void => {
    const isSelectingTeamMember =
      modalName === TeamModalName.EditTeamMember || modalName === TeamModalName.RemoveTeamMember;

    if (isSelectingTeamMember && member) this.selectedTeamMember = member;
    this.modalName = modalName;
  };

  closeModal = (): void => {
    this.selectedTeamMember = undefined;
    this.modalName = null;
  };
}

export default TeamStore;
