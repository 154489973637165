import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './TableCard.module.scss';

interface TableCardProps {
  children: ReactNode | ReactNode[];
  className?: string;
  headerButton?: ReactNode;
  title: string;
  vertical?: boolean;
}

const TableCard = ({
  children,
  className = '',
  headerButton,
  title,
  vertical = false,
}: TableCardProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, 'card', className, { [styles.vertical]: vertical })}>
      <header className={styles.header}>
        <h2>{title}</h2>
        {headerButton}
      </header>
      <div>{children}</div>
    </div>
  );
};

export default TableCard;
