import { useMemo, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { REDIRECT_LINKS } from 'domain/constants';
import options from 'domain/options';
import { FormValidation, MembershipRole } from 'domain/types';
import { MemberModalName } from 'members/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import UserDetailsFormTable from 'theme/userDetailsFormTable';
import { useFormValidation, useMemberRole, useUserDetailsForm } from 'utils/hooks';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';
import MemberRoleField from '../memberRoleField';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
  firstNameRequired: '',
  lastNameRequired: '',
  phoneRequired: '',
  phoneInvalid: '',
};

function InviteMemberModal(): JSX.Element {
  const { corporateAdminStore, membersStore, toastsStore } = useStores();
  const { corporateAdminOrganization, corporateAdminRole } = corporateAdminStore;
  const { closeModal, inviteMember, isSubmitting, modalName } = membersStore;
  const { addToast, toastMessages } = toastsStore;

  const { cleanValidationErrors, formValidationMessages, handleValidation, resetValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const { memberRoleFieldProps } = useMemberRole(
    corporateAdminRole === MembershipRole.Owner ? options.invitableRole : options.invitableRoleForManager
  );
  const { handleRoleChange, isManagingCharters, isManagingFinancial, monthlyCharterLimit, role } = memberRoleFieldProps;

  const memberRoleField = <MemberRoleField {...memberRoleFieldProps} options={options.role} />;

  const { isFormSubmitDisabled, resetFormValues, userFormData } = useUserDetailsForm({
    cleanValidationErrors,
    validation,
  });
  const { email, firstName, lastName, phone } = userFormData;

  const validateOnSubmit = (): void => {
    handleValidation('firstNameRequired', formValidationMessages.FIRST_NAME_REQUIRED, !firstName);
    handleValidation('lastNameRequired', formValidationMessages.LAST_NAME_REQUIRED, !lastName);
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation('phoneRequired', formValidationMessages.PHONE_REQUIRED, !phone);
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, !!phone && !PHONE_REGEX.test(phone));
  };

  const onModalClose = (): void => {
    closeModal();
    handleRoleChange(options.invitableRole[0]);
    resetFormValues();
    resetValidation();
  };

  const createNewMembership = (): void => {
    validateOnSubmit();
    if (isFormSubmitDisabled || !corporateAdminOrganization) return;

    inviteMember({
      organizationId: corporateAdminOrganization.id,
      firstName,
      lastName,
      userEmail: email,
      userPhone: phone,
      role: role.value as MembershipRole,
      monthlyCharterLimit: role.value === MembershipRole.Employee ? monthlyCharterLimit : null,
      ...(role.value === MembershipRole.Manager && {
        isManagingFinancial,
        isManagingCharters,
      }),
      ...REDIRECT_LINKS,
    })
      .then(() => {
        onModalClose();
        addToast(toastMessages.MEMBER.INVITE_SUCCESS, 'success');
      })
      .catch(() => addToast(toastMessages.MEMBER.INVITE_ERROR));
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    createNewMembership();
  };

  const isSubmitBtnDisabled = useMemo(
    () => isSubmitting || (role.value === MembershipRole.Manager && !isManagingCharters && !isManagingFinancial),
    [isSubmitting, role.value, isManagingCharters, isManagingFinancial]
  );

  return (
    <Modal onClose={onModalClose} isOpen={modalName === MemberModalName.InviteMember} title="Add member">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <UserDetailsFormTable userFormData={userFormData} tableChildren={memberRoleField} validation={validation} />
        <div>
          <span className="modal-disclaimer">User will receive an invitation to download the app</span>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} type="submit" disabled={isSubmitBtnDisabled} />
        </div>
      </form>
    </Modal>
  );
}

export default observer(InviteMemberModal);
