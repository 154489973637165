import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import ReactRemoveScroll from 'react-remove-scroll/dist/es5/Combination';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import styles from './Modal.module.scss';

interface ModalProps {
  children: ReactNode;
  className?: string;
  contentClass?: string;
  contentOverflow?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
}

function Modal({
  children,
  className,
  contentClass = '',
  contentOverflow = false,
  isOpen = true,
  onClose,
  title,
}: ModalProps): JSX.Element | null {
  useEffect(() => {
    const closeModalOnEscape = (e: globalThis.KeyboardEvent): void => {
      if (e.key === 'Escape') onClose?.();
    };

    document.addEventListener('keydown', closeModalOnEscape);

    return () => {
      document.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  if (isOpen) {
    const component = (
      <ReactFocusLock autoFocus={false}>
        <ReactRemoveScroll>
          <div className={styles.overlay} onClick={() => onClose?.()}>
            <div
              className={clsx(styles.modal, className)}
              aria-modal="true"
              role="dialog"
              onClick={(e) => e.stopPropagation()}
            >
              {title && <h2 className={styles.modalTitle}>{title}</h2>}
              <div className={clsx(styles.content, contentClass, { [styles.contentOverflow]: contentOverflow })}>
                {children}
              </div>
              <Icon icon={icons.cross} onClick={() => onClose?.()} className={styles.closeBtn} width={20} height={20} />
            </div>
          </div>
        </ReactRemoveScroll>
      </ReactFocusLock>
    );

    return createPortal(component, document.body);
  }

  return null;
}

export default Modal;
