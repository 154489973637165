import api from 'api';
import { searchObjectToArray } from 'utils/helpers';
import {
  FlightDeleteGuestReturn,
  FetchFlightGuestsParams,
  FlightGuestCreateReturn,
  FlightGuestCreateDto,
  FlightGuestSearchValues,
  FlightGuestsReturn,
  FlightGuestUpdateReturn,
  FlightGuestUpdateDto,
} from './types';

export const fetchGuests = ({
  contactId,
  organizationId,
  pageNumber,
  pageSize,
  searchValues,
  sortBy,
}: FetchFlightGuestsParams): FlightGuestsReturn => {
  return api.get('/contacts', {
    params: {
      organizationId,
      pageNumber,
      pageSize,
      sort: sortBy,
      ...(contactId && { contactId }),
      ...(searchValues && { ...searchObjectToArray<FlightGuestSearchValues>(searchValues) }),
    },
  });
};

export const fetchGuest = (organizationId: string, guestId: string): FlightGuestsReturn => {
  return api.get('/contacts', { params: { organizationId, guestId, pageNumber: 1, pageSize: 1 } });
};

export const createGuest = (data: FlightGuestCreateDto): FlightGuestCreateReturn => {
  return api.post('/contacts', data);
};

export const updateGuest = (guestId: string, data: FlightGuestUpdateDto): FlightGuestUpdateReturn => {
  return api.patch(`/contacts/${guestId}`, data);
};

export const deleteGuest = (guestId: string): FlightDeleteGuestReturn => {
  return api.delete(`/contacts/${guestId}`);
};
