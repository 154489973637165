import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import Wallet from 'corporateAdmin/wallet';
import { SUBSCRIPTION_STATUSES } from 'domain/constants';
import { TableAction } from 'domain/types';
import Breadcrumbs from 'theme/breadcrumbs';
import FileUpload from 'theme/fileUpload';
import IconButton from 'theme/iconButton';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import TableCard from 'theme/tableCard';
import Tabs from 'theme/tabs';
import VerificationTag from 'theme/verificationTag';
import { useTitle } from 'utils/hooks';
import styles from './Account.module.scss';
import EditCorporateOrganizationModal from './editCorporateOrganizationModal';

const TABS = [
  {
    value: 'wallet',
    label: 'Wallet',
    element: <Wallet />,
  },
  // { value: 'payments', label: 'Payment history', element: <div>Payments</div> },
];

const Account = (): JSX.Element | null => {
  const { companiesStore, corporateAdminStore } = useStores();
  const { uploadProgress } = companiesStore;
  const { corporateAdminOrganization, isLoadingCorporateAdminOrganization, openModal } = corporateAdminStore;

  useTitle('Account');

  const handleFileUpload = (file: File | null): Promise<void> => {
    if (!file) return Promise.resolve();
    return Promise.resolve();

    // return uploadFile(user.id, file)
    //   .then(() => addToast(toastMessages.USER.ID_UPLOAD_SUCCESS, 'success'))
    //   .catch(() => addToast(toastMessages.USER.ID_UPLOAD_ERROR));
  };

  const renderContent = (): JSX.Element | null => {
    if (!corporateAdminOrganization) return null;
    const { address, taxId, isActive, activeUntil, name } = corporateAdminOrganization;

    return (
      <>
        <PageHeader title={name}>
          <IconButton action={openModal} text="Edit" type={TableAction.Edit} />
        </PageHeader>
        <div className={styles.content}>
          <TableCard title="Company information" className={styles.table} vertical>
            <table>
              <tbody>
                <tr>
                  <th>Address</th>
                  <td data-th="Address">{address}</td>
                </tr>
                <tr>
                  <th>Tax ID</th>
                  <td data-th="Tax ID">{taxId}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td data-th="Phone"></td>
                </tr>
                <tr>
                  <th>Subscription</th>
                  <td data-th="Subscription">
                    <VerificationTag labels={SUBSCRIPTION_STATUSES} verified={isActive} />
                    <span>valid till: {activeUntil}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableCard>
          <div className={clsx(styles.fileUpload, 'card')}>
            <FileUpload
              className={styles.fileUploadComponent}
              onFileUpload={handleFileUpload}
              text="Upload your logo file"
              loading={false}
              uploadProgress={uploadProgress}
              disabled={false}
              imageUrl=""
              removeImage={() => alert('remove')}
            />
          </div>
        </div>
        <Tabs tabs={TABS} />
      </>
    );
  };

  return (
    <>
      <Breadcrumbs current="Account" />
      <LoadingArea loading={isLoadingCorporateAdminOrganization}>{renderContent()}</LoadingArea>
      <EditCorporateOrganizationModal />
    </>
  );
};

export default observer(Account);
