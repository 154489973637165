import { useState, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { CompanyModalName } from 'companies/types';
import { FormValidation } from 'domain/types';
import Button from 'theme/button';
import Input from 'theme/input';
import Modal from 'theme/modal';
import UserDetailsFormTable from 'theme/userDetailsFormTable';
import { useFormValidation, useUserDetailsForm } from 'utils/hooks';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';

const FORM_VALIDATIONS: FormValidation = {
  companyNameRequired: '',
  firstNameRequired: '',
  lastNameRequired: '',
  ownerEmailInvalid: '',
  ownerEmailRequired: '',
  ownerPhoneInvalid: '',
  ownerPhoneRequired: '',
};

function AddCompanyModal(): JSX.Element {
  const { companiesStore, toastsStore } = useStores();
  const { closeModal, createCompany, modalName, isSubmitting } = companiesStore;
  const { addToast, toastMessages } = toastsStore;

  const [companyName, setCompanyName] = useState('');

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const companyNameRow = (
    <tr>
      <th>Company name</th>
      <td data-th="Company name">
        <Input
          name="name"
          onChange={(e) => handleCompanyNameChange(e.target.value)}
          value={companyName}
          error={validation.companyNameRequired}
          inTable
        />
      </td>
    </tr>
  );

  const { isFormSubmitDisabled, resetFormValues, userFormData } = useUserDetailsForm({
    cleanValidationErrors,
    validation,
  });
  const { email, firstName, lastName, phone } = userFormData;

  const handleCompanyNameChange = (value: string): void => {
    cleanValidationErrors(['companyNameRequired']);
    setCompanyName(value);
  };

  const onModalClose = (): void => {
    closeModal();
    setCompanyName('');
    resetFormValues();
    resetValidation();
  };

  const validateOnSubmit = (): void => {
    handleValidation('companyNameRequired', formValidationMessages.COMPANY_NAME_REQUIRED, !companyName);
    handleValidation('firstNameRequired', formValidationMessages.OWNER_FIRST_NAME_REQUIRED, !firstName);
    handleValidation('lastNameRequired', formValidationMessages.OWNER_LAST_NAME_REQUIRED, !lastName);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !EMAIL_REGEX.test(email));
    handleValidation('emailRequired', formValidationMessages.OWNER_EMAIL_REQUIRED, !email);
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, !PHONE_REGEX.test(phone));
    handleValidation('phoneRequired', formValidationMessages.OWNER_PHONE_REQUIRED, !phone);
  };

  const addCompany = (): void => {
    validateOnSubmit();
    if (!companyName || isFormSubmitDisabled) return;

    createCompany({
      name: companyName.trim(),
      ownerFirstName: firstName,
      ownerLastName: lastName,
      ownerEmail: email,
      ownerPhone: phone,
    })
      .then(() => {
        addToast(toastMessages.COMPANY.CREATE_SUCCESS, 'success');
        onModalClose();
      })
      .catch((err) => {
        if (err.config?.url.includes('memberships/invitations'))
          addToast(toastMessages.MEMBER.COMPANY_ADDED_BUT_MEMBER_ERROR, 'warning');
        else addToast(toastMessages.COMPANY.CREATE_ERROR);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    addCompany();
  };

  return (
    <Modal onClose={onModalClose} isOpen={modalName === CompanyModalName.AddCompany} title="Add company">
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <UserDetailsFormTable
          userFormData={userFormData}
          tableChildren={companyNameRow}
          validation={validation}
          isOwner
        />
        <div>
          <span className="modal-disclaimer">User will receive an invitation to create a password for admin panel</span>
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(AddCompanyModal);
