import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { AdminRole } from 'auth/types';
import { TableAction, TableActionBtn } from 'domain/types';
import { TeamMember, TeamModalName } from 'team/types';
import Confirm from 'theme/confirm';
import LoadingArea from 'theme/loadingArea';
import Modal from 'theme/modal';
import Pagination from 'theme/pagination';
import SearchPanel from 'theme/searchPanel';
import SortableHeadCell from 'theme/sortableHeadCell';
import TableActions from 'theme/tableActions';
import TableItemName from 'theme/tableItemName';
import Tag, { TagColor } from 'theme/tag';
import { useAdminPermissionsForUIManagement } from 'utils/hooks';
import styles from './EntourageTeam.module.scss';

const EntourageTeam = (): JSX.Element => {
  const { teamStore } = useStores();
  const {
    changePageNumber,
    changePageSize,
    clearFilters,
    closeModal,
    filters,
    isLoadingTeamMembers,
    isSubmitting,
    modalName,
    openModal,
    pageNumber,
    pageSize,
    removeTeamMember,
    resetFiltersSortAndPagination,
    selectedTeamMember,
    sortingProps,
    teamMembers,
    teamMembersCount,
    updateFilters,
  } = teamStore;

  const isEditionForbidden = useAdminPermissionsForUIManagement();

  useEffect(() => {
    return () => {
      resetFiltersSortAndPagination();
    };
  }, []);

  const rowButtonActions = (member: TeamMember): TableActionBtn[] => {
    const btns: TableActionBtn[] = [
      {
        link: paths.teamMember('entourage', member.id),
        text: 'Details',
        hidden: false,
        type: TableAction.Details,
      },
      {
        action: () => openModal(TeamModalName.RemoveTeamMember, member),
        text: 'Remove',
        hidden: isEditionForbidden,
        type: TableAction.Remove,
      },
    ];

    return btns.filter((btn) => !btn.hidden);
  };

  const displayTeamMemberRole = (role: AdminRole): JSX.Element | null => {
    switch (role) {
      case AdminRole.SuperAdmin:
        return <Tag text="Super Admin" color={TagColor.Green} />;
      case AdminRole.WriteAdmin:
        return <Tag text="Admin" color={TagColor.Orange} />;
      case AdminRole.ViewOnlyAdmin:
        return <Tag text="View-only" color={TagColor.Blue} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      <SearchPanel
        className={styles.searchPanel}
        loading={isLoadingTeamMembers}
        filters={filters}
        reset={clearFilters}
        search={updateFilters}
        title="Search team members"
      />
      <LoadingArea loading={isLoadingTeamMembers}>
        <div className="card">
          <table className="horizontal-table">
            <thead>
              <tr>
                <SortableHeadCell columnName="CreatedAt" name="No." {...sortingProps} />
                <th data-th="Role">Role</th>
                <SortableHeadCell columnName="Name" name="Name" {...sortingProps} />
                <th data-th="Phone">Phone</th>
                <th data-th="Action" />
              </tr>
            </thead>
            <tbody>
              {teamMembers.map((m) => (
                <tr key={m.id}>
                  <td data-th="No.">{m.sequenceId}</td>
                  <td data-th="Role" className={styles.roleCell}>
                    {displayTeamMemberRole(m.role)}
                  </td>
                  <td data-th="Name">
                    <TableItemName
                      title={m.name}
                      subtitle={m.email}
                      link={paths.teamMember('entourage', m.id)}
                      oneLine
                    />
                  </td>
                  <td data-th="Phone">{m.phone}</td>

                  <td data-th="Action">
                    <TableActions actions={rowButtonActions(m)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            activePage={pageNumber}
            activePageItemCount={teamMembers.length}
            allItemCount={teamMembersCount}
            disabled={isLoadingTeamMembers}
            goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        </div>
      </LoadingArea>

      <Modal onClose={closeModal} isOpen={modalName === TeamModalName.RemoveTeamMember}>
        <Confirm
          yesText="Confirm"
          action={removeTeamMember}
          close={closeModal}
          submitting={isSubmitting}
          question={
            <>
              Are you sure that you want to remove <strong>{selectedTeamMember?.name}</strong>?
            </>
          }
        />
      </Modal>
    </div>
  );
};

export default observer(EntourageTeam);
