import { Option } from 'domain/types';
import { AdminPermission, AdminPermissionOption } from 'domain/types';
import styles from './RadioOptionTable.module.scss';
import RadioOptionRow from './radioOptionRow';

interface RadioOptionTableProps<T> {
  defaultRowValue?: AdminPermissionOption;
  hiddenOptions?: AdminPermissionOption[];
  keyName: string;
  keys: Option[];
  onOptionChange: (key: T, value: string) => void;
  selectionOptions: Option[];
  values: Record<string, string[]>;
}

const RadioOptionTable = <T extends AdminPermission | string>({
  defaultRowValue,
  hiddenOptions,
  keyName,
  keys,
  onOptionChange,
  selectionOptions,
  values,
}: RadioOptionTableProps<T>): JSX.Element => {
  const findSelected = (keyValue: string): Option => {
    return (
      selectionOptions.find((o) => values[o.value]?.includes(keyValue)) ||
      selectionOptions[
        defaultRowValue?.name === keyValue
          ? selectionOptions.findIndex((o) => o.value === defaultRowValue.value)
          : selectionOptions.length - 1
      ]
    );
  };

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{keyName}</th>
            {selectionOptions.map(({ label, value }) => (
              <th key={value}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {keys.map(({ label, value }) => {
            const selected = findSelected(value);

            return (
              <RadioOptionRow
                key={value}
                name={value}
                label={label}
                selectionOptions={selectionOptions}
                selected={selected}
                onChange={(rowValue) => onOptionChange(value as T, rowValue)}
                hiddenOption={hiddenOptions?.find((o) => o.name === value)}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RadioOptionTable;
