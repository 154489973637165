import { action, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'Root.store';
import { getAPICredentials, removeAPICredentials, removeRefreshToken, saveAPICredentials } from 'api';
import { paths } from 'app/routes/paths.const';
import { AdminPermission } from 'domain/types';
import * as requests from './requests';
import { AdminRole, Auth, AuthUser, ResetPasswordReturn } from './types';

const RESET_PASSWORD_URL = `${location.origin}${paths.resetPassword}`;

class AuthStore {
  loading = false;
  rootStore: RootStore;
  token: Auth | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.checkIfAuthenticated();

    makeAutoObservable(this, {
      login: action.bound,
      logout: action.bound,
    });
  }

  get loggedInUser(): AuthUser | null {
    return this.token?.user || null;
  }

  checkIfAuthenticated = (): void => {
    const apiCredentials = getAPICredentials();
    runInAction(() => (this.token = apiCredentials && JSON.parse(apiCredentials)));
  };

  login = (username: string, password: string): Promise<Auth> => {
    runInAction(() => (this.loading = true));

    return requests
      .login(username, password)
      .then(({ data }) => {
        const authData = saveAPICredentials(data.token);
        runInAction(() => (this.token = authData));

        return authData;
      })
      .catch((err) => {
        if (err.response?.status === 401) throw new Error('Invalid credentials');
        else throw new Error('Unknown error, please try again later');
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  logout(): void {
    this.token = null;
    removeRefreshToken();
    removeAPICredentials();
  }

  get userRole(): string | null {
    if (!this.loggedInUser) return null;
    const { userRoles, viewPermissions, writePermissions } = this.loggedInUser;

    if (userRoles?.includes(AdminRole.SuperAdmin)) return AdminRole.SuperAdmin;
    if (writePermissions.length > 0) return AdminRole.WriteAdmin;
    if (viewPermissions.length > 0) return AdminRole.ViewOnlyAdmin;

    return AdminRole.CorporateAdmin;
  }

  get isEAD(): boolean {
    return (
      this.userRole == AdminRole.SuperAdmin ||
      this.userRole == AdminRole.ViewOnlyAdmin ||
      this.userRole == AdminRole.WriteAdmin
    );
  }

  get isAuthenticated(): boolean {
    return !!this.loggedInUser;
  }

  resetPassword = (password: string, token: string): ResetPasswordReturn => {
    runInAction(() => (this.loading = true));
    return requests.resetPassword(password, token).finally(() => runInAction(() => (this.loading = false)));
  };

  requestNewPassword = (email: string): ResetPasswordReturn => {
    runInAction(() => (this.loading = true));

    return requests
      .requestNewPassword(email, RESET_PASSWORD_URL)
      .finally(() => runInAction(() => (this.loading = false)));
  };

  hasPermissionToView = (view: AdminPermission): boolean => {
    if (!this.loggedInUser) return false;
    if (this.userRole == AdminRole.SuperAdmin) return true;

    const { writePermissions, viewPermissions } = this.loggedInUser;
    return this.isAuthenticated && (viewPermissions.includes(view) || writePermissions.includes(view));
  };

  setToken = (token: string): void => {
    this.token = saveAPICredentials(token);
  };
}

export default AuthStore;
