import { FunctionComponent, SVGProps } from 'react';
import clsx from 'clsx';
import { FlightStatus, FlightBookingStatus } from 'flights/types';
import Icon, { icons } from 'theme/icon';
import styles from './Tag.module.scss';

export enum TagColor {
  Blue = 'blue',
  Brown = 'brown',
  Default = 'default',
  Gold = 'gold',
  Gray = 'gray',
  Green = 'green',
  OrangeAlt = 'orange-alt',
  Orange = 'orange',
  Red = 'red',
  Sea = 'sea',
  Violet = 'violet',
}

export enum TagType {
  Default = 'default',
  Error = 'error',
  ErrorAlt = 'error-alt',
  Success = 'success',
}

type TagProps = {
  className?: string;
  text: FlightStatus | FlightBookingStatus | string;
  type?: TagType;
  color?: TagColor;
};

const Tag = ({ className = '', text, type = TagType.Default, color = TagColor.Default }: TagProps): JSX.Element => {
  const renderIcon = (): FunctionComponent<SVGProps<SVGSVGElement>> | null => {
    switch (type) {
      case TagType.Error:
        return icons.blockedWhite;
      case TagType.ErrorAlt:
        return icons.minusCircle;
      case TagType.Success:
        return icons.tick;
      default:
        return null;
    }
  };

  return (
    <div className={clsx(className, styles.root, styles[type], styles[color])}>
      <Icon icon={renderIcon()} />
      {text}
    </div>
  );
};

export default Tag;
