import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import AdminFlights from 'adminFlights';
import AdminFlightDetails from 'adminFlights/adminFlightDetails';
import Login from 'auth/login';
import RequestNewPassword from 'auth/requestNewPassword';
import ResetPassword from 'auth/resetPassword';
import Companies from 'companies';
import CompanyDetails from 'companies/companyDetails';
import CompanyMemberInvitation from 'companies/companyMemberInvitation';
import CorporateAdmin from 'corporateAdmin';
import Account from 'corporateAdmin/account';
import CorporateFlights from 'corporateFlights';
import CorporateFlightDetails from 'corporateFlights/corporateFlightDetails';
import { AdminPermission } from 'domain/types';
import Guests from 'guests';
import GuestDetails from 'guests/guestDetails';
import Layout from 'layout';
import Members from 'members';
import MemberDetails from 'members/memberDetails';
import Onboarding from 'members/onboarding';
import Team from 'team';
import TeamMemberDetails from 'team/teamMemberDetails';
import NonAuthedPageLayout from 'theme/nonAuthedPageLayout';
import Users from 'users';
import UserDetails from 'users/userDetails';
import AdminPermissionRoute from './AdminPermissionRoute';
import ScrollToTop from './ScrollToTop';
import ErrorBoundary from './errorBoundary';
import { paths } from './paths.const';

const URL_PARAM = {
  companyId: ':companyId',
  guestId: ':guestId',
  id: ':id',
  memberId: ':memberId',
  organizationId: ':organizationId',
  pnrCode: ':pnrCode',
  step: ':step',
  tab: ':tab',
  teamMemberId: ':teamMemberId',
};

function Router(): JSX.Element {
  const { authStore } = useStores();
  const { isEAD, isAuthenticated } = authStore;

  const EADRoutes = (
    <Route element={<Layout />}>
      <Route element={<AdminPermissionRoute permission={AdminPermission.CorporateMemberships} />}>
        <Route path={paths.companies} element={<Companies />} />
        <Route path={paths.company(URL_PARAM.companyId)} element={<CompanyDetails />} />
        <Route
          path={paths.companyMember(URL_PARAM.companyId, URL_PARAM.memberId, URL_PARAM.tab)}
          element={<MemberDetails />}
        />
      </Route>
      <Route element={<AdminPermissionRoute permission={AdminPermission.IndividualMemberships} />}>
        <Route path={paths.individualMemberships} element={<Users />} />
        <Route path={paths.individualMembership(URL_PARAM.id, URL_PARAM.tab)} element={<UserDetails />} />
      </Route>
      <Route element={<AdminPermissionRoute permission={AdminPermission.EntourageTeam} />}>
        <Route path={paths.team(URL_PARAM.tab)} element={<Team />} />
        <Route path={paths.teamMember(URL_PARAM.tab, URL_PARAM.teamMemberId)} element={<TeamMemberDetails />} />
      </Route>
      <Route element={<AdminPermissionRoute permission={AdminPermission.Charters} />}>
        <Route path={paths.adminFlights} element={<AdminFlights />} />
        <Route path={paths.adminFlightDetails} element={<AdminFlightDetails />} />
      </Route>

      <Route path="*" element={<Navigate to={paths.individualMemberships} replace />} />
    </Route>
  );

  const CODRoutes = (
    <>
      <Route path={paths.onboarding(URL_PARAM.step)} element={<Onboarding />} />
      <Route element={<Layout />}>
        <Route element={<CorporateAdmin />}>
          <Route path={paths.account} element={<Account />} />
          <Route path={paths.corporateFlights(URL_PARAM.tab)} element={<CorporateFlights />} />
          <Route path={paths.corporateFlightDetails(URL_PARAM.pnrCode)} element={<CorporateFlightDetails />} />
          <Route path={paths.guests} element={<Guests />} />
          <Route path={paths.guestDetails(URL_PARAM.guestId, URL_PARAM.organizationId)} element={<GuestDetails />} />
          <Route path={paths.members} element={<Members />} />
          <Route path={paths.member(URL_PARAM.memberId)} element={<MemberDetails />} />
          <Route path="*" element={<Navigate to={paths.members} replace />} />
        </Route>
      </Route>
    </>
  );

  const authedRoutes = isEAD ? EADRoutes : CODRoutes;

  const nonAuthedRoutes = (
    <Route element={<NonAuthedPageLayout />}>
      <Route path={paths.login} element={<Login />} />
      <Route path={paths.createPassword} element={<ResetPassword />} />
      <Route path={paths.resetPassword} element={<ResetPassword />} />
      <Route path={paths.requestNewPassword} element={<RequestNewPassword />} />
      <Route path={paths.companyMemberInvitation} element={<CompanyMemberInvitation />} />
      <Route path="*" element={<Navigate to={paths.login} replace />} />
    </Route>
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ErrorBoundary>
        <Routes>{isAuthenticated ? authedRoutes : nonAuthedRoutes}</Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default observer(Router);
