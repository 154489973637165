import { AxiosResponse } from 'axios';

export interface LoadingAirportsFlags {
  from: boolean;
  to: boolean;
}

export enum FlightType {
  OneTime = 'OneTime',
  Requested = 'Requested',
  Weekly = 'Weekly',
}

export enum AirportType {
  From = 'from',
  To = 'to',
}

export enum FlightStatus {
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Finished = 'finished',
  Ongoing = 'ongoing',
  Unconfirmed = 'unconfirmed',
}

export enum FlightInterest {
  High,
  Low,
  Medium,
  VeryHigh,
  VeryLow,
}

interface BookingClassInfoPrice {
  baseAmount: number;
  discountAmount: number;
  milesAmount: number;
  taxAmount: number;
  totalAmount: number;
}

interface BookingClassInfo {
  code: string;
  quantity: number;
  refItinerary: string;
  price: BookingClassInfoPrice;
}

interface FlightOffer {
  expirationDate: string;
  ref: string;
  refItinerary: string;
}

interface Airport {
  city: string;
  iata: string;
  name: string;
}

interface PassengerFare {
  price: BookingClassInfoPrice;
  refPassenger: string;
}

interface Aircraft {
  amenities: string[];
  capacity: number;
  cruisingSpeed: number;
  model: string;
  range: number;
  tailNumber: string;
  unit: 'Kilometers' | 'Miles';
}

export interface RawFlightDetails {
  aircraft: Aircraft;
  arrivalDate: string;
  arrivalFBO: string | null;
  availableSeats: number;
  bookingClassInfo: BookingClassInfo[];
  charterRequester: string;
  charterRequestersOrganization: string;
  charterType: FlightType;
  cityPhotoUrl: string;
  departureDate: string;
  departureFrom: Airport;
  departureTo: Airport;
  flightNumber: string;
  id: string;
  interest: FlightInterest;
  minSeats: number;
  offer: FlightOffer;
  passengerFares: PassengerFare;
  price: BookingClassInfoPrice;
  specialServices: string[];
  status: FlightStatus;
  totalSeats: number;
}

export interface FlightDetails extends RawFlightDetails {
  arrivalDateWithDayOfWeek: string;
  arrivalTime: string;
  departureTime: string;
  departureDateWithDayOfWeek: string;
  fullArrivalTime: string;
  fullDepartureTime: string;
  isConfirmed: boolean;
  seatCompletion: number;
  takenSeats: number;
  typeLabel: string;
}

export interface RawFlight {
  archivedAt: string | null;
  arrivalDate: string;
  availableSeats: number;
  cityPhotoUrl: string;
  createdAt: string;
  departureDate: string;
  departureFrom: Airport;
  departureTo: Airport;
  flightNumber: string;
  id: string;
  interest: string;
  minSeats: number;
  status: FlightStatus;
  totalSeats: number;
}

export interface Flight extends RawFlight {
  arrivalTime: string;
  charterType: FlightType;
  dateDay: string;
  dateDayOfWeek: string;
  departureTime: string;
  disabled: boolean;
  fullArrivalTime: string;
  fullDepartureDayTime: string;
  fullDepartureTime: string;
  isConfirmed: boolean;
  isPM: boolean;
  passengers: FlightPassenger[];
  seatCompletion: number;
  takenSeats: number;
  typeLabel: string;
}

export interface FlightDate {
  date: Date;
  isToday: boolean;
}

export interface CurrentMonths {
  earliestMonth: string;
  latestMonth: string;
}

export interface FlightPassenger {
  bookedAt: string;
  id: string;
  identityConfirmed: boolean;
  isPremium: boolean;
  meal: string;
  name: string;
  seat: {
    number: string;
    location: 'aisle' | 'window' | 'middle';
  };
}

export interface FlightSeat {
  seatNumber: string;
  taken: boolean;
  user: { id: string; name: string };
}

export interface FetchFlightDetailsParams {
  departureDate: string;
  departureFrom: string;
  departureTo: string;
  flightNumber: string;
}

export interface FetchFlightsParams extends Record<string, string | number | undefined> {
  departureAfter: string;
  departureBefore: string;
  pageNumber?: number;
  pageSize?: number;
  sourceAirport?: string;
  targetAirport?: string;
}

export interface FlightsRawData {
  count: number;
  results: RawFlight[];
}

export enum FlightBookingStatus {
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  NotFilled = 'NotFilled',
  Processing = 'Processing',
  Refunded = 'Refunded',
  Unconfirmed = 'Unconfirmed',
  Unprocessable = 'Unprocessable',
}

interface SpecialService {
  code: string;
  data: Partial<{
    adof: null;
    bill: null;
    chld: null;
    doca: null;
    doco: null;
    docs: null;
    ectc: null;
    fields: string[];
    foid: null;
    fqtv: null;
    inft: null;
    pctc: null;
    seat: null;
    umnr: null;
  }>;
  text: string;
}

interface ContactSpecialService {
  contactId: string;
  passengerRef: string;
  specialServices: SpecialService[];
}

interface MemberSpecialService {
  userId: string;
  passengerRef: string;
  specialServices: SpecialService[];
}

export interface FlightBookingDto {
  contactSpecialServices: ContactSpecialService[];
  memberSpecialServices: MemberSpecialService[];
  offer: FlightOffer;
  organizationId: string;
}

export interface FlightBooking {
  createdAt: string;
  departureDate: string;
  destinationCode: string;
  flightNumber: string;
  invitedContacts: string[];
  invitedMembers: string[];
  modifiedAt: string;
  organizationId: string;
  originCode: string;
  pnrCode: string;
  price: number;
  status: FlightBookingStatus;
  userId: string;
}

export interface RawBooking {
  aircraft: Aircraft;
  allBookedSeats: number;
  arrivalDate: string;
  bookedBy: string;
  bookingSeats: number;
  cityPhotoUrl: string;
  departureDate: string;
  departureFrom: Airport;
  departureTo: Airport;
  fillDeadline: string;
  flightNumber: string;
  minSeats: number;
  // passengers: string[]; // TODO
  pnrCode: string;
  price: BookingClassInfoPrice;
  status: FlightBookingStatus;
}

export interface Booking extends RawBooking {
  arrivalTime: string;
  charterType: FlightType;
  departureTime: string;
  passengers: FlightPassenger[]; // TEMPORARY SOLUTION
}

export type FetchFlightDetailsReturn = Promise<AxiosResponse<FlightDetails>>;
export type FetchFlightsReturn = Promise<AxiosResponse<FlightsRawData>>;
export type FetchBookingReturn = Promise<AxiosResponse<Booking>>;
export type FetchBookingsReturn = Promise<AxiosResponse<Booking[]>>;
export type FlightBookingReturn = Promise<AxiosResponse<FlightBooking>>;
export type FetchAirportsReturn = Promise<AxiosResponse<Airport[]>>;
