import { runInAction, observable, makeObservable } from 'mobx';
import { addDays, formatISO } from 'date-fns';
import { DEFAULT_SELECT_OPTION } from 'domain/constants';
import { CORPORATE_FLIGHTS_PAGE_SIZE } from 'domain/constants';
import { FlightsStore } from 'flights';
import { modelFlight, modelFlightDetails, modelBooking } from 'flights/modelFlight';
import * as requests from 'flights/requests';
import { FetchFlightDetailsParams, FlightDetails, Booking } from 'flights/types';
import { CorporateFlightModalName } from './types';

class CorporateFlightsStore extends FlightsStore {
  booking: Booking | null = null;
  bookings: Booking[] = [];
  flightCount = 0;
  isLoadingBookings = false;
  isLoadingPlannedFlight = false;
  modalName: CorporateFlightModalName | null = null;
  pageNumber = 1;
  selectedFlightDetails: FlightDetails | null = null;

  constructor(rootStore) {
    super(rootStore);
    makeObservable(this, {
      airportFrom: observable,
      airportTo: observable,
      booking: observable,
      bookings: observable,
      datepickerEndDate: observable,
      datepickerStartDate: observable,
      flightCount: observable,
      flights: observable,
      isLoadingAirportAutocompleteHints: observable,
      isLoadingBookings: observable,
      isLoadingFlightDetails: observable,
      isLoadingFlights: observable,
      isLoadingPlannedFlight: observable,
      isSubmitting: observable,
      modalName: observable,
      numberOfDays: observable,
      pageNumber: observable,
      selectedFlightDetails: observable,
    });
  }

  fetchFlights = (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingFlights = true));

    const departureAfter = formatISO(this.datepickerStartDate || new Date());
    const departureBefore = this.datepickerEndDate ? formatISO(addDays(this.datepickerEndDate, 1)) : '';

    return requests
      .fetchFlights({
        departureAfter,
        departureBefore,
        pageSize: CORPORATE_FLIGHTS_PAGE_SIZE,
        pageNumber: this.pageNumber,
        sourceAirport: this.airportFrom.value,
        targetAirport: this.airportTo.value,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.flights = data.results.map(modelFlight);
          this.flightCount = data.count;
        });
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_FLIGHTS_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingFlights = false));
      });
  };

  fetchFlightDetails = (params: FetchFlightDetailsParams): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingFlightDetails = true));

    return requests
      .fetchFlightDetails(params)
      .then(({ data }) => {
        runInAction(() => (this.selectedFlightDetails = modelFlightDetails(data)));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_FLIGHT_DETAILS_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingFlightDetails = false));
      });
  };

  fetchPlannedFlight = (pnrCode: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingPlannedFlight = true));

    return requests
      .fetchPlannedFlight(pnrCode)
      .then(({ data }) => {
        runInAction(() => (this.booking = modelBooking(data)));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_PLANNED_FLIGHT_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingPlannedFlight = false));
      });
  };

  fetchBookings = (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoadingBookings = true));

    return requests
      .fetchBookings()
      .then(({ data }) => {
        runInAction(() => (this.bookings = data.map(modelBooking)));
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.FETCH_PLANNED_FLIGHTS_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isLoadingBookings = false));
      });
  };

  bookFlight = (): Promise<void> => {
    const organizationId = this.rootStore.corporateAdminStore.corporateAdminOrganization?.id;
    if (!organizationId || !this.selectedFlightDetails) return Promise.resolve();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.isSubmitting = true));

    return requests
      .bookFlight({
        offer: this.selectedFlightDetails.offer,
        organizationId,
        memberSpecialServices: [
          {
            // TEMPORARY SOLUTION
            userId: '761c7a99-c2a7-4812-ae06-16d5cfc3fb6a',
            passengerRef: 'Traveler_Type_1_Index_0',
            specialServices: [
              {
                code: 'EXT-ADOB',
                data: {},
                text: 'Text',
              },
              {
                code: 'CTCE',
                data: {},
                text: 'Text',
              },
              {
                code: 'CTCH',
                data: {},
                text: 'Text',
              },
              {
                code: 'CTCM',
                data: {},
                text: 'Text',
              },
            ],
          },
        ],
        contactSpecialServices: [],
      })
      .then(() => {
        addToast(toastMessages.FLIGHTS.BOOKING_SUCCESS);
        this.closeModal();
      })
      .catch(() => {
        addToast(toastMessages.FLIGHTS.BOOKING_ERROR);
      })
      .finally(() => {
        runInAction(() => (this.isSubmitting = false));
      });
  };

  clearFilters = (): Promise<void> => {
    this.datepickerEndDate = undefined;
    this.datepickerStartDate = undefined;
    this.airportFrom = DEFAULT_SELECT_OPTION;
    this.airportTo = DEFAULT_SELECT_OPTION;

    return this.fetchFlights();
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) {
      this.pageNumber = newPageNumber;
      this.fetchFlights();
    }
  };

  openModal = (): void => {
    this.modalName = CorporateFlightModalName.FlightBooking;
  };

  closeModal = (): void => {
    this.selectedFlightDetails = null;
    this.modalName = null;
  };

  resetSelectedFlightDetails = (): void => {
    this.selectedFlightDetails = null;
  };
}

export default CorporateFlightsStore;
