import { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import Button from 'theme/button';
import LoadingArea from 'theme/loadingArea';
import Modal from 'theme/modal';
import { useFlightUrlParams } from 'utils/hooks';
import { CorporateFlightModalName } from '../types';
import styles from './BookingModal.module.scss';
import BookingDetails from './bookingDetails';

function BookingModal(): JSX.Element {
  const navigate = useNavigate();
  const urlParams = useFlightUrlParams();
  const { corporateFlightsStore } = useStores();
  const {
    bookFlight,
    closeModal,
    fetchFlightDetails,
    isLoadingFlightDetails,
    isSubmitting,
    modalName,
    selectedFlightDetails,
  } = corporateFlightsStore;

  const submit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    bookFlight();
  };

  const onModalClose = (): void => {
    closeModal();
    navigate(paths.corporateFlights('available'));
  };

  useEffect(() => {
    if (modalName) fetchFlightDetails(urlParams);
  }, [modalName]);

  return (
    <Modal
      className={styles.modal}
      contentClass={styles.modalContent}
      onClose={onModalClose}
      isOpen={modalName === CorporateFlightModalName.FlightBooking}
      title="Book flight"
    >
      <LoadingArea loading={isLoadingFlightDetails}>
        <form className="table-style-form" onSubmit={submit} autoComplete="off">
          <BookingDetails flight={selectedFlightDetails} />
          <div>
            <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
            <Button
              text="Book"
              loading={isSubmitting}
              type="submit"
              disabled={isSubmitting || !selectedFlightDetails}
            />
          </div>
        </form>
      </LoadingArea>
    </Modal>
  );
}

export default observer(BookingModal);
