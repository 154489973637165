import { useEffect, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { FormValidation } from 'domain/types';
import RoleAndPermissions from 'team/roleAndPermissions';
import { TeamModalName } from 'team/types';
import Button from 'theme/button';
import Modal from 'theme/modal';
import UserDetailsFormTable from 'theme/userDetailsFormTable';
import { useFormValidation, useRoleAndPermissions, useUserDetailsForm } from 'utils/hooks';
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex';

interface TeamMemberModalProps {
  isMemberDetailsView?: boolean;
}

const FORM_VALIDATIONS: FormValidation = {
  emailInvalid: '',
  emailRequired: '',
  firstNameRequired: '',
  lastNameRequired: '',
  phoneInvalid: '',
  phoneRequired: '',
  roleRequired: '',
};

function TeamMemberModal({ isMemberDetailsView }: TeamMemberModalProps): JSX.Element {
  const { teamStore, toastsStore } = useStores();
  const { closeModal, isSubmitting, modalName, selectedTeamMember, submitTeamMember } = teamStore;
  const { addToast, toastMessages } = toastsStore;

  const { resetRoleAndPermissions, roleAndPermissionsProps } = useRoleAndPermissions();
  const { isSuperAdmin, setIsSuperAdmin, setViewPermissions, setWritePermissions, viewPermissions, writePermissions } =
    roleAndPermissionsProps;

  const {
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    isSubmitBtnDisabled,
    resetValidation,
    validation,
  } = useFormValidation(FORM_VALIDATIONS, isSubmitting);

  const roleAndPermissions = <RoleAndPermissions {...roleAndPermissionsProps} />;

  const { isFormSubmitDisabled, resetFormValues, userFormData } = useUserDetailsForm({
    cleanValidationErrors,
    selected: selectedTeamMember,
    validation,
  });
  const { email, firstName, lastName, phone } = userFormData;

  const onModalClose = (): void => {
    closeModal();
    resetValidation();
    resetFormValues();
    resetRoleAndPermissions();
  };

  const validateOnSubmit = (): void => {
    handleValidation('firstNameRequired', formValidationMessages.FIRST_NAME_REQUIRED, !firstName);
    handleValidation('lastNameRequired', formValidationMessages.LAST_NAME_REQUIRED, !lastName);
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation('phoneRequired', formValidationMessages.PHONE_REQUIRED, !phone);
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, !!phone && !PHONE_REGEX.test(phone));
  };

  const requestTeamMember = (): void | undefined => {
    validateOnSubmit();
    if (isFormSubmitDisabled) return;

    submitTeamMember(
      { email, phone, firstName, lastName },
      {
        isSuperAdmin,
        writePermissions: isSuperAdmin ? [] : writePermissions,
        viewPermissions: isSuperAdmin ? [] : viewPermissions,
      },
      selectedTeamMember?.id || undefined
    )
      .then(() => {
        addToast(
          isMemberDetailsView ? toastMessages.TEAM_MEMBER.UPDATE_SUCCESS : toastMessages.TEAM_MEMBER.CREATE_SUCCESS,
          'success'
        );
        onModalClose();
      })
      .catch(({ request, response }) => {
        let msg;

        if (request.responseURL.includes('/admin/team')) msg = toastMessages.TEAM_MEMBER.PERMISSIONS_ERROR;
        else {
          if (response?.status === 409) {
            switch (response.data) {
              case 'email':
                msg = toastMessages.USER.EMAIL_IN_USE_ERROR;
                break;
              case 'phone':
                msg = toastMessages.USER.PHONE_IN_USE_ERROR;
                break;
              default:
                msg = toastMessages.ERROR.DEFAULT;
            }
          } else msg = toastMessages.ERROR.DEFAULT;
        }

        addToast(msg);
      });
  };

  const submit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    requestTeamMember();
  };

  useEffect(() => {
    if (selectedTeamMember) {
      setIsSuperAdmin(selectedTeamMember.isSuperAdmin);
      setViewPermissions(selectedTeamMember.viewPermissions);
      setWritePermissions(selectedTeamMember.writePermissions);
    }
  }, [selectedTeamMember]);

  useEffect(() => {
    resetFormValues();
  }, [isMemberDetailsView]);

  return (
    <Modal
      onClose={onModalClose}
      isOpen={modalName === TeamModalName.AddTeamMember || modalName === TeamModalName.EditTeamMember}
      title={isMemberDetailsView ? 'Edit team member' : 'Add team member'}
      contentOverflow
    >
      <form className="table-style-form" onSubmit={submit} autoComplete="off">
        <UserDetailsFormTable userFormData={userFormData} tableChildren={roleAndPermissions} validation={validation} />
        <div>
          {!isMemberDetailsView && <span className="modal-disclaimer">User will receive an invitation</span>}
          <Button onClick={onModalClose} text="Cancel" buttonType="transparent" />
          <Button text="Save" loading={isSubmitting} disabled={isSubmitBtnDisabled} type="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default observer(TeamMemberModal);
