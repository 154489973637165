import { AxiosResponse } from 'axios';
import { MembershipRole, OrganizationKind } from 'domain/types';

export interface MemberSearchValues extends Record<string, string> {
  userQuery: string;
}

export enum MemberModalName {
  DeleteMember = 'DeleteMember',
  EditMember = 'EditMember',
  InviteMember = 'InviteMember',
}

type IntercomStatus = 'Unverified' | 'Verified' | 'Requested';

export interface CreateMembershipDto {
  appStoreUrl: string;
  firstName: string;
  googlePlayUrl: string;
  isManagingCharters?: boolean;
  isManagingFinancial?: boolean;
  lastName: string;
  mobileAppLoginUrl: string;
  mobileAppSignupUrl: string;
  monthlyCharterLimit: number | null;
  organizationId: string;
  redirectUrl: string;
  role: MembershipRole;
  userEmail: string;
  userPhone: string;
}

export type UpdateMembershipDto = {
  isManagingCharters: boolean;
  isManagingFinancial: boolean;
  monthlyCharterLimit: number | null;
  role: string;
};

export interface RawMembershipUser {
  activeUntil: string;
  archivedAt: string | null;
  banned: boolean | null;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  identityConfirmed: boolean;
  intercomId: string | null;
  intercomStatus: IntercomStatus;
  lastName: string;
  modifiedAt: string | null;
  phone: string;
  preferredName: string | null;
  roles: MembershipRole[];
}

export interface MembershipUser extends RawMembershipUser {
  name: string;
}

export interface MembershipOrganization {
  activeUntil: string;
  address: string;
  archivedAt: string | null;
  createdAt: string;
  id: string;
  intercomId: string | null;
  kind: OrganizationKind;
  modifiedAt: string | null;
  name: string;
  taxId: string;
}

export interface RawMember {
  archivedAt: string | null;
  createdAt: string;
  id: string;
  isManagingCharters: boolean;
  isManagingFinancial: boolean;
  modifiedAt: string | null;
  monthlyCharterLimit: number | null;
  organization: MembershipOrganization;
  ownerOnboardingDone: boolean | null;
  role: MembershipRole;
  sequenceId: number;
  user: RawMembershipUser;
}

export interface Member extends RawMember {
  user: MembershipUser;
}

export interface MemberInvitation {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  isManagingCharters: boolean | null;
  isManagingFinancial: boolean | null;
  lastName: string;
  modifiedAt: string;
  monthlyCharterLimit: boolean | null;
  organizationId: string;
  organizationKind: OrganizationKind;
  organizationName: string;
  phone: string;
  role: MembershipRole;
  status: string;
  validUntil: string;
}

export interface InvitationAcceptDto {
  invitationId: string;
  token: string;
  userId: string;
}

export interface InvitationAcceptResponse {
  archivedAt: string;
  createdAt: string;
  id: string;
  intercomStatus: IntercomStatus;
  isManagingCharters: boolean;
  isManagingFinancial: boolean;
  modifiedAt: string;
  monthlyCharterLimit: number;
  organizationId: string;
  ownerOnboardingDone: null;
  role: MembershipRole;
  sequenceId: number;
  userId: string;
}

export interface MembersRawData {
  count: number;
  results: Member[];
}

export interface FetchMembersParams {
  companyIdParam?: string;
  onInit?: boolean;
  pageNumber?: number;
  pageSize?: number;
  resetPreviousData?: boolean;
}

export type DeleteMemberReturn = Promise<AxiosResponse<void>>;
export type FetchMembersReturn = Promise<AxiosResponse<MembersRawData>>;
export type InvitationAcceptReturn = Promise<AxiosResponse<InvitationAcceptResponse>>;
export type MemberInvitationReturn = Promise<AxiosResponse<MemberInvitation>>;
export type MemberInvitationsReturn = Promise<AxiosResponse<MemberInvitation[]>>;
export type MemberReturn = Promise<AxiosResponse<Member>>;
