import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { CORP_MEMBERSHIPS_COLUMNS_STORAGE_NAME } from 'domain/constants';
import { SUBSCRIPTION_STATUSES } from 'domain/constants';
import { TableAction, TableActionBtn } from 'domain/types';
import Breadcrumbs from 'theme/breadcrumbs';
import Button from 'theme/button';
import Confirm from 'theme/confirm';
import HorizontalScrollTable from 'theme/horizontalScrollTable';
import IconButton from 'theme/iconButton';
import Input from 'theme/input';
import LoadingArea from 'theme/loadingArea';
import Modal from 'theme/modal';
import PageHeader from 'theme/pageHeader';
import Pagination from 'theme/pagination';
import SortableHeadCell from 'theme/sortableHeadCell';
import TableActions from 'theme/tableActions';
import TableColumnSelectionModal from 'theme/tableColumnSelectionModal';
import TableItemName from 'theme/tableItemName';
import VerificationTag from 'theme/verificationTag';
import { useTitle } from 'utils/hooks';
import { useAdminPermissionsForUIManagement, useCustomizedTableColumns } from 'utils/hooks';
import AddCompanyModal from './addCompanyModal';
import AddUserToCompanyModal from './addUserToCompanyModal';
import EditCompanyModal from './editCompanyModal';
import { initiallySelectedColumns, tableColumns } from './tableColumns';
import { Company, CompanyColumn, CompanyModalName } from './types';

function Companies(): JSX.Element {
  const { companiesStore } = useStores();
  const {
    changePageNumber,
    changePageSize,
    closeModal,
    companies,
    companiesCount,
    deleteCompany,
    fetchCompanies,
    isLoadingCompanies,
    isResettingCompanies,
    isSubmitting,
    modalName,
    openModal,
    pageNumber,
    pageSize,
    resetFiltersSortAndPagination,
    searchValues,
    selectedCompany,
    setSearchValues,
    sortingProps,
  } = companiesStore;

  const isEditionForbidden = useAdminPermissionsForUIManagement();
  const { activeColumnCount, columnsToSave, handleColumnsToSave, saveActiveColumns, savedActiveColumns } =
    useCustomizedTableColumns({
      initiallySelectedColumns,
      localStorageName: CORP_MEMBERSHIPS_COLUMNS_STORAGE_NAME,
      tableColumns,
    });

  const renderTableCellContent = (columnName: CompanyColumn, company: Company): JSX.Element | null => {
    let content;

    switch (columnName) {
      case CompanyColumn.Payment:
        content = <VerificationTag verified={company.isActive} labels={SUBSCRIPTION_STATUSES} />;
        break;
      case CompanyColumn.ActiveUntil:
        content = company.activeUntil;
        break;
      case CompanyColumn.Address:
        content = company.address;
        break;
      case CompanyColumn.TaxID:
        content = company.taxId;
        break;
      default:
        content = null;
    }

    return (
      <td data-th={columnName} key={columnName}>
        {content}
      </td>
    );
  };

  const rowButtonActions = (company: Company): TableActionBtn[] => {
    const btns: TableActionBtn[] = [
      {
        action: () => openModal(CompanyModalName.AddUserToCompany, company),
        text: 'Add user',
        hidden: isEditionForbidden,
        type: TableAction.Add,
      },
      {
        link: paths.company(company.id),
        text: 'Details',
        hidden: false,
        type: TableAction.Details,
      },
      {
        action: () => openModal(CompanyModalName.EditCompany, company),
        text: 'Edit',
        hidden: isEditionForbidden,
        type: TableAction.Edit,
      },
      {
        action: () => openModal(CompanyModalName.DeleteCompany, company),
        text: 'Delete',
        hidden: isEditionForbidden,
        type: TableAction.Delete,
      },
    ];

    return btns.filter((btn) => !btn.hidden);
  };

  const leftColumn = (
    <table className="horizontal-table-vertical-top">
      <thead>
        <tr>
          <SortableHeadCell columnName="CreatedAt" name="No." {...sortingProps} />
          <SortableHeadCell columnName="Name" name="Name" {...sortingProps}>
            <Input
              name="search"
              loading={isResettingCompanies}
              placeholder="Search by name..."
              onChange={(e) => setSearchValues('organization', e.target.value)}
              value={searchValues.name}
              type="search"
            />
          </SortableHeadCell>
        </tr>
      </thead>
      <tbody>
        {companies.map((c) => (
          <tr key={c.id}>
            <td data-th="No.">{c.sequenceId}</td>
            <td data-th="Name">
              <TableItemName title={c.name} link={paths.company(c.id)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const middleColumn = (
    <table className="horizontal-table-vertical-top">
      <thead>
        <tr>
          {savedActiveColumns.map(({ name }) => (
            <th data-th={name} key={name}>
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {companies.map((c) => (
          <tr key={c.id}>{savedActiveColumns.map(({ name }) => renderTableCellContent(name, c))}</tr>
        ))}
      </tbody>
    </table>
  );

  const rightColumn = (
    <table className="horizontal-table-vertical-top">
      <thead>
        <tr>
          <th data-th="Action">
            <IconButton action={() => openModal(CompanyModalName.TableColumnSelection)} type={TableAction.Settings} />
          </th>
        </tr>
      </thead>
      <tbody>
        {companies.map((c) => (
          <tr key={c.id}>
            <td data-th="Action">
              <TableActions actions={rowButtonActions(c)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  useEffect(() => {
    fetchCompanies(pageSize, pageNumber);
  }, []);

  useEffect(() => {
    return () => {
      resetFiltersSortAndPagination();
    };
  }, []);

  useTitle('Corporate memberships');

  return (
    <div>
      <Breadcrumbs current="Corporate memberships" />
      <PageHeader title="Corporate memberships">
        {!isEditionForbidden && <Button text="Add company" onClick={() => openModal(CompanyModalName.AddCompany)} />}
      </PageHeader>
      <LoadingArea loading={isLoadingCompanies}>
        <div className="main-container">
          <HorizontalScrollTable
            left={leftColumn}
            middle={middleColumn}
            right={rightColumn}
            columnCount={activeColumnCount}
            withSearch
          />
          <Pagination
            activePage={pageNumber}
            activePageItemCount={companies.length}
            allItemCount={companiesCount}
            disabled={isLoadingCompanies}
            goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        </div>
      </LoadingArea>

      <AddCompanyModal />
      <EditCompanyModal />
      <AddUserToCompanyModal />
      <Modal onClose={closeModal} isOpen={modalName === CompanyModalName.DeleteCompany}>
        <Confirm
          yesText="Confirm"
          action={deleteCompany}
          close={closeModal}
          submitting={isSubmitting}
          question={
            <>
              Are you sure that you want to delete the <strong>{selectedCompany?.name}</strong>?
            </>
          }
        />
      </Modal>
      <TableColumnSelectionModal
        close={closeModal}
        columns={tableColumns}
        saveColumns={saveActiveColumns}
        tableColumnsToSave={columnsToSave}
        handleColumn={handleColumnsToSave}
        isOpen={modalName === CompanyModalName.TableColumnSelection}
      />
    </div>
  );
}

export default observer(Companies);
