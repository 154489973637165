import { AxiosResponse } from 'axios';
import { AdminPermission, Gender } from 'domain/types';
import { Member, RawMembershipUser } from 'members/types';

export enum UserColumn {
  ActiveUntil = 'Active until',
  BirthDate = 'Birth date',
  CorporateMembership = 'Corporate Membership',
  Country = 'Country of Residence',
  ID = 'ID',
  IndMembership = 'Ind. Membership',
  Name = 'Name',
  No = 'No.',
  Phone = 'Phone',
  Sex = 'Biological sex',
}

export enum UserModalName {
  IDConfirmation = 'IDConfirmation',
  RemoveDocument = 'RemoveDocument',
  TableColumnSelection = 'TableColumnSelection',
  UserForm = 'UserForm',
}

type QuestionKind = 'SingleChoice' | 'Ordering' | 'MultipleChoice';

export enum AssetTypeEnum {
  IdentityDocument = 'identity_document',
  ProfilePicture = 'profile_picture',
}

export type AssetType = AssetTypeEnum.IdentityDocument | AssetTypeEnum.ProfilePicture;

export interface UserSearchValues extends Record<string, string> {
  user: string;
  organizationId: string;
}

export interface RawUser {
  banned: null | boolean;
  birthDate: string | null;
  country: string | null;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  intercomId: string;
  isPremium: boolean;
  lastName: string;
  membershipViews: Member[];
  phone: string;
  preferredName: string;
  roles: string[];
  sequenceId: string;
  sex: Gender;
  viewPermissions: AdminPermission[];
  writePermissions: AdminPermission[];
}

export interface User extends RawUser {
  activeUntil: string;
  addresses: UserAddress[];
  corporateOrganizations: Member[];
  flightDeposit: string;
  identityConfirmed: boolean;
  intercomUrl: string | undefined;
  isManagingCharters?: boolean;
  isManagingFinancial?: boolean;
  name: string;
}

export interface ModelUserParams {
  addresses?: UserAddress[];
  depositData?: OrganizationFee;
  intercomConfigUrl?: string;
  user: RawUser;
}

export interface OrganizationFee {
  membershipFeeInCents: number;
  currentDepositInCents: number;
  minimalInitialDeposit: number;
}

export type UserDto = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export interface UsersRawData {
  count: number;
  results: RawUser[];
}

export type MembershipInvitationDto = {
  createdAt: string;
  email: string | null;
  id: string;
  organizationId: string;
  phone: string | null;
  role: string;
  validUntil: string;
};

export type UserIdentityDocument = {
  additionalParams: { key: string; value: string }[];
  key: AssetTypeEnum.IdentityDocument;
  presignedUrl: string;
  url: string;
};

export type UserAddress = {
  id: string;
  userId: string;
  kind: string;
  data: string;
  createdAt: string;
  modifiedAt: string | null;
};

export interface RawQuestion {
  archivedAt: string | null;
  content: string;
  createdAt: string;
  description: string;
  id: string;
  isCustomAllowed: boolean;
  kind: QuestionKind;
  modifiedAt: string | null;
  sequenceId: number;
}

export interface Question extends RawQuestion {
  answers: Answer[];
  predefinedAnswers: PredefinedAnswer[];
}

export interface PredefinedAnswer {
  archivedAt: string | null;
  createdAt: string;
  id: string;
  modifiedAt: string;
  questionId: string;
  value: string;
}

export interface Answer {
  createdAt: string;
  customValue: string;
  id: string;
  orderingIdx: number;
  predefinedAnswerId: string;
  questionId: string;
  userId: string;
}

export interface OnboardingItem {
  answers: Answer[];
  predefinedAnswers: PredefinedAnswer[];
  question: RawQuestion;
}

export interface IntercomConfig {
  usersUrl: string;
}

export interface UserFormData {
  email: string;
  firstName: string;
  handleFirstNameChange: (value: string) => void;
  handleLastNameChange: (value: string) => void;
  handleEmailChange: (value: string) => void;
  handlePhoneChange: (value: string) => void;
  lastName: string;
  phone: string;
}

export interface FetchUsersParams {
  corporateRoles?: string[];
  id?: string;
  isIdentityConfirmed?: boolean;
  isPremium?: boolean;
  organizationId?: string;
  pageNumber: number;
  pageSize: number;
  searchValues?: UserSearchValues;
  sortBy: string;
}

export type ConfirmUserIdentityReturn = Promise<AxiosResponse<RawMembershipUser>>;
export type IntercomConfigReturn = Promise<AxiosResponse<IntercomConfig>>;
export type MembershipInvitationReturn = Promise<AxiosResponse<MembershipInvitationDto>>;
export type OrganizationFeeReturn = Promise<AxiosResponse<OrganizationFee>>;
export type QuestionsReturn = Promise<AxiosResponse<OnboardingItem[]>>;
export type UserAddressesReturn = Promise<AxiosResponse<UserAddress[]>>;
export type UserIdentityDocumentReturn = Promise<AxiosResponse<UserIdentityDocument>>;
export type UserReturn = Promise<AxiosResponse<RawUser>>;
export type UsersReturn = Promise<AxiosResponse<UsersRawData>>;
