import React from 'react';
import FlightRoute from 'flights/flightRoute';
import FlightSeats from 'flights/flightSeats';
import FlightStatusTag from 'flights/flightStatusTag';
import { FlightDetails } from 'flights/types';
import Icon, { icons } from 'theme/icon';
import styles from './BookingDetails.module.scss';

interface ItineraryRowProps {
  airportName: string;
  city: string;
  day: string;
  hour: string;
}

const ItineraryRow = ({ airportName, city, day, hour }: ItineraryRowProps): JSX.Element => (
  <div className={styles.itineraryRow}>
    <div className={styles.itineraryRowContainer}>
      <span className={styles.label}>{hour}</span>
      <span className="text-small">{day}</span>
    </div>
    <div className={styles.itineraryRowContainer}>
      <span className={styles.labelBold}>{city}</span>
      <span className="text-small">{airportName}</span>
    </div>
  </div>
);

interface BillingRowProps {
  label: string;
  value: string;
}

const BillingRow = ({ label, value }: BillingRowProps): JSX.Element => (
  <div className={styles.billingRow}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

interface SelectedFlightDetailsProps {
  flight: FlightDetails | null;
}

const BookingFlights = ({ flight }: SelectedFlightDetailsProps): JSX.Element | null => {
  if (!flight) return <div className={styles.errorMessage}>Failed to fetch the flight details.</div>;

  const {
    arrivalDateWithDayOfWeek,
    arrivalTime,
    departureDateWithDayOfWeek,
    departureFrom,
    departureTime,
    departureTo,
    status,
  } = flight;

  return (
    <div className={styles.root}>
      <section className={styles.section}>
        <div className={styles.route}>
          <h2 className={styles.routeFrom}>{departureFrom.city}</h2>
          <FlightRoute flight={flight} simplified />
          <h2 className={styles.routeTo}>{departureTo.city}</h2>
        </div>
        <div className={styles.statusInfo}>
          <FlightStatusTag status={status} />
          <FlightSeats flight={flight} smallProgressBar withLabel />
        </div>
      </section>
      <section className={styles.section}>
        <h5>Itinerary</h5>
        <div className={styles.itinerary}>
          <Icon icon={icons.flightVertical} width={23} height={77} />
          <div className={styles.itineraryContainer}>
            <ItineraryRow
              hour={departureTime}
              day={departureDateWithDayOfWeek}
              city={departureFrom.city}
              airportName={departureFrom.name}
            />
            <ItineraryRow
              hour={arrivalTime}
              day={arrivalDateWithDayOfWeek}
              city={departureTo.city}
              airportName={departureTo.name}
            />
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <h5>Passengers</h5>
      </section>
      <section className={styles.section}>
        <h5>Billing</h5>
        <BillingRow label="Seats (0)" value="$0" />
        <BillingRow label="Tax" value="included" />
        <BillingRow label="Fuel surcharge" value="TBD" />
        <p className={styles.total}>
          Total: <span>$0</span>
        </p>
      </section>
    </div>
  );
};

export default BookingFlights;
