import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AxiosError } from 'axios';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import { TableAction } from 'domain/types';
import Breadcrumbs from 'theme/breadcrumbs';
import IconButton from 'theme/iconButton';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import Tabs from 'theme/tabs';
import { useTitle, useAdminPermissionsForUIManagement } from 'utils/hooks';
import { UserModalName } from '../types';
import UserFormModal from '../userFormModal';
import styles from './UserDetails.module.scss';
import OnboardingQuestions from './onboardingQuestions';
import UserDetailsTable from './userDetailsTable';

// import UserFlights from './userFlights';

const TABS = [
  { value: 'details', label: 'Details', element: <UserDetailsTable /> },
  { value: 'onboarding-questions', label: 'Onboarding questions', element: <OnboardingQuestions /> },
  // { value: 'charters', label: 'Flights', element: <UserFlights /> },
];

const ANCESTORS = [{ title: 'Individual memberships', to: paths.individualMemberships }];

function UserDetails(): JSX.Element {
  const { toastsStore, usersStore } = useStores();
  const { addToast, toastMessages } = toastsStore;
  const { fetchAllUserData, isLoadingUser, openModal, user } = usersStore;

  const navigate = useNavigate();
  const { id: userId } = useParams();
  const isEditionForbidden = useAdminPermissionsForUIManagement();

  useTitle('User Details');

  const displayErrorMessage = (error: AxiosError): void => {
    const includes = (url: string): boolean => !!error.config?.url?.includes(url);
    let message;

    switch (true) {
      case includes('/payments/fees'):
        message = toastMessages.USER.FETCH_BALANCE_ERROR;
        break;
      default:
        message = toastMessages.USER.FETCH_DETAILS_ERROR;
    }

    addToast(message);
  };

  useEffect(() => {
    fetchAllUserData(userId, displayErrorMessage);
  }, [userId]);

  const onTabClick = (value: string): void | undefined => {
    if (!userId) return;
    navigate(paths.individualMembership(userId, value));
  };

  const renderHeaderBtns = (): JSX.Element | null => {
    if (isEditionForbidden) return null;

    return (
      <div className="page-header-btn-container">
        <IconButton action={() => openModal(UserModalName.UserForm, user)} text="Edit" type={TableAction.Edit} />
        {/* <IconButton action={() => alert('block')} text="Block" type="block" /> */}
      </div>
    );
  };

  const renderContent = (): JSX.Element | null => {
    if (!user) return null;

    return (
      <div className={styles.main}>
        <PageHeader title={user.name}>{renderHeaderBtns()}</PageHeader>
        <Tabs tabs={TABS} onTabClick={(value) => onTabClick(value)} />
      </div>
    );
  };

  return (
    <>
      <Breadcrumbs ancestors={ANCESTORS} current="Details" />
      <LoadingArea loading={isLoadingUser}>{renderContent()}</LoadingArea>
      <UserFormModal isUserDetailsView />
    </>
  );
}

export default observer(UserDetails);
