import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';
import { paths } from 'app/routes/paths.const';
import Breadcrumbs from 'theme/breadcrumbs';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import { useTitle } from 'utils/hooks';
import DeleteMemberModal from '../deleteMemberModal';
import EditMemberModal from '../editMemberModal';
import MemberRowButtonActions from '../memberRowButtonActions';
import styles from './MemberDetails.module.scss';
import MemberDetailsTable from './memberDetailsTable';

function MemberDetails(): JSX.Element {
  const { membersStore, toastsStore } = useStores();
  const { fetchMember, isLoadingMember, member } = membersStore;
  const { addToast, toastMessages } = toastsStore;
  const { companyId, memberId } = useParams();

  useTitle('Member Details');

  useEffect(() => {
    if (!memberId) return;

    const fetchData = async (): Promise<void> => {
      try {
        await fetchMember(memberId, companyId ?? '');
      } catch (error) {
        addToast(toastMessages.MEMBERS.FETCH_DETAILS_ERROR);
      }
    };

    fetchData();
  }, [memberId]);

  const ancestors = [{ title: 'Members', to: paths.members }];
  const corporateAncestors = [
    { title: 'Corporate memberships', to: paths.companies },
    { title: 'Company details', to: paths.company(companyId ?? '') },
  ];

  const renderContent = (): JSX.Element | null => {
    if (!member) return null;

    return (
      <div className={styles.main}>
        <PageHeader title={member.user.name}>
          <MemberRowButtonActions member={member} areBtnsAlwaysVisible isDetailsView isEAD={!!companyId} />
        </PageHeader>
        <MemberDetailsTable member={member} />
      </div>
    );
  };

  return (
    <>
      <Breadcrumbs ancestors={companyId ? corporateAncestors : ancestors} current="Member details" />
      <LoadingArea loading={isLoadingMember}>{renderContent()}</LoadingArea>

      <EditMemberModal />
      <DeleteMemberModal />
    </>
  );
}

export default observer(MemberDetails);
