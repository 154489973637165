import { paths } from 'app/routes/paths.const';
import { Option } from 'domain/types';

export const LOGIN_STORAGE_PREFIX = 'entourage__auth_';
export const REFRESH_TOKEN_STORAGE_PREFIX = 'entourage__ref_tok_';
export const SIDEBAR_VISIBILITY_STORAGE_NAME = 'entourage__sidebar_visible';
export const IND_MEMBERSHIPS_COLUMNS_STORAGE_NAME = 'entourage__ind_mem_columns';
export const CORP_MEMBERSHIPS_COLUMNS_STORAGE_NAME = 'entourage__corp_mem_columns';

export const DEFAULT_PAGE_SIZE = 10;
export const CORPORATE_FLIGHTS_PAGE_SIZE = 3;
export const DEFAULT_SELECT_OPTION: Option = { label: '', value: '' };
export const DEBOUNCE_INTERVAL = 500;

export const REDIRECT_LINKS = {
  redirectUrl: location.origin + paths.companyMemberInvitation,
  mobileAppLoginUrl: 'https://google.com',
  mobileAppSignupUrl: 'https://google.com',
  appStoreUrl: 'https://google.com',
  googlePlayUrl: 'https://google.com',
};

export const VERIFICATION_STATUSES = ['Verified', 'Unverified'];
export const SUBSCRIPTION_STATUSES = ['active', 'inactive'];
