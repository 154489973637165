import React from 'react';
import { Outlet } from 'react-router-dom';
import { images } from 'theme/images';
import LazyImage from 'theme/lazyImage';
import styles from './NonAuthedPageLayout.module.scss';

const NonAuthedPageLayout = (): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <Outlet />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <LazyImage src={images.png.plane} placeholderSrc={images.jpg.planeMin} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default NonAuthedPageLayout;
