import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RootStoreContext, { rootStore } from 'Root.store';
import Router from 'app/routes/Router';
import Toasts from 'toasts';

function App(): JSX.Element {
  return (
    <RootStoreContext.Provider value={rootStore}>
      <DndProvider backend={HTML5Backend}>
        <Router />
        <Toasts />
      </DndProvider>
    </RootStoreContext.Provider>
  );
}

export default App;
