import clsx from 'clsx';
import FlightSearch from 'flights/flightSearch';
import Autocomplete from 'theme/autocomplete';
import Button from 'theme/button';
import Dates from 'theme/dates';
import Input from 'theme/input';
import MultiSelect from 'theme/multiSelect';
import styles from './SearchPanel.module.scss';
import { Filter, FilterType } from './types';

interface SearchPanelProps {
  className?: string;
  children?: React.ReactNode;
  loading: boolean;
  filters: Filter[];
  reset: () => Promise<void>;
  search: () => void;
  submitDisabled?: boolean;
  title: string;
}

const SearchPanel = ({
  children,
  className,
  loading,
  filters,
  reset,
  search,
  submitDisabled = false,
  title,
}: SearchPanelProps): JSX.Element | null => {
  const isSubmitDisabled = loading || submitDisabled;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.header}>
        <h2>{title}</h2>
        {children}
      </div>
      <div className={styles.container}>
        <div className={styles.filters}>
          {filters.map(
            ({
              autocompleteSettings,
              clear,
              endDate,
              flightAutocompleteSettings,
              isSearchXL,
              label,
              minStartDate,
              name,
              onChange,
              onDateChange,
              onSelect,
              options,
              placeholder,
              selectedOptions,
              startDate,
              type,
              value,
            }) => {
              switch (type) {
                case FilterType.Autocomplete:
                  return (
                    <div key={name} className={styles.filterItem}>
                      {autocompleteSettings ? (
                        <Autocomplete key={name} className={styles.search} {...autocompleteSettings} />
                      ) : null}
                    </div>
                  );
                case FilterType.Search:
                  return (
                    <Input
                      key={name}
                      className={clsx(styles.filterItem, styles.search, { [styles.searchXL]: isSearchXL })}
                      name={name}
                      label={label}
                      placeholder={placeholder}
                      onChange={onChange}
                      value={value ?? ''}
                    />
                  );
                case FilterType.Multiselect:
                  return (
                    <MultiSelect
                      key={name}
                      className={clsx(styles.filterItem, styles.multiselect)}
                      name={name}
                      label={label}
                      options={options || []}
                      selectedOptions={selectedOptions || []}
                      handleOptions={onSelect}
                    />
                  );
                case FilterType.Dates:
                  return (
                    <Dates
                      key={name}
                      className={styles.filterItem}
                      label={label}
                      clear={clear}
                      handleDate={onDateChange}
                      endDate={endDate}
                      startDate={startDate}
                      minStartDate={minStartDate}
                    />
                  );
                case FilterType.FlightSearch:
                  return (
                    <div key={name} className={styles.filterItem}>
                      {flightAutocompleteSettings ? <FlightSearch {...flightAutocompleteSettings} /> : null}
                    </div>
                  );
                default:
                  return null;
              }
            }
          )}
          <div className={styles.btns}>
            <Button
              disabled={isSubmitDisabled}
              loading={loading}
              text="Search"
              onClick={search}
              className={clsx(styles.filterItem, styles.searchBtn)}
            />
            <Button
              disabled={isSubmitDisabled}
              loading={loading}
              text="Clear"
              buttonType="transparent"
              onClick={reset}
              className={styles.filterItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPanel;
