import React from 'react';
import clsx from 'clsx';
import { TableActionBtn } from 'domain/types';
import IconButton from 'theme/iconButton';
import styles from './TableActions.module.scss';
import ActionDropdown from './actionDropdown';

type TableActionsProps = {
  actions: TableActionBtn[];
  alignLeft?: boolean;
  areBtnsAlwaysVisible?: boolean;
};

const TableActions = ({ actions, alignLeft = false, areBtnsAlwaysVisible = false }: TableActionsProps): JSX.Element => {
  return (
    <>
      <ActionDropdown
        options={actions}
        className={clsx(styles.actionDropdown, { [styles.actionDropdownHidden]: areBtnsAlwaysVisible })}
        hiddenOnDesktop
      />
      <div
        className={clsx(styles.buttons, {
          [styles.buttonsAlwaysVisible]: areBtnsAlwaysVisible,
          [styles.alignLeft]: alignLeft,
        })}
      >
        {actions.map((a, i) => (
          <IconButton key={i} {...a} />
        ))}
      </div>
    </>
  );
};

export default TableActions;
