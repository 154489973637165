import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import LoadingArea from 'theme/loadingArea';
import { formatToMoney } from 'utils/helpers';
import styles from './Wallet.module.scss';

interface WalletProps {
  nav?: boolean;
}

const Wallet = ({ nav }: WalletProps): JSX.Element | null => {
  const { authStore, corporateAdminStore } = useStores();
  const { isEAD } = authStore;
  const { corporateAdminDeposit, isLoadingCorporateAdminDeposit } = corporateAdminStore;

  if (isEAD) return null;

  return (
    <div className={clsx(styles.root, { [styles.noMarginTop]: !nav })}>
      {nav && <span className={styles.label}>Wallet</span>}
      <LoadingArea loading={isLoadingCorporateAdminDeposit}>
        <div className={clsx(styles.card, { [styles.cardXL]: !nav, [styles.noMarginTop]: !nav })}>
          {corporateAdminDeposit && (
            <h2 className={clsx({ [styles.titleXL]: !nav })}>${formatToMoney(corporateAdminDeposit)}</h2>
          )}
          <span className={clsx(styles.availableCash, { [styles.availableCashXL]: !nav })}>Available Cash</span>
          {!nav && <strong className={styles.fundsInfo}>You can add funds in Entourage App</strong>}
        </div>
      </LoadingArea>
    </div>
  );
};

export default observer(Wallet);
