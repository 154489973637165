import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import Icon, { icons } from 'theme/icon';
import Loader from 'theme/loader';
import styles from './Input.module.scss';

type InputType = 'text' | 'password' | 'search' | 'number' | 'select-search';

interface InputProps {
  autoCompleteOff?: boolean;
  className?: string;
  error?: string;
  inTable?: boolean;
  label?: string;
  loading?: boolean;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  type?: InputType;
  value: string | number;
}

const Input = ({
  autoCompleteOff = false,
  className = '',
  error = '',
  inTable = false,
  label,
  loading = false,
  name,
  onChange,
  onFocus,
  placeholder,
  readOnly = false,
  type = 'text',
  value,
}: InputProps): JSX.Element => {
  const isSearchIconVisible = type === 'search' || type === 'select-search';

  return (
    <div className={clsx(styles.root, className)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <input
        name={name}
        placeholder={placeholder}
        type={type === 'search' ? 'text' : type}
        value={type === 'number' ? value.toString() : value}
        onChange={(e) => onChange?.(e)}
        onFocus={onFocus}
        readOnly={readOnly}
        autoComplete={autoCompleteOff ? 'off' : undefined}
        min={type === 'number' ? '0' : undefined}
        step={type === 'number' ? '1' : undefined}
        className={clsx(styles.input, { [styles.inputError]: error, [styles.numeric]: type === 'number' })}
      />
      <Loader className={styles.loader} small loading={loading} />
      {isSearchIconVisible && (
        <Icon
          icon={icons.search}
          className={clsx(styles.searchIcon, { [styles.searchIconSelect]: type === 'select-search' })}
        />
      )}
      {error && <span className={clsx(styles.error, { [styles.errorInTable]: inTable })}>{error}</span>}
    </div>
  );
};

export default Input;
