import { ReactNode } from 'react';
import clsx from 'clsx';
import { SortColumn, SortDirection } from 'domain/types';
import styles from './SortableHeadCell.module.scss';

interface SortableHeadCellProps {
  className?: string;
  children?: ReactNode;
  columnName: string;
  name: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  updateSort: (column: SortColumn) => void;
}

const SortableHeadCell = ({
  className = '',
  children,
  columnName,
  name,
  sortColumn,
  sortDirection,
  updateSort,
}: SortableHeadCellProps): JSX.Element => {
  return (
    <th
      data-th={name}
      className={clsx(styles.root, className, {
        [styles[sortDirection]]: sortColumn === SortColumn[columnName],
      })}
    >
      <span onClick={() => updateSort(SortColumn[columnName])}>{name}</span>
      {children}
    </th>
  );
};

export default SortableHeadCell;
