import { useLocation } from 'react-router-dom';
import { useStores } from 'Root.store';
import * as menuLinks from 'layout/menuLinks';

export function useAdminPermissionsForUIManagement(): boolean {
  const location = useLocation();
  const { authStore } = useStores();
  const { loggedInUser: user } = authStore;

  const allLinks = [...menuLinks.membershipSuperAdmin, ...menuLinks.manageSuperAdmin];
  const matchedLink = allLinks.find((l) => location.pathname.includes(l.name));

  if (!matchedLink || !user) return false;

  return !!matchedLink.permissionId && !!user.viewPermissions.includes(matchedLink.permissionId);
}
