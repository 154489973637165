import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Root.store';

const CorporateAdmin = (): JSX.Element => {
  const { corporateAdminStore } = useStores();
  const {
    corporateAdminOrganizationOptions,
    defaultCorporateAdminOrganizationOption,
    fetchCorporateAdminUser,
    setCorporateAdminOrganization,
  } = corporateAdminStore;

  useEffect(() => {
    fetchCorporateAdminUser();
  }, []);

  useEffect(() => {
    if (corporateAdminOrganizationOptions.length)
      setCorporateAdminOrganization(defaultCorporateAdminOrganizationOption, true);
  }, [corporateAdminOrganizationOptions.length]);

  return <Outlet />;
};

export default observer(CorporateAdmin);
