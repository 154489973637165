import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'Root.store';
import { User, UserAddress } from 'users/types';
import styles from './OnboardingQuestions.module.scss';
import OnboardingQuestionAnswer from './onboardingQuestionAnswer';

interface LocationsRowProps {
  questionCount: number;
  user: User | undefined;
}

const LocationsRow = ({ questionCount, user }: LocationsRowProps): JSX.Element | null => {
  if (!user || !user.addresses.length) return null;

  const getIconClass = (addressKind: string): string => {
    switch (addressKind.toLowerCase()) {
      case 'home':
        return styles.homeIcon;
      case 'work':
        return styles.workIcon;
      default:
        return '';
    }
  };

  const sortAddresses = (a: UserAddress, b: UserAddress): number => {
    if (a.kind === 'Home') return -1;
    if (b.kind === 'Home') return 1;
    if (a.kind === 'Work') return -1;
    if (b.kind === 'Work') return 1;
    return 0;
  };

  return (
    <tr>
      <td data-th="No.">{questionCount + 1}</td>
      <td data-th="Question" className={styles.question}>
        Do you have any favourite travel locations?
      </td>
      <td data-th="Answer">
        <ul>
          {user.addresses.sort(sortAddresses).map(({ data, kind }, i) => (
            <li key={i} className={styles.locationAnswer}>
              <div className={clsx(styles.locationAnswerIcon, getIconClass(kind))} />
              <span className={styles.locationAnswerLabel}>{data}</span>
            </li>
          ))}
        </ul>
      </td>
    </tr>
  );
};

function OnboardingQuestions(): JSX.Element {
  const { usersStore } = useStores();
  const { questions, user } = usersStore;

  return (
    <div className="card">
      <table className={styles.table}>
        <thead>
          <tr>
            <th data-th="No.">No.</th>
            <th data-th="Question">Question</th>
            <th data-th="Answer">Answer</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((q) => (
            <tr key={q.id}>
              <td data-th="No.">{q.sequenceId}</td>
              <td data-th="Question" className={styles.question}>
                {q.content}
              </td>
              <td data-th="Answer">
                <OnboardingQuestionAnswer question={q} />
              </td>
            </tr>
          ))}
          <LocationsRow questionCount={questions.length} user={user} />
        </tbody>
      </table>
    </div>
  );
}

export default observer(OnboardingQuestions);
