import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  children?: ReactNode;
  className?: string;
  title: string | ReactNode;
}

const PageHeader = ({ children, className = '', title }: PageHeaderProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      {typeof title === 'string' ? <h1>{title}</h1> : title}
      {children}
    </div>
  );
};

export default PageHeader;
