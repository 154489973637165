import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'app/routes/paths.const';
import Icon, { icons } from 'theme/icon';
import styles from './Breadcrumbs.module.scss';

export type Ancestor = {
  title: string;
  to: string;
};

export type BreadcrumbsProps = {
  ancestors?: Ancestor[];
  current: string;
};

const DEFAULT_ANCESTOR = { title: 'Dashboard', to: paths.index };

const Breadcrumbs = ({ ancestors, current }: BreadcrumbsProps): JSX.Element => {
  const links = ancestors ? [DEFAULT_ANCESTOR, ...ancestors] : [DEFAULT_ANCESTOR];

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        {links.map((a, i) => (
          <div key={i}>
            <Link to={a.to} className={styles.ancestor}>
              {a.title}
            </Link>
            <Icon icon={icons.chevronRight} />
          </div>
        ))}
      </div>
      <div className={styles.current}>{current}</div>
    </header>
  );
};

export default Breadcrumbs;
