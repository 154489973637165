import { TableColumn } from 'domain/types';
import { CompanyColumn } from './types';

export const initiallySelectedColumns = [CompanyColumn.Payment];

export const tableColumns: TableColumn<CompanyColumn>[] = [
  { seq: 1, name: CompanyColumn.No, static: true },
  { seq: 2, name: CompanyColumn.Name, static: true },
  { seq: 3, name: CompanyColumn.Payment, static: false },
  { seq: 4, name: CompanyColumn.ActiveUntil, static: false },
  { seq: 5, name: CompanyColumn.Address, static: false },
  { seq: 6, name: CompanyColumn.TaxID, static: false },
];
