import React from 'react';
import FlightDetailsHeader from 'flights/flightDetailsHeader';
import FlightSeats from 'flights/flightSeats';
// import FlightPassengers from './flightPassengers';
// import Tabs from 'theme/tabs';
import { FlightDetails } from 'flights/types';
import Breadcrumbs, { Ancestor } from 'theme/breadcrumbs';
import LoadingArea from 'theme/loadingArea';
import PageHeader from 'theme/pageHeader';
import TableCard from 'theme/tableCard';
import { useTitle } from 'utils/hooks';
import styles from './FlightInfo.module.scss';

// import Meals from './meals';
// import SeatDistribution from './seatDistribution';

// const TABS = [
//   {
//     value: 'passengers',
//     label: 'Passengers',
//     element: (
//       <div className={styles.passengerContainer}>
//         <FlightPassengers />
//         <SeatDistribution />
//       </div>
//     ),
//   },
//   { value: 'menu', label: 'Menu', element: <Meals /> },
// ];

interface FlightInfo {
  ancestors: Ancestor[];
  flightDetails: FlightDetails | null;
  headerButtons?: JSX.Element;
  loading: boolean;
  modals?: JSX.Element;
}

const FlightInfo = ({ ancestors, flightDetails, headerButtons, loading, modals }: FlightInfo): JSX.Element => {
  useTitle('Flight details');

  const renderFlightDetailsTable = (): JSX.Element | null => {
    if (!flightDetails) return null;
    const {
      aircraft,
      arrivalFBO,
      charterRequester,
      charterRequestersOrganization,
      fullArrivalTime,
      fullDepartureTime,
    } = flightDetails;

    return (
      <>
        <PageHeader title={<FlightDetailsHeader flight={flightDetails} />}>{headerButtons}</PageHeader>
        <TableCard title="Flight information" className={styles.tableCard} vertical>
          <table>
            <tbody>
              <tr>
                <th>Plane number</th>
                <td data-th="Plane number">{aircraft.tailNumber}</td>
              </tr>
              <tr>
                <th>Departure</th>
                <td data-th="Departure">{fullDepartureTime}</td>
              </tr>
              <tr>
                <th>Arrival</th>
                <td data-th="Arrival">{fullArrivalTime}</td>
              </tr>
              <tr>
                <th>Time of arrival at FBO</th>
                <td data-th="Time of arrival at FBO">{arrivalFBO}</td>
              </tr>
              <tr>
                <th>Seats filled</th>
                <td data-th="Seats filled">
                  <FlightSeats flight={flightDetails} />
                </td>
              </tr>
              <tr>
                <th>Type:</th>
                <td data-th="Type">
                  Requested by <strong>{charterRequester}</strong> (Company{' '}
                  <strong>{charterRequestersOrganization}</strong>)
                </td>
              </tr>
            </tbody>
          </table>
        </TableCard>
      </>
    );
  };

  return (
    <>
      <Breadcrumbs ancestors={ancestors} current="Flight details" />
      <LoadingArea loading={loading}>
        {renderFlightDetailsTable()}
        {/* <Tabs tabs={TABS} /> */}
      </LoadingArea>

      {modals}
    </>
  );
};

export default FlightInfo;
