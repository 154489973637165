export const paths = {
  account: '/account',
  adminFlightDetails: '/flights/details',
  adminFlights: '/flights',
  companies: '/companies',
  company: (id: string): string => `/companies/${id}`,
  companyMember: (companyId: string, memberId: string, tab: string): string =>
    `/companies/${companyId}/members/${memberId}/${tab}`,
  companyMemberInvitation: '/company-member-invitation',
  corporateFlightDetails: (pnrCode: string) => `/corporate-flights/planned/details/${pnrCode}`,
  corporateFlights: (tab: string) => `/corporate-flights/${tab}`,
  createPassword: '/create-password',
  guestDetails: (guestId: string, organizationId: string): string =>
    `/guests/${guestId}/organizations/${organizationId}}`,
  guests: '/guests',
  index: '/',
  individualMembership: (id: string, tab: string): string => `/individual-memberships/${id}/${tab}`,
  individualMemberships: '/individual-memberships',
  login: '/login',
  member: (id: string): string => `/members/${id}`,
  members: '/members',
  onboarding: (step: string) => `/onboarding/${step}`,
  payments: '/payments',
  requestNewPassword: '/request-new-password',
  resetPassword: '/reset-password',
  signup: '/signup',
  team: (tab: string) => `/team/${tab}`,
  teamMember: (tab: string, teamMemberId: string) => `/team/${tab}/members/${teamMemberId}`,
};
